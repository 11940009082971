import type { FC } from 'react';
import useConnect from './connect';
import {
  ProductsIcon,
  Button,
  ChevronRightIcon,
  Container,
  Header,
  IconCircle,
  Label,
  MenuButton,
  Icon,
  Title,
  DangerIcon,
} from './styles';

const ProductAdvancedMenu: FC = () => {
  const {
    activateProductReference,
    isOpen,
    handleClose,
    handleAdjust,
    handleRemove,
    handleCreateReference,
    employeePermissions,
    handleStoreMovement,
    subscription,
    handleOpenAssignDiscount,
    handleBaseWeightMovement,
  } = useConnect();

  return (
    <Container open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <ProductsIcon />
        </IconCircle>
        <Title>Opciones Avanzadas</Title>
      </Header>
      {employeePermissions.allowAdjustProducts && (
        <>
          <MenuButton type="button" onClick={handleAdjust}>
            <IconCircle>
              <Icon name="organization" size={16} />
            </IconCircle>
            <Label>Ajustar</Label>
            <ChevronRightIcon />
          </MenuButton>
          <MenuButton type="button" onClick={handleStoreMovement}>
            <IconCircle>
              <Icon name="store" size={16} />
            </IconCircle>
            <Label>Almacén</Label>
            <ChevronRightIcon />
          </MenuButton>
          <MenuButton type="button" onClick={handleBaseWeightMovement}>
            <IconCircle>
              <Icon name="up" size={16} />
            </IconCircle>
            <Label>Cantidad Base</Label>
            <ChevronRightIcon />
          </MenuButton>
        </>
      )}

      {subscription.allowedDiscounts &&
        employeePermissions.allowAssignDiscounts && (
          <MenuButton type="button" onClick={handleOpenAssignDiscount}>
            <IconCircle>
              <Icon name="discount" size={16} />
            </IconCircle>
            <Label>Asignar descuento</Label>
            <ChevronRightIcon />
          </MenuButton>
        )}

      {employeePermissions.allowCreateProducts && activateProductReference && (
        <MenuButton type="button" onClick={handleCreateReference}>
          <IconCircle>
            <Icon name="products" />
          </IconCircle>
          <Label>Crear referencia</Label>
          <ChevronRightIcon />
        </MenuButton>
      )}

      {employeePermissions.allowRemoveProducts && (
        <MenuButton type="button" onClick={handleRemove}>
          <IconCircle $isDanger>
            <DangerIcon name="trash" size={16} />
          </IconCircle>
          <Label>Eliminar</Label>
          <ChevronRightIcon />
        </MenuButton>
      )}

      <Button onClick={handleClose}>Cancelar</Button>
    </Container>
  );
};

export default ProductAdvancedMenu;
