import type { FC } from 'react';
import Header from 'containers/Common/Header';
import { Charts, Chart, Container, Main, LineCharts } from './styles';
import connect from './connect';
import ProductsMetricList from './ProductsList';
import ProductsMobileMetricList from './ProductsMobileList';
import LineChart from './LineChart';
import BestTimes from './BestTimes';

const MetricsView: FC = () => {
  const {
    billingsMetric,
    fromTabletLandscape,
    partnersMetric,
    productsMobile,
    productsRanking,
    newProductsByMonth,
    monthlyProfit,
    monthlyCollectedCreditsProfit,
    bestPurchasesTimes,
  } = connect();

  return (
    <Container>
      <Header title="Métricas" />
      <Main>
        {bestPurchasesTimes && (
          <BestTimes purchasesTimes={bestPurchasesTimes} />
        )}

        <Charts>
          <Chart
            data={billingsMetric?.chart || []}
            title={String(billingsMetric?.totalMonthCredits || 0)}
            extraTitle="cr"
            subtitle={String(billingsMetric?.totalWeekCredits || 0)}
            extraSubtitle="cr esta semana"
          />
          <Chart
            data={partnersMetric?.chart || []}
            title={String(partnersMetric?.totalPartners || 0)}
            extraTitle="Socios"
            subtitle={`+${partnersMetric?.totalMonthPartners || 0}`}
            extraSubtitle="este mes"
          />
        </Charts>
        <LineCharts>
          <LineChart
            data={monthlyProfit}
            title="Productos"
            labelProfits="ganancias"
            labelCosts="costes"
          />
          <LineChart
            data={monthlyCollectedCreditsProfit}
            title="Créditos"
            labelProfits="donaciones"
            labelCosts="gastos"
          />
        </LineCharts>

        {fromTabletLandscape ? (
          <ProductsMetricList
            items={productsRanking}
            totalOfLastCreations={newProductsByMonth.length}
          />
        ) : (
          <ProductsMobileMetricList
            items={productsMobile}
            totalProducts={productsRanking.length}
          />
        )}
      </Main>
    </Container>
  );
};

export default MetricsView;
