import styled from 'styled-components';
import DefaultAvatar from 'components/Avatar';
import DefaultLogoutIcon from 'components/Icon/Icons/LogOut';
import DefaultIconButton from 'components/IconButton';

export const Avatar = styled(DefaultAvatar)`
  flex: 0 0 auto;
  font-size: 0.9rem;
  height: 2.1875rem;
  margin-right: 0.5rem;
  width: 2.1875rem;
`;

export const Name = styled.div`
  flex: 1 1 auto;
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 0.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LogoutIcon = styled(DefaultLogoutIcon).attrs(() => ({
  size: 18,
}))`
  color: ${({ theme }) => theme.colors.primary};
`;

export const IconButton = styled(DefaultIconButton)`
  flex: 0 0 auto;
  &&& {
    border-radius: 0.25rem;
    border: none;
    padding: 4px 3px 3px 3px;
    height: unset;
    width: unset;
    &:hover {
      background-color: ${({ theme }) => theme.colors.accent};
      ${LogoutIcon} {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  user-select: none;
  color: #666666;
`;
