import { useCallback, useMemo } from 'react';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import { formatDate } from 'utils/dates/formatDates';

const useConnect = () => {
  const { close, type, closeCashierPayload } = useModal();
  const { cashier } = closeCashierPayload || {};

  const initialValues = useMemo(() => {
    const realCreditsAdded = Number(
      (cashier?.realCreditsAdded || 0).toFixed(2),
    );
    const totalCreditsAdded = Number(
      (cashier?.totalCreditsAdded || 0).toFixed(2),
    );
    const totalExpenses = Number((cashier?.totalExpenses || 0).toFixed(2));
    const lastRestRealCredits = Number(
      (cashier?.lastRestRealCredits || 0).toFixed(2),
    );
    const restRealCredits = Number((cashier?.restRealCredits || 0).toFixed(2));
    const totalCashier =
      totalCreditsAdded + lastRestRealCredits - totalExpenses;

    const sumValues = totalCashier < 0;
    const adjust = sumValues
      ? realCreditsAdded + totalCashier
      : realCreditsAdded - totalCashier;

    return {
      id: cashier?.id || '',
      note: cashier?.note || '',
      startDate: (cashier?.start && formatDate(cashier.start)) || '',
      endDate: (cashier?.end && formatDate(cashier.end)) || '',
      openedBy: cashier?.openedBy?.fullName || '',
      closedBy: cashier?.closedBy?.fullName || '',
      totalCashier: Number(totalCashier.toFixed(2)),
      typesOfCash: (cashier?.typesOfCash || [])
        .filter((item) => item.value > 0)
        .map((item) => `(${item.value}: ${item.key})`)
        .join(', '),
      adjust: Number(adjust.toFixed(2)),
      realCreditsAdded,
      totalCreditsAdded,
      totalExpenses,
      lastRestRealCredits,
      restRealCredits,
    };
  }, [cashier]);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  return {
    handleClose,
    initialValues,
    isOpen: type === ModalType.CASHIER_DETAILS,
  };
};

export default useConnect;
