import { useCallback, useMemo } from 'react';
import useAuthActions from 'apollo/hooks/useAuthActions';
import getMe from 'apollo/hooks/user/useMe';
import { useLocation } from 'react-router-dom';

const useConnect = () => {
  const { logout, loading: logoutLoading } = useAuthActions();
  const { data, loading: getMeLoading } = getMe();
  const location = useLocation();

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const organizationData = useMemo<{ name: string; totalOrders: number }>(
    () => ({
      name: data?.organization?.name || 'organización',
      totalOrders: data?.organization?.totalOrders || 0,
    }),
    [data],
  );

  return {
    handleLogout,
    organizationData,
    isLoading: logoutLoading || getMeLoading,
    isNewOrderView: location.pathname === '/new-order',
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
