import { FC, memo, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import { formatDate, formatDateToTable } from 'utils/dates/formatDates';
import { TransactionType } from 'model/Transaction';
import type { Discount } from 'model/Discount';
import {
  Container,
  Credits,
  CreditValue,
  Date,
  DateItem,
  Employee,
  ExtraConcept,
  Icon,
  IconBorder,
  Items,
  Note,
  NoteData,
  Revert,
  State,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, onClickItems, onRevert, transaction }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const {
    canceled,
    canceledBy,
    canceledAt,
    concept,
    extraConcept,
    credits,
    date,
    employee,
    icon,
    items,
    sumCredits,
    type,
    discount,
  } = transaction;

  const products =
    items.length === 1
      ? `${items[0].productName}(${items[0].totalQuantity.toFixed(2)})`
      : items.length;

  const isZeroCredit = credits === 0;
  const isNegativeCredit = credits < 0;

  const handleClickItems = useCallback(
    () => onClickItems(items, discount as Partial<Discount>),
    [items, onClickItems, discount],
  );

  return (
    <Container className={className}>
      <State>
        {canceled ? (
          <Icon
            size={fromTabletPortrait ? 22 : 15}
            $color="#E53935"
            name="revert"
          />
        ) : (
          <Icon
            size={fromTabletPortrait ? 22 : 15}
            $color={icon.color}
            name={icon.name}
          />
        )}
      </State>
      {fromTabletPortrait && (
        <Note>
          <NoteData>
            {employee && (
              <Employee to={`/employees/${employee.id}`} target="_blank">
                {employee.fullName}
              </Employee>
            )}
            {concept}
            {extraConcept && <ExtraConcept>{extraConcept}</ExtraConcept>}
          </NoteData>
        </Note>
      )}

      <Items $hasItems={items.length > 0} onClick={handleClickItems}>
        {products}
      </Items>
      <Date>
        {canceledAt ? (
          <>
            <DateItem $type="canceled">
              {fromTabletPortrait
                ? formatDateToTable(canceledAt)
                : formatDate(canceledAt)}
            </DateItem>
            <DateItem $type="second">
              {fromTabletPortrait ? formatDateToTable(date) : formatDate(date)}
            </DateItem>
          </>
        ) : (
          <DateItem>
            {fromTabletPortrait ? formatDateToTable(date) : formatDate(date)}{' '}
          </DateItem>
        )}
      </Date>
      <Credits $creditsAdded={sumCredits} $isZeroCredit={isZeroCredit}>
        <CreditValue $isReverted={canceled}>
          {!isZeroCredit && !isNegativeCredit && (
            <>
              {sumCredits
                ? '+'
                : (type !== TransactionType.CREDITS && '-') || ''}
            </>
          )}

          <Price>{credits}</Price>
        </CreditValue>
      </Credits>
      {!canceled && (
        <Revert onClick={onRevert}>
          <IconBorder onClick={onRevert}>
            <Icon
              size={fromTabletPortrait ? 22 : 15}
              $color="#E53935"
              name="revert"
            />
          </IconBorder>
        </Revert>
      )}
      {canceledBy && (
        <Revert>
          Cancelado por{' '}
          <Employee to={`/employees/${canceledBy.id}`} target="_blank">
            {canceledBy.fullName}
          </Employee>
        </Revert>
      )}
    </Container>
  );
};

export default memo(Row);
