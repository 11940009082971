import styled from 'styled-components';
import { SectionHeader } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultBackLink from 'components/BackLink';
import DefaultButton from 'components/Button';

export const Header = styled(SectionHeader)`
  margin-bottom: 2rem;
  padding: 0;
  ${from.tabletLandscape`
    margin-bottom: 3rem;
  `}
`;

export const BackLink = styled(DefaultBackLink)``;

export const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: 1rem !important;
  }
  > * {
    flex: 0 0 8rem;

    ${from.desktop`
      flex: 0 1 12.5rem;
    `}

    ${from.tabletPortrait`
      justify-content: flex-start;
    `}
  }
`;

export const HeaderInfo = styled.div`
  flex: 1 0 auto;
  margin-left: 1rem;
`;

export const HeaderName = styled.p`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  font-size: 2rem;
  text-transform: capitalize;
`;

export const HeaderReference = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 1rem;
  font-weight: 500;
`;

export const Button = styled(DefaultButton)``;

export const SecondaryButton = styled(DefaultButton).attrs(() => ({
  variant: 'light_black',
}))`
  &&& {
    max-width: 10%;
  }
`;
