import styled from 'styled-components';
import { SectionContainer, SectionHeader } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultBackLink from 'components/BackLink';
import DefaultButton from 'components/Button';
import DefaultIcon from 'components/Icon';

export const Container = styled(SectionContainer)`
  padding-left: 0;
`;

export const Header = styled(SectionHeader)`
  align-items: center;
  ${from.tabletLandscape`
    margin-bottom: 5rem;
  `}
`;

export const BackLink = styled(DefaultBackLink)``;

export const HeaderInfo = styled.div`
  flex: 1 0 auto;
  margin-left: 1rem;
`;

export const HeaderName = styled.p`
  align-items: start;
  display: flex;
  flex: 1 0 auto;
  font-size: 0.8rem;
  text-transform: capitalize;

  ${from.tabletPortrait`
     font-size: 1rem;
  `}
`;

export const DownloadButton = styled(DefaultButton).attrs(() => ({
  variant: 'light',
}))`
  &&& {
    padding: 0.5rem;
  }
`;

export const FileIcon = styled(DefaultIcon).attrs(() => ({
  size: 16,
  name: 'uploadFile',
}))`
  color: ${({ theme }) => theme.colors.accent};
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-radius: 0.25rem;
`;

export const Main = styled(SectionContainer)`
  position: relative;
  padding-bottom: 3.6rem;
`;

export const ListContainer = styled.div`
  margin-top: 3rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
  `};
`;

export const Filters = styled(SectionContainer)``;

export const DateTimeFilterContainer = styled.div``;
