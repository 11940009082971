import { useCallback, useMemo, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { FormikConfig, FormikProps } from 'formik';
import { useReactiveVar } from '@apollo/client';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useCategories from 'apollo/hooks/category/useCategories';
import useExpenses from 'apollo/hooks/expense/useExpenses';
import useExpenseActions from 'apollo/hooks/expense/useExpenseActions';
import {
  cleanStore,
  expenseStateVar,
  updateExpenseStateVar,
} from 'apollo/reactive';
import useProviders from 'apollo/hooks/provider/useProviders';
import { CategoryType } from 'apollo/generated/globalTypes';
import RevertSnackbarButton from 'components/RevertSnackbarButton';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { ExpenseFormFields } from 'model/Expense';
import type { Provider } from 'model/Provider';

const useConnect = () => {
  const {
    close,
    type,
    openSelectCategories,
    openNewExpense,
    openSelectProvider,
  } = useModal();
  const { categories, refetch: refetchCategories } = useCategories({
    filter: {
      type: CategoryType.EXPENSES,
    },
    fetchPolicy: 'network-only',
  });
  const { providers } = useProviders();
  const { refetch: refetchExpenses } = useExpenses();
  const {
    createExpense,
    cancelExpense,
    loading: createExpenseLoading,
  } = useExpenseActions();
  const { ...fields } = useReactiveVar(expenseStateVar);
  const formikRef = useRef<FormikProps<ExpenseFormFields>>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initialValues = useMemo<ExpenseFormFields>(
    () => ({
      categoryIds: fields.categoryIds || [],
      description: fields.description || '',
      name: fields.name || '',
      price: fields.price || ('' as unknown as number),
      providerId: fields.providerId || '',
      providerName: fields.providerName || '',
      taxes: fields.taxes || ('' as unknown as number),
      quantity: fields.quantity || ('' as unknown as number),
    }),
    [fields],
  );

  const handleClose = useCallback(() => {
    cleanStore();
    close();
  }, [close]);

  const handleOnSelectOptions = useCallback(async () => {
    await refetchCategories();
    openNewExpense();
  }, [openNewExpense, refetchCategories]);

  const handleOnSelectProvider = useCallback(
    (provider: Provider) => {
      if (provider) {
        updateExpenseStateVar({
          providerId: provider.id,
          providerName: provider.name,
        });
        openNewExpense();
      }
    },
    [openNewExpense],
  );

  const handleOpenCategoriesSelection = useCallback(async () => {
    if (formikRef?.current?.values) {
      const { values } = formikRef.current;
      updateExpenseStateVar({ ...values });
    }

    const modalCategories = categories.map((c) => ({
      ...c,
      isChecked: !!fields?.categoryIds?.includes(c.id),
    }));

    openSelectCategories({
      categories: modalCategories,
      onSelectCategories: handleOnSelectOptions,
      onReturn: handleOnSelectOptions,
      type: CategoryType.EXPENSES,
    });
  }, [categories, fields, handleOnSelectOptions, openSelectCategories]);

  const handleOpenProviderSelection = useCallback(async () => {
    if (formikRef?.current?.values) {
      const { values } = formikRef.current;
      updateExpenseStateVar({ ...values });
    }

    openSelectProvider({
      onSubmit: handleOnSelectProvider,
      onReturn: handleOnSelectOptions,
    });
  }, [openSelectProvider, handleOnSelectProvider, handleOnSelectOptions]);

  const handleRevertTransaction = useCallback(
    async (expenseId: string) => {
      try {
        await cancelExpense(expenseId);
        closeSnackbar();
        enqueueSnackbar('Se ha cancelado el gasto', {
          variant: 'info',
        });
        await refetchExpenses();
      } catch (e) {
        enqueueSnackbar(formatErrors('transaction', e.message, 'cancelar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [cancelExpense, closeSnackbar, enqueueSnackbar, refetchExpenses],
  );

  const handleSubmit = useCallback<FormikConfig<ExpenseFormFields>['onSubmit']>(
    async (data) => {
      try {
        const expense = await createExpense({ ...data, description: '' });
        if (expense) {
          enqueueSnackbar(
            `El gasto ${data.name} ha sido creado correctamente`,
            {
              variant: 'success',
              action: () => (
                <RevertSnackbarButton
                  onClickBar={() => handleRevertTransaction(expense.id)}
                />
              ),
            },
          );
          await refetchExpenses();
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('expense', e.message, 'crear'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [
      handleClose,
      createExpense,
      enqueueSnackbar,
      refetchExpenses,
      handleRevertTransaction,
    ],
  );

  return {
    formikRef,
    handleClose,
    handleOpenCategoriesSelection,
    handleOpenProviderSelection,
    handleSubmit,
    initialValues,
    isOpen: type === ModalType.NEW_EXPENSE,
    providers,
    creationLoading: createExpenseLoading,
  };
};

export default useConnect;
