import type { FC } from 'react';
import { Formik } from 'formik';
import Loader from 'components/Loader';
import Header from 'containers/Common/Header';
import Switch from 'components/Inputs/Switch';
import { validationSchema } from './constants';
import useConnect from './connect';
import {
  Container,
  Content,
  Section,
  Row,
  SubmitButton,
  TextField,
  FormContentTitle,
  Icon,
  FormContent,
  Form,
} from './styles';

const PermissionsGroup: FC = () => {
  const { isLoading, initialValues, isNew, handleOnSubmit, handleOnRemove } =
    useConnect();
  const formId = 'permissionsGroup';

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header
        title="Grupo de Permisos"
        buttonText={!isNew ? 'Eliminar' : ''}
        buttonVariant="danger"
        onClickButton={handleOnRemove}
        showGlobalCreateButton={false}
        showRfidButton={false}
      />
      <Content>
        <Section>
          <Formik
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="name"
                  label="Nombre *"
                  id={`${formId}_firstName`}
                />
                <FormContentTitle>
                  <Icon name="products" size={20} /> Productos
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowCreateProducts`}
                      label="Crear productos"
                      name="allowCreateProducts"
                    />
                    <Switch
                      id={`${formId}_allowEditProducts`}
                      label="Editar productos"
                      name="allowEditProducts"
                    />

                    <Switch
                      id={`${formId}_allowRemoveProducts`}
                      label="Eliminar productos"
                      name="allowRemoveProducts"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowAdjustProducts`}
                      label="Ver ajustes de productos"
                      name="allowShowAdjustProducts"
                    />
                    <Switch
                      id={`${formId}_allowAdjustProducts`}
                      label="Ajustar productos"
                      name="allowAdjustProducts"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="users" size={20} /> Socios
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowCreatePartners`}
                      label="Crear socios"
                      name="allowCreatePartners"
                    />
                    <Switch
                      id={`${formId}_allowEditPartners`}
                      label="Editar socios"
                      name="allowEditPartners"
                    />
                    <Switch
                      id={`${formId}_allowRemovePartners`}
                      label="Eliminar socios"
                      name="allowRemovePartners"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="expenses" size={20} /> Gastos
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowExpenses`}
                      label="Ver gastos"
                      name="allowShowExpenses"
                    />
                    <Switch
                      id={`${formId}_allowCreateExpenses`}
                      label="Crear gastos"
                      name="allowCreateExpenses"
                    />
                    <Switch
                      id={`${formId}_allowEditExpenses`}
                      label="Editar gastos"
                      name="allowEditExpenses"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowRemoveExpenses`}
                      label="Eliminar gastos"
                      name="allowRemoveExpenses"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="calendar" size={15} /> Calendario
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowCalendars`}
                      label="Ver calendario"
                      name="allowShowCalendars"
                    />
                    <Switch
                      id={`${formId}_allowCreateCalendars`}
                      label="Crear eventos"
                      name="allowCreateCalendars"
                    />
                    <Switch
                      id={`${formId}_allowEditCalendars`}
                      label="Editar eventos"
                      name="allowEditCalendars"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowRemoveCalendars`}
                      label="Eliminar eventos"
                      name="allowRemoveCalendars"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="discount" size={20} /> Descuentos
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowDiscounts`}
                      label="Ver descuentos"
                      name="allowShowDiscounts"
                    />
                    <Switch
                      id={`${formId}_allowCreateDiscounts`}
                      label="Crear descuentos"
                      name="allowCreateDiscounts"
                    />
                    <Switch
                      id={`${formId}_allowAssignDiscounts`}
                      label="Asignar descuentos"
                      name="allowAssignDiscounts"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowEditDiscounts`}
                      label="Editar descuentos"
                      name="allowEditDiscounts"
                    />
                    <Switch
                      id={`${formId}_allowRemoveDiscounts`}
                      label="Eliminar descuentos"
                      name="allowRemoveDiscounts"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="expenses" size={20} /> Proveedores
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowProviders`}
                      label="Ver proveedores"
                      name="allowShowProviders"
                    />
                    <Switch
                      id={`${formId}_allowCreateProviders`}
                      label="Crear proveedores"
                      name="allowCreateProviders"
                    />
                    <Switch
                      id={`${formId}_allowEditProviders`}
                      label="Editar proveedores"
                      name="allowEditProviders"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowRemoveProviders`}
                      label="Eliminar proveedores"
                      name="allowRemoveProviders"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="uploadFile" size={20} /> Documentos
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowContract`}
                      label="Ver documentos"
                      name="allowShowContract"
                    />
                    <Switch
                      id={`${formId}_allowEditContract`}
                      label="Editar documento"
                      name="allowEditContract"
                    />
                    <Switch
                      id={`${formId}_allowAssignContract`}
                      label="Generar documento"
                      name="allowAssignContract"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="cashier" size={18} /> Caja
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowCashier`}
                      label="Ver caja"
                      name="allowShowCashier"
                    />
                    <Switch
                      id={`${formId}_allowShowCashierOneDay`}
                      label="Ver caja de un día"
                      name="allowShowCashierOneDay"
                    />
                    <Switch
                      id={`${formId}_allowDownloadCashierData`}
                      label="Permitir descargar csv"
                      name="allowDownloadCashierData"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowProductsCashier`}
                      label="Mostrar tabla de productos"
                      name="allowShowProductsCashier"
                    />
                    <Switch
                      id={`${formId}_allowShowPartnersCashier`}
                      label="Mostrar tabla de socios"
                      name="allowShowPartnersCashier"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="uploadFile" size={20} /> Archivos de socio
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowFiles`}
                      label="Ver archivos de socio"
                      name="allowShowFiles"
                    />
                    <Switch
                      id={`${formId}_allowCreateFiles`}
                      label="Crear archivos de socio"
                      name="allowCreateFiles"
                    />
                    <Switch
                      id={`${formId}_allowRemoveFiles`}
                      label="Eliminar archivos de socio"
                      name="allowRemoveFiles"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="quota" size={20} /> Cuotas
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowQuotas`}
                      label="Ver cuotas"
                      name="allowShowQuotas"
                    />
                    <Switch
                      id={`${formId}_allowCreateQuotas`}
                      label="Crear cuotas"
                      name="allowCreateQuotas"
                    />
                    <Switch
                      id={`${formId}_allowAssignQuotas`}
                      label="Asignar cuotas"
                      name="allowAssignQuotas"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowEditQuotas`}
                      label="Editar cuotas"
                      name="allowEditQuotas"
                    />
                    <Switch
                      id={`${formId}_allowRemoveQuotas`}
                      label="Eliminar cuotas"
                      name="allowRemoveQuotas"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="cr" size={20} /> Concepto de donación
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowCreditsConcept`}
                      label="Ver conceptos"
                      name="allowShowCreditsConcept"
                    />
                    <Switch
                      id={`${formId}_allowCreateCreditsConcept`}
                      label="Crear conceptos"
                      name="allowCreateCreditsConcept"
                    />
                    <Switch
                      id={`${formId}_allowEditCreditsConcept`}
                      label="Editar conceptos"
                      name="allowEditCreditsConcept"
                    />
                  </Row>
                  <Row>
                    <Switch
                      id={`${formId}_allowRemoveCreditsConcept`}
                      label="Eliminar conceptos"
                      name="allowRemoveCreditsConcept"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="categories" size={20} /> Categorías
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowCreateCategories`}
                      label="Crear categorías"
                      name="allowCreateCategories"
                    />
                    <Switch
                      id={`${formId}_allowEditCategories`}
                      label="Editar categorías"
                      name="allowEditCategories"
                    />
                    <Switch
                      id={`${formId}_allowRemoveCategories`}
                      label="Eliminar categorías"
                      name="allowRemoveCategories"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="char" size={20} /> Vístas
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowReports`}
                      label="Ver reportes"
                      name="allowShowReports"
                    />
                    <Switch
                      id={`${formId}_allowShowMetrics`}
                      label="Ver métricas"
                      name="allowShowMetrics"
                    />
                  </Row>
                </FormContent>

                <FormContentTitle>
                  <Icon name="organization" size={20} /> Organización
                </FormContentTitle>
                <FormContent>
                  <Row>
                    <Switch
                      id={`${formId}_allowShowTransactionsHistory`}
                      label="Ver historico de transacciones"
                      name="allowShowTransactionsHistory"
                    />
                    <Switch
                      id={`${formId}_allowPartnerRequests`}
                      label="Gestionar solicitudes de socios"
                      name="allowPartnerRequests"
                    />
                  </Row>
                </FormContent>

                <SubmitButton type="submit">
                  {isNew ? 'Crear' : 'Editar'}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </Section>
      </Content>
    </Container>
  );
};

export default PermissionsGroup;
