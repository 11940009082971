import styled, { css } from 'styled-components';
import { SEARCH_HEIGHT } from 'components/Search/styles';
import {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';
import { SectionContainer } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';
import DefaultFilters from 'components/Filters';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding-top: calc(
    ${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN} + ${SEARCH_HEIGHT} + 5rem
  );
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const FiltersContainer = styled.div`
  padding: 0 1.4rem;
  margin-bottom: 1rem;
`;

export const TagsFilter = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const TagName = styled.span`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: color 200ms ease;
`;

export const TagFilter = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  transition: border-color 200ms ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey900};

    ${TagName} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme, $selected }) =>
    $selected &&
    css`
      border-color: ${theme.colors.primary};

      ${TagName} {
        color: ${theme.colors.primary};
        font-weight: 600;
      }
    `}
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const Filters = styled(DefaultFilters)`
  padding: 0;
`;

export const ListContent = styled.div`
  margin-top: 3rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
  `};
`;
