import type { Discount } from 'model/Discount';
import type { PurchaseDiscountData } from 'apollo/graphql.types';

export const applyDiscount = ({
  discount,
  price,
}: {
  discount?: Partial<Discount> | PurchaseDiscountData | null;
  price: number;
}): number => {
  if (!discount) {
    return price;
  }

  if (discount.percentage) {
    if (discount.percentage === 100) {
      return 0;
    }

    return Number((price * ((100 - discount.percentage) / 100)).toFixed(2));
  }

  return Number((price - (discount.price || 0)).toFixed(2));
};
