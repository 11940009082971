import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultEmptyState from 'components/EmptyState';
import DefaultLink from 'components/Link';
import { SectionContainer } from 'components/Layout';
import {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';

export const Container = styled.div``;

export const Main = styled(SectionContainer)`
  padding: calc(${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN}) 0 5rem 0;
  position: relative;
  max-width: 27rem;

  ${from.tabletPortrait`
    max-width: unset;
  `};

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const Sections = styled.div`
  padding: 0 1.25rem;
`;

export const Section = styled.div`
  margin-top: 3rem;
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;

export const CashierActionStatusTitle = styled.div<{ $isOpen?: boolean }>`
  color: ${({ $isOpen, theme }) =>
    $isOpen ? theme.colors.accent : theme.colors.primary};
  margin-bottom: 1.5rem;
  padding: 0 1.25rem;
  line-height: 1.2;
  font-weight: 500;
  white-space: pre-wrap;
`;

export const DateTimeFilterContainer = styled.div`
  padding: 0 1.25rem;
`;

export const SeeMoreButton = styled(DefaultLink)`
  cursor: pointer;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  transition: color 200ms ease-out;
  width: 100%;
`;

export const SeeMore = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.black}0A;
  text-align: center;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey400}0A;
    ${SeeMoreButton} {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
  transition: background-color 200ms ease-out;
`;
