import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_CASHIER_PARTNERS } from 'apollo/queries/cashiers';
import { normalizeCashierPartner } from 'model/Cashier';
import type {
  GetCashierPartnersQuery,
  GetCashierPartnersQueryVariables,
} from 'apollo/graphql.types';

const useCashierPartners = ({
  filters,
  fetchPolicy = 'no-cache',
}: {
  filters?: { from?: Date; to?: Date };
  fetchPolicy?: QueryHookOptions<GetCashierPartnersQueryVariables>['fetchPolicy'];
} = {}) => {
  const { data, loading, refetch, error } = useQuery<
    GetCashierPartnersQuery,
    GetCashierPartnersQueryVariables
  >(GET_CASHIER_PARTNERS, {
    fetchPolicy,
    variables: {
      filters: filters
        ? {
            dates: {
              fromDate: filters.from || null,
              toDate: filters.to || null,
            },
          }
        : null,
    },
  });

  const cashierPartners = useMemo(
    () =>
      data?.cashierPartners?.length
        ? data.cashierPartners.map(normalizeCashierPartner)
        : [],
    [data],
  );

  return {
    loading,
    cashierPartners,
    refetch,
    error,
  };
};

export default useCashierPartners;
