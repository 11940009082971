import format from 'date-fns/format';
import { es as EsLocale } from 'date-fns/locale';

export const formatDate = (date: Date | number) =>
  format(date, 'dd/MM/yyyy HH:mm', { locale: EsLocale });

export const formatDate2 = (date: Date | number) =>
  format(date, 'dd/MM/yyyy', { locale: EsLocale });

export const formatTime = (date: Date | number) => format(date, 'HH:mm');

export const formatDateToTable = (date: Date | number) => {
  return format(date, "EEEE dd 'a las' HH:mm", { locale: EsLocale });
};

export const formatDateToTableExtended = (date: Date | number) => {
  return format(date, "EEEE dd 'de' MMM 'a las' HH:mm", { locale: EsLocale });
};

export const formatDateToTableExtended2 = (date: Date | number) => {
  return format(date, "dd 'de' MMM 'a las' HH:mm", { locale: EsLocale });
};
