import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useProductActions from 'apollo/hooks/product/useProductActions';
import { QuantityMovementType } from 'apollo/graphql.types';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { ProductAdjustQuantityFormFields } from 'model/Product';

const useConnect = () => {
  const { adjustProductQuantity, loading } = useProductActions();
  const { close, type, adjustProductQuantityPayload } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { product, reloadPage } = adjustProductQuantityPayload || {};

  const initialValues = useMemo<ProductAdjustQuantityFormFields>(
    () => ({
      lastBaseWeight: product?.baseWeight
        ? Number(product.baseWeight.toFixed(2))
        : 0.0,
      name: product?.name || '',
      baseWeight: '' as unknown as number,
      newQuantity: 0,
      note: '',
      movementType: QuantityMovementType.BaseWeight,
    }),
    [product],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<ProductAdjustQuantityFormFields>['onSubmit']
  >(
    async (data) => {
      try {
        if (product?.id) {
          await adjustProductQuantity(product.id, {
            ...data,
            baseWeight: Number(data?.baseWeight || 0),
          });
          enqueueSnackbar(`El producto ha sido editado correctamente`, {
            variant: 'success',
          });
          if (reloadPage) {
            window.location.reload();
          }
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'ajustar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [product, adjustProductQuantity, enqueueSnackbar, reloadPage, handleClose],
  );

  return {
    handleClose,
    handleSubmit,
    initialValues,
    isLoading: loading,
    isOpen: type === ModalType.ADJUST_PRODUCT_BASE_WEIGHT,
  };
};

export default useConnect;
