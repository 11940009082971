import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_CASHIER_CATEGORIES } from 'apollo/queries/cashiers';
import { normalizeCashierCategory } from 'model/Cashier';
import type {
  GetCashierCategoriesQuery,
  GetCashierCategoriesQueryVariables,
} from 'apollo/graphql.types';

const useCashierCategories = ({
  filters,
  fetchPolicy = 'no-cache',
}: {
  filters?: { from?: Date; to?: Date };
  fetchPolicy?: QueryHookOptions<GetCashierCategoriesQueryVariables>['fetchPolicy'];
} = {}) => {
  const { data, loading, refetch, error } = useQuery<
    GetCashierCategoriesQuery,
    GetCashierCategoriesQueryVariables
  >(GET_CASHIER_CATEGORIES, {
    fetchPolicy,
    variables: {
      filters: filters
        ? {
            dates: {
              fromDate: filters.from || null,
              toDate: filters.to || null,
            },
          }
        : null,
    },
  });

  const cashierCategories = useMemo(
    () =>
      data?.cashierCategories
        ? data.cashierCategories.map(normalizeCashierCategory)
        : [],
    [data],
  );

  return {
    loading,
    cashierCategories,
    refetch,
    error,
  };
};

export default useCashierCategories;
