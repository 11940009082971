import { useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import usePermissionsGroup from 'apollo/hooks/permissionsGroup/usePermissionsGroup';
import usePermissionsGroupActions from 'apollo/hooks/permissionsGroup/usePermissionsGroupActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { PermissionsFormFields } from 'model/PermissionsGroup';

const useConnect = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const isNew = groupId === 'new';
  const { permissionsGroup, getPermissionGroupLoading } = usePermissionsGroup({
    id: !isNew ? groupId : undefined,
  });
  const { openDialog, close: closeModal } = useModal();
  const {
    createPermissionsGroup,
    editPermissionsGroup,
    removePermissionsGroup,
    loading: actionsEmployeeLoading,
  } = usePermissionsGroupActions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues = useMemo(
    () => ({
      name: permissionsGroup?.name || '',

      // Products
      allowCreateProducts: permissionsGroup?.allowCreateProducts || false,
      allowEditProducts: permissionsGroup?.allowEditProducts || false,
      allowAdjustProducts: permissionsGroup?.allowAdjustProducts || false,
      allowShowAdjustProducts:
        permissionsGroup?.allowShowAdjustProducts || false,
      allowRemoveProducts: permissionsGroup?.allowRemoveProducts || false,

      // Partners
      allowCreatePartners: permissionsGroup?.allowCreatePartners || false,
      allowEditPartners: permissionsGroup?.allowEditPartners || false,
      allowRemovePartners: permissionsGroup?.allowRemovePartners || false,
      allowPartnerRequests: permissionsGroup?.allowPartnerRequests || false,

      // Expenses
      allowShowExpenses: permissionsGroup?.allowShowExpenses || false,
      allowCreateExpenses: permissionsGroup?.allowCreateExpenses || false,
      allowEditExpenses: permissionsGroup?.allowEditExpenses || false,
      allowRemoveExpenses: permissionsGroup?.allowRemoveExpenses || false,

      // Reports
      allowShowReports: permissionsGroup?.allowShowReports || false,

      // Metrics
      allowShowMetrics: permissionsGroup?.allowShowMetrics || false,

      // Cashier
      allowShowCashier: permissionsGroup?.allowShowCashier || false,
      allowShowCashierOneDay: permissionsGroup?.allowShowCashierOneDay || false,
      allowDownloadCashierData:
        permissionsGroup?.allowDownloadCashierData || false,
      allowShowProductsCashier:
        permissionsGroup?.allowShowProductsCashier || false,
      allowShowPartnersCashier:
        permissionsGroup?.allowShowPartnersCashier || false,

      // Calendar
      allowShowCalendars: permissionsGroup?.allowShowCalendars || false,
      allowCreateCalendars: permissionsGroup?.allowCreateCalendars || false,
      allowEditCalendars: permissionsGroup?.allowEditCalendars || false,
      allowRemoveCalendars: permissionsGroup?.allowRemoveCalendars || false,

      // Files
      allowShowFiles: permissionsGroup?.allowShowFiles || false,
      allowCreateFiles: permissionsGroup?.allowCreateFiles || false,
      allowRemoveFiles: permissionsGroup?.allowRemoveFiles || false,

      // Providers
      allowShowProviders: permissionsGroup?.allowShowProviders || false,
      allowCreateProviders: permissionsGroup?.allowCreateProviders || false,
      allowEditProviders: permissionsGroup?.allowEditProviders || false,
      allowRemoveProviders: permissionsGroup?.allowRemoveProviders || false,

      // Discounts
      allowShowDiscounts: permissionsGroup?.allowShowDiscounts || false,
      allowCreateDiscounts: permissionsGroup?.allowCreateDiscounts || false,
      allowAssignDiscounts: permissionsGroup?.allowAssignDiscounts || false,
      allowEditDiscounts: permissionsGroup?.allowEditDiscounts || false,
      allowRemoveDiscounts: permissionsGroup?.allowRemoveDiscounts || false,

      // Quotas
      allowShowQuotas: permissionsGroup?.allowShowQuotas || false,
      allowCreateQuotas: permissionsGroup?.allowCreateQuotas || false,
      allowAssignQuotas: permissionsGroup?.allowAssignQuotas || false,
      allowEditQuotas: permissionsGroup?.allowEditQuotas || false,
      allowRemoveQuotas: permissionsGroup?.allowRemoveQuotas || false,

      // Credits concepts
      allowShowCreditsConcept:
        permissionsGroup?.allowShowCreditsConcept || false,
      allowCreateCreditsConcept:
        permissionsGroup?.allowCreateCreditsConcept || false,
      allowEditCreditsConcept:
        permissionsGroup?.allowEditCreditsConcept || false,
      allowRemoveCreditsConcept:
        permissionsGroup?.allowRemoveCreditsConcept || false,

      // Categories
      allowCreateCategories: permissionsGroup?.allowCreateCategories || false,
      allowEditCategories: permissionsGroup?.allowEditCategories || false,
      allowRemoveCategories: permissionsGroup?.allowRemoveCategories || false,

      // Contract
      allowShowContract: permissionsGroup?.allowShowContract || false,
      allowEditContract: permissionsGroup?.allowEditContract || false,
      allowAssignContract: permissionsGroup?.allowAssignContract || false,

      // Organization
      allowShowTransactionsHistory:
        permissionsGroup?.allowShowTransactionsHistory || false,
    }),
    [permissionsGroup],
  );

  const handleOnSubmit = useCallback(
    async (values: PermissionsFormFields) => {
      if (isNew) {
        try {
          await createPermissionsGroup(values);
          enqueueSnackbar('El grupo de permisos se ha creado correctamente', {
            variant: 'success',
          });
          navigate('/organization/permissions');
        } catch (e) {
          enqueueSnackbar(formatErrors('permissions', e.message, 'crear'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      } else if (!isNew && permissionsGroup?.id) {
        try {
          await editPermissionsGroup({ id: permissionsGroup.id, data: values });
          enqueueSnackbar('El grupo de permisos se ha editado correctamente', {
            variant: 'success',
          });
          navigate('/organization/permissions');
        } catch (e) {
          enqueueSnackbar(
            formatErrors('permissions', e.message, 'actualizar'),
            {
              variant: 'error',
              action: () => <NotifySnackbarErrorButton error={e} />,
            },
          );
        }
      }
    },
    [
      navigate,
      isNew,
      permissionsGroup,
      createPermissionsGroup,
      enqueueSnackbar,
      editPermissionsGroup,
    ],
  );

  const handleConfirmRemove = useCallback(async () => {
    if (permissionsGroup?.id) {
      try {
        await removePermissionsGroup(permissionsGroup.id);
        enqueueSnackbar('El grupo de permisos se ha eliminado correctamente', {
          variant: 'success',
        });
        closeModal();
        navigate('/organization/permissions');
      } catch (e) {
        enqueueSnackbar(formatErrors('permissions', e.message, 'eliminar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    }
  }, [
    closeModal,
    permissionsGroup,
    navigate,
    removePermissionsGroup,
    enqueueSnackbar,
  ]);

  const handleOnRemove = useCallback(async () => {
    if (permissionsGroup?.id) {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar este permiso y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: handleConfirmRemove,
        title: 'Eliminar permiso',
        variant: 'danger',
      });
    }
  }, [permissionsGroup, openDialog, handleConfirmRemove]);

  return {
    handleOnSubmit,
    isNew,
    initialValues,
    handleOnRemove,
    isLoading: getPermissionGroupLoading || actionsEmployeeLoading,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
