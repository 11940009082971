import { FC, memo, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import {
  employeeRegistersHeaders,
  getEmployeesRegistersData,
} from 'utils/csv/employee';
import Row from './Row';
import TableHead from './TableHead';
import {
  Button,
  Container,
  EmptyState,
  Main,
  ListContent,
  Content,
} from './styles';
import type { Props } from './types';

const EmployeeLogins: FC<Props> = ({ logins, employeeName }) => {
  const hasResults = logins?.length > 0;
  const csvInfo = useMemo(() => {
    const data = getEmployeesRegistersData(logins);
    const filename = `registros_${employeeName
      .toLowerCase()
      .replace(/\s+/g, '_')}`;

    return { headers: employeeRegistersHeaders, data, filename };
  }, [logins, employeeName]);

  return (
    <Container>
      <Main>
        {!hasResults && (
          <EmptyState title="No hay registros" iconName="timer">
            No se ha creado ningún registro
          </EmptyState>
        )}

        {hasResults && (
          <Content>
            <Button variant="light_black">
              <CSVLink
                data={csvInfo.data}
                headers={csvInfo.headers}
                filename={csvInfo.filename}
                target="_blank"
              >
                Descargar
              </CSVLink>
            </Button>
            <ListContent>
              <TableHead />
              {logins?.map((login) => (
                <Row
                  key={login.id}
                  date={login.date}
                  endSession={login.endSession}
                />
              ))}
            </ListContent>
          </Content>
        )}
      </Main>
    </Container>
  );
};

export default memo(EmployeeLogins);
