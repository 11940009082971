import { useCallback, Dispatch, SetStateAction } from 'react';
import { CancelToken } from 'axios';
import imageCompression from 'browser-image-compression';
import { BaseMutationOptions } from '@apollo/client';
import uploadFile from 'utils/uploadFile';
import {
  GetImageSignedUrl,
  GetImageSignedUrlVariables,
} from 'apollo/generated/GetImageSignedUrl';
import {
  ConfirmImageUpload,
  ConfirmImageUploadVariables,
} from 'apollo/generated/ConfirmImageUpload';
import { confirmImageUpload, getImageSignedUrl } from 'apollo/requests';
import {
  ImageCategory,
  ImageExtraCategory,
} from 'apollo/generated/globalTypes';
import { normalizeFilename } from 'utils/files';

type Options = {
  getImageSignedUrlOptions?: BaseMutationOptions<
    GetImageSignedUrl,
    GetImageSignedUrlVariables
  >;
  confirmImageUploadOptions?: BaseMutationOptions<
    ConfirmImageUpload,
    ConfirmImageUploadVariables
  >;
};

const useImageSignedUrlActions = ({
  getImageSignedUrlOptions,
  confirmImageUploadOptions,
}: Options = {}) => {
  const uploadImage = useCallback(
    async ({
      file,
      name,
      imageId,
      category,
      extraCategory,
      setProgress,
      cancelToken,
    }: {
      file: File;
      name?: string;
      imageId?: string;
      category: ImageCategory;
      extraCategory?: ImageExtraCategory;
      setProgress:
        | Dispatch<SetStateAction<number | undefined>>
        | ((progress?: number) => void);
      cancelToken?: CancelToken;
    }) => {
      setProgress(0);

      const renamedFile = new File([file], normalizeFilename(file.name), {
        type: file.type,
      });

      try {
        const { uploadUrl, contentType, id, fileName, size } =
          await getImageSignedUrl({
            variables: {
              data: {
                imageId,
                file_name: name?.replace(/\s/g, '-') || renamedFile.name,
                content_type: renamedFile.type,
                file_size: renamedFile.size,
                category,
                extraCategory,
              },
              ...getImageSignedUrlOptions,
            },
          });

        try {
          const resizedFile = await imageCompression(renamedFile, {
            maxSizeMB: 0.8,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
          });

          await uploadFile({
            file: resizedFile,
            onProgress: setProgress,
            uploadUrl,
            contentType,
            cancelToken,
          });

          const confirmImageUploadData = await confirmImageUpload({
            variables: {
              data: {
                imageId: id,
                file_name: fileName,
                content_type: contentType,
                category,
                extraCategory,
                file_size: size,
              },
            },
            ...confirmImageUploadOptions,
          });

          setProgress(undefined);
          return confirmImageUploadData;
        } catch (error) {
          setProgress(undefined);
          throw new Error(error?.message);
        }
      } catch (error) {
        setProgress(undefined);
        throw new Error(error?.message);
      }
    },
    [confirmImageUploadOptions, getImageSignedUrlOptions],
  );
  return {
    uploadImage,
  };
};

export default useImageSignedUrlActions;

export type UseImageSignedUrlActions = ReturnType<
  typeof useImageSignedUrlActions
>;
