import styled, { css } from 'styled-components';
import { SEARCH_HEIGHT } from 'components/Search/styles';
import DefaultHeader, {
  HEADER_CHILDREN_MARGIN,
  HEADER_HEIGHT,
} from 'containers/Common/Header';
import { from } from 'styles/responsive';
import DefaultFilters from 'components/Filters';
import DefaultEmptyState from 'components/EmptyState';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  padding: 0 1rem;
  ${from.tabletLandscape`
    padding: 0;
  `};
`;

export const Header = styled(DefaultHeader)`
  padding: 0;
`;

export const Main = styled.div`
  padding-top: calc(
    ${HEADER_HEIGHT} + ${HEADER_CHILDREN_MARGIN} + ${SEARCH_HEIGHT} + 7rem
  );
  position: relative;
  padding-bottom: 3.6rem;

  ${from.tabletLandscape`
    padding-top: 0;
  `};
`;

export const ListContent = styled.div`
  margin-top: 3.5rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
  `};
`;

export const SquareListContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 7rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
  `};

  ${from.laptop`
    justify-content: unset;
  `};
`;

export const LandscapeListContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 7rem;
  gap: 0.8rem;

  ${from.tabletPortrait`
    margin-top: 1rem;
  `};

  ${from.laptop`
    justify-content: unset;
  `};
`;

export const OrderFloatingContainer = styled.div``;

export const Filters = styled(DefaultFilters)`
  padding: 0 1.4rem 0 0;
  width: 70%;

  ${from.tabletPortrait`
    width: 80%;
 `}

  ${from.tabletLandscape`
    width: 100%;
  `}
`;

export const FiltersContainer = styled.div``;

export const SearchContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const CategoriesFilter = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const CategoryName = styled.span`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: color 200ms ease;
`;

export const CategoryFilter = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  transition: border-color 200ms ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey900};

    ${CategoryName} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme, $selected }) =>
    $selected &&
    css`
      border-color: ${theme.colors.primary};

      ${CategoryName} {
        color: ${theme.colors.primary};
        font-weight: 500;
      }
    `}
`;

export const EditButton = styled(DefaultButton)<{ $isAccent: boolean }>`
  &&& {
    color: ${({ theme }) => theme.colors.grey900};
    border-color: ${({ theme }) => theme.colors.grey900};
    padding: 0.35rem;

    ${({ $isAccent }) =>
      $isAccent &&
      css`
        color: ${({ theme }) => theme.colors.accent};
      `}
  }
  margin-left: auto;
  margin-right: 1rem;

  ${from.tabletLandscape`
    margin-right: 0;
  `}
`;

export const EmptyState = styled(DefaultEmptyState)`
  margin: 5rem auto;
`;
