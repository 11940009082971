import { FC, memo, useCallback } from 'react';
import TableHead from './TableHead';
import TotalRow from './TotalRow';
import { Container } from './styles';
import { SeeMore, SeeMoreButton } from '../styles';
import type { Props } from './types';
import Row from './Row';

const ProductsList: FC<Props> = ({
  className,
  allowDownload,
  products,
  csv,
  filterDates,
}) => {
  const numberToShow = 6;
  const productsToShow = products.items.slice(0, numberToShow);
  const getLinkUrl = useCallback(() => {
    let url = '/cashier/products?';

    if (filterDates.from) {
      url += `start=${filterDates.from.getTime()}&`;
    }

    if (filterDates.to) {
      url += `end=${filterDates.to.getTime()}&`;
    }

    return url;
  }, [filterDates]);

  return (
    <Container className={className}>
      <TableHead allowDownload={allowDownload} csv={csv} />
      <TotalRow item={products.stats} />
      {productsToShow.map((product) => (
        <Row
          key={`${product.name}-${product.referenceCode}`}
          product={product}
        />
      ))}
      {productsToShow.length >= numberToShow && (
        <SeeMore>
          <SeeMoreButton to={getLinkUrl()}>Ver más</SeeMoreButton>
        </SeeMore>
      )}
    </Container>
  );
};

export default memo(ProductsList);
