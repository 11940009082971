import { FC, memo } from 'react';
import useLogic from './logic';
import * as S from './styles';
import type { Props } from './types';
import { TrashIcon } from './styles';

const FileInput: FC<Props> = ({
  onGetFile,
  selectedFileName = '',
  buttonText = 'Seleccionar archivo',
  acceptedFiles = 'image/*, application/pdf, text/comma-separated-values, text/csv, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel',
}) => {
  const {
    error,
    fileName,
    handleButtonClick,
    handleFileChange,
    handleRemoveFile,
    inputFileRef,
  } = useLogic(onGetFile, selectedFileName);

  return (
    <S.Container>
      <S.InputContent>
        <S.Button
          startIcon={<S.Icon name="uploadFile" />}
          fullWidth
          onClick={handleButtonClick}
          variant="light_black"
        >
          {buttonText}
          <input
            id="file-input"
            type="file"
            hidden
            onChange={handleFileChange}
            accept={acceptedFiles}
            ref={inputFileRef}
          />
        </S.Button>
        {fileName && (
          <>
            <S.FileName>{fileName}</S.FileName>
            <TrashIcon onClick={handleRemoveFile} />
          </>
        )}
      </S.InputContent>

      {error && <S.ErrorMsg>{error}</S.ErrorMsg>}
    </S.Container>
  );
};

export default memo(FileInput);
