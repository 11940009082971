import styled from 'styled-components';
import { Form } from 'formik';
import DefaultTextField from 'components/Inputs/TextField';

export const Container = styled(Form)`
  flex: 1 1 auto;
  min-width: 20rem;
`;

export const TextField = styled(DefaultTextField)`
  margin-top: 1rem;

  .MuiFormLabel-root {
    color: ${({ theme }) => theme.colors.accent} !important;
  }
`;
