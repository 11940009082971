import { gql } from '@apollo/client';
import { PURCHASE_FRAGMENT } from './Purchase';
import { IMAGE_FRAGMENT } from './Image';
import { FILE_FRAGMENT } from './File';

export const PARTNER_QUOTA_FRAGMENT = gql`
  fragment PartnerQuotaData on PartnerQuota {
    id
    addedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    discountData {
      ... on PurchaseDiscountData {
        name
        percentage
        price
      }
    }
    canceled
    canceledAt
    canceledBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    createdAt
    nextPayment
    quota {
      ... on Quota {
        id
        name
        price
        type
        value
      }
    }
  }
`;

export const PARTNER_NOTE_FRAGMENT = gql`
  fragment PartnerNoteData on PartnerNote {
    id
    addedByName
    createdAt
    description
    lastEditionByName
    showNotification
    updatedAt
  }
`;

export const PARTNER_FRAGMENT = gql`
  fragment PartnerData on Partner {
    id
    usage
    rfidCode
    discounts {
      ... on Discount {
        id
        name
        percentage
        price
        type
      }
    }
    memberNum
    maxConsumeMonth
    monthConsume
    notes {
      ...PartnerNoteData
    }
    files {
      ...FileData
    }
    credits
    hostMemberNum
    hostMember {
      id
      hostMemberNum
      memberNum
      birthDate
      createdAt
      deletedAt
      usage
      maxConsumeMonth
      userId
      firstName
      lastName
      phoneNumber
      email
      role
      document
      address
      zipCode
      city
    }
    user {
      ... on User {
        address
        avatar {
          ...ImageData
        }
        birthDate
        city
        country
        createdAt
        document
        email
        firstName
        gender
        lastName
        phoneNumber
        id
        zipCode
        role
      }
    }
    supportedPartners {
      id
      memberNum
      deletedAt
      hostMemberNum
      user {
        ... on User {
          email
          firstName
          lastName
          document
          address
          phoneNumber
        }
      }
    }
    partnerQuota {
      ...PartnerQuotaData
    }
    registers {
      id
      date
    }
    updatedAt
    createdAt
    lastRegister
  }
  ${PARTNER_QUOTA_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${PARTNER_NOTE_FRAGMENT}
  ${FILE_FRAGMENT}
`;

export const PARTNER_LIST_FRAGMENT = gql`
  fragment PartnerListData on Partner {
    id
    memberNum
    credits
    hostMemberNum
    rfidCode
    user {
      ... on User {
        document
        firstName
        lastName
        email
        avatar {
          ... on Image {
            url
          }
        }
      }
    }
    partnerQuota {
      ... on PartnerQuota {
        nextPayment
      }
    }
  }
`;

export const PARTNER_QUOTA_TRANSACTION_FRAGMENT = gql`
  fragment PartnerQuotaTransactionData on PartnerQuotaTransaction {
    id
    addedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    canceled
    canceledAt
    discountData {
      ... on PurchaseDiscountData {
        name
        percentage
        price
      }
    }
    canceledBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    createdAt
    quota {
      ... on Quota {
        id
        name
        price
        type
        value
      }
    }
    partner {
      ... on Partner {
        id
        memberNum
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const PARTNER_CREDITS_TRANSACTION_FRAGMENT = gql`
  fragment PartnerCreditTransactionData on PartnerCreditTransaction {
    id
    action
    addedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    credits
    canceled
    canceledAt
    canceledBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    partner {
      ... on Partner {
        id
        memberNum
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    concept
    date
  }
`;

export const PARTNER_STATISTICS_FRAGMENT = gql`
  fragment PartnerTransactionsData on PartnerTransactions {
    partner {
      ...PartnerData
    }
    transactions {
      purchases {
        ...PurchaseData
      }
      credits {
        ...PartnerCreditTransactionData
      }
      quotas {
        ...PartnerQuotaData
      }
    }
  }
  ${PARTNER_FRAGMENT}
  ${PURCHASE_FRAGMENT}
  ${PARTNER_CREDITS_TRANSACTION_FRAGMENT}
  ${PARTNER_QUOTA_FRAGMENT}
`;
