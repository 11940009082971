import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar, permissionsStateVar } from 'apollo/reactive';
import useCashierProducts from 'apollo/hooks/cashier/useCashierProducts';
import useMostUsedCategories from 'apollo/hooks/category/useMostUsedCategories';
import {
  cashierProductHeaders,
  getCashierProductData,
  getCashierProductFilename,
} from 'utils/csv/cashier';

const useConnect = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { activateProductReference } = useReactiveVar(organizationConfigVar);
  const { mostUsedCategories, loading: getCategoriesLoading } =
    useMostUsedCategories();
  const [searchParams] = useSearchParams();
  const startFromPram = searchParams.get('start');
  const endFromParam = searchParams.get('end');

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startFromPram
      ? new Date(Number(startFromPram))
      : startOfDay(subDays(new Date(), 1)),
    to: endFromParam ? new Date(Number(endFromParam)) : endOfDay(new Date()),
  });

  const { cashierProducts, loading, refetch } = useCashierProducts({
    filters: filterDates,
  });

  useEffect(() => {
    refetch({
      filters: {
        dates: {
          fromDate: filterDates.from || null,
          toDate: filterDates.to || null,
        },
        categories: selectedCategories,
      },
    });
  }, [filterDates, refetch, selectedCategories]);

  const csvInfo = useMemo(() => {
    const data = getCashierProductData({
      showProductReference: activateProductReference,
      products: cashierProducts,
    });

    const filename = getCashierProductFilename({
      from: filterDates.from ? new Date(filterDates.from) : new Date(),
      to: filterDates.to ? new Date(filterDates.to) : new Date(),
    });

    return { headers: cashierProductHeaders, data, filename };
  }, [activateProductReference, cashierProducts, filterDates]);

  const isSelectedCategory = useCallback(
    (name: string) =>
      !!selectedCategories.find((category) => category === name),
    [selectedCategories],
  );

  const handleToggleCategory = useCallback(
    async (name: string) => {
      const exists = isSelectedCategory(name);
      if (exists) {
        setSelectedCategories((prev) =>
          prev.filter((category) => category !== name),
        );
      } else {
        setSelectedCategories((prev) => [...prev, name]);
      }
    },
    [isSelectedCategory],
  );

  const handleOnSearchDates = ({ from, to }: { from?: Date; to?: Date }) => {
    setFilterDate({ from, to });
  };

  return {
    products: cashierProducts,
    csvInfo,
    isLoading: loading || getCategoriesLoading,
    filterDates,
    employeePermissions,
    mostUsedCategories,
    isSelectedCategory,
    handleToggleCategory,
    handleOnSearchDates,
  };
};

export default useConnect;
