import { useCallback, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useCategoriesActions from 'apollo/hooks/category/useCategoriesActions';
import { CategoryType } from 'apollo/generated/globalTypes';
import useCategories from 'apollo/hooks/category/useCategories';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { CategoryFormFields } from 'model/Category';

const useConnect = () => {
  const { close, type, categoryPayload } = useModal();
  const {
    createCategory,
    updateCategory,
    loading: categoryActionsLoading,
  } = useCategoriesActions();
  const { refetch } = useCategories();
  const { enqueueSnackbar } = useSnackbar();

  const { category } = categoryPayload || {};

  const initialValues = useMemo<CategoryFormFields>(
    () => ({
      name: category?.name || '',
      type: category?.type || CategoryType.PRODUCT,
      description: category?.description || '',
      showInCashier: category?.showInCashier || false,
    }),
    [category],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<CategoryFormFields>['onSubmit']
  >(
    async (data: CategoryFormFields) => {
      try {
        if (category?.id) {
          await updateCategory({ id: category.id, data });
        } else {
          await createCategory(data);
        }
        enqueueSnackbar(
          `La categoría ha sido ${
            category?.id ? 'editada' : 'creada'
          } correctamente`,
          {
            variant: 'success',
          },
        );
        await refetch();
        close();
      } catch (e) {
        enqueueSnackbar(
          formatErrors(
            'category',
            e.message,
            category?.id ? 'actualizar' : 'crear',
          ),
          {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          },
        );
      }
    },
    [category, enqueueSnackbar, refetch, close, updateCategory, createCategory],
  );

  return {
    handleSubmit,
    handleClose,
    initialValues,
    isOpen: type === ModalType.CATEGORY,
    isLoading: categoryActionsLoading,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
