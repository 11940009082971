import { memo } from 'react';
import type { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import { formatDateToTable, formatDate } from 'utils/dates/formatDates';
import {
  Container,
  Credits,
  Date,
  Employee,
  EmployeeContent,
  ExtraQuantity,
  Partner,
  Quantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, transaction }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const { employee, partner, quantity, extraQuantity, total, date } =
    transaction;

  return (
    <Container className={className}>
      <EmployeeContent>
        <Employee to={`/employees/${employee.id}`}>
          {employee.fullName}
        </Employee>
      </EmployeeContent>
      <Partner>
        <Employee to={`/partners/${partner.id}`}>{partner.fullName}</Employee>
      </Partner>
      <Date>
        {fromTabletPortrait ? formatDateToTable(date) : formatDate(date)}
      </Date>
      <Quantity>{quantity.toFixed(2)}</Quantity>
      <ExtraQuantity>{extraQuantity.toFixed(2)}</ExtraQuantity>
      <Credits>
        <Price>{Number(total.toFixed(2))}</Price>
      </Credits>
    </Container>
  );
};

export default memo(Row);
