import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const State = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 5%;
  ${TextEllipsis};

  ${from.tabletLandscape`
   flex: 0 1 ${TABLE_METRICS.state};
 `}
`;

export const Note = styled.div`
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.note};
`;

export const NoteData = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-flow: column;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  white-space: initial;
`;

export const ExtraConcept = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-top: 0.2rem;
  white-space: initial;
`;

export const Items = styled.div<{ $hasItems: boolean }>`
  color: ${({ $hasItems, theme }) =>
    $hasItems ? theme.colors.accent : theme.colors.primary};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 20%;
  text-align: center;
  text-transform: capitalize;
  ${TextEllipsis};

  ${({ $hasItems }) =>
    $hasItems &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}

  ${from.tabletLandscape`
   font-size: 1rem;
   flex: 0 1 ${TABLE_METRICS.items};
 `}
`;

export const Date = styled.div`
  display: flex;
  flex-flow: column;
  flex: 0 0 22%;
  text-align: center;
  ${TextEllipsis};
  white-space: pre-wrap;

  ${from.tabletLandscape`
   margin-right: 1rem;
   flex: 0 0 ${TABLE_METRICS.date};
 `}
`;

export const DateItem = styled.div<{
  $type?: 'canceled' | 'second';
}>`
  color: ${({ theme, $type }) => {
    switch ($type) {
      case 'canceled':
        return theme.colors.danger;
      case 'second':
        return theme.colors.grey500;
      default:
        return theme.colors.primary;
    }
  }};

  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Credits = styled.div<{
  $creditsAdded: boolean;
  $isZeroCredit: boolean;
}>`
  color: ${({ $creditsAdded, $isZeroCredit, theme }) =>
    $creditsAdded
      ? theme.colors.green
      : ($isZeroCredit && theme.colors.primary) || theme.colors.danger};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 18%;
  text-align: center;
  ${TextEllipsis};

  ${from.tabletLandscape`
   font-size: 1rem;
   flex: 0 0 ${TABLE_METRICS.credits};
 `}
`;

export const CreditValue = styled.span<{
  $isReverted?: boolean;
}>`
  ${({ $isReverted }) =>
    $isReverted &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      position: relative;
      max-width: fit-content;
      &:before {
        border-color: inherit;
        border-top: 1px solid;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 45%;
        transform: skewY(-10deg);
      }
    `}
`;

export const Revert = styled.div`
  align-items: flex-end;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  flex-flow: column;
  flex: 0 1 15%;
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  text-align: center;

  ${from.tabletLandscape`
   font-size: 0.9rem;
   flex: 0 0 ${TABLE_METRICS.revert};
 `}
`;

export const Icon = styled(DefaultIcon)<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

export const IconBorder = styled.div`
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey700};
  border-radius: 0.25rem;
  max-width: fit-content;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #e53935;
    ${Icon} {
      color: white;
    }
  }
`;

export const Employee = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  margin-bottom: 0.4rem;
  text-transform: capitalize;
  max-width: 12rem;
  ${TextEllipsis};

  &:hover {
    text-decoration: underline;
  }
`;
