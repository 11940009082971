import { FC, memo } from 'react';
import Form from './Form';
import useConnect from './connect';
import { Container, Title, IconCircle, Icon, Header } from './styles';

const AdjustProductBaseWeightModal: FC = () => {
  const { handleClose, handleSubmit, initialValues, isLoading, isOpen } =
    useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="flower" size={24} />
        </IconCircle>
        <Title>Ajuste Peso Base de {initialValues.name}</Title>
      </Header>
      <Form
        initialValues={initialValues}
        isLoading={isLoading}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default memo(AdjustProductBaseWeightModal);
