import { FC, memo } from 'react';
import Price from 'components/Price';
import {
  Container,
  InfoContent,
  InfoContentBody,
  InfoContentBodyContainer,
  InfoContentHeader,
  InfoContentLabel,
  InfoContentTitle,
  InfoContentValue,
} from './styles';
import type { Props } from './types';

const BestTimes: FC<Props> = ({ purchasesTimes }) => {
  return (
    <Container>
      <InfoContent>
        <InfoContentHeader>
          <InfoContentTitle>Mejores Meses</InfoContentTitle>
          <InfoContentTitle>|</InfoContentTitle>
          <InfoContentTitle>Peores Meses</InfoContentTitle>
        </InfoContentHeader>
        <InfoContentBodyContainer>
          <InfoContentBody>
            {purchasesTimes.topMonths.map((topMonth) => (
              <InfoContentLabel>
                {topMonth.value}:{' '}
                <InfoContentValue>
                  <Price>{topMonth?.total || 0.0}</Price>
                </InfoContentValue>
              </InfoContentLabel>
            ))}
          </InfoContentBody>
          <InfoContentBody>
            {purchasesTimes.bottomMonths.map((bottomMonth) => (
              <InfoContentLabel>
                {bottomMonth.value}:{' '}
                <InfoContentValue>
                  <Price>{bottomMonth?.total || 0.0}</Price>
                </InfoContentValue>
              </InfoContentLabel>
            ))}
          </InfoContentBody>
        </InfoContentBodyContainer>
      </InfoContent>
      <InfoContent>
        <InfoContentHeader>
          <InfoContentTitle>Mejores Días</InfoContentTitle>
          <InfoContentTitle>|</InfoContentTitle>
          <InfoContentTitle>Peores Días</InfoContentTitle>
        </InfoContentHeader>
        <InfoContentBodyContainer>
          <InfoContentBody>
            {purchasesTimes.topDays.map((topDay) => (
              <InfoContentLabel>
                {topDay.value}:{' '}
                <InfoContentValue>
                  <Price>{topDay?.total || 0.0}</Price>
                </InfoContentValue>
              </InfoContentLabel>
            ))}
          </InfoContentBody>
          <InfoContentBody>
            {purchasesTimes.bottomDays.map((bottomDay) => (
              <InfoContentLabel>
                {bottomDay.value}:{' '}
                <InfoContentValue>
                  <Price>{bottomDay?.total || 0.0}</Price>
                </InfoContentValue>
              </InfoContentLabel>
            ))}
          </InfoContentBody>
        </InfoContentBodyContainer>
      </InfoContent>
      <InfoContent>
        <InfoContentHeader>
          <InfoContentTitle>Mejores Horas</InfoContentTitle>
          <InfoContentTitle>|</InfoContentTitle>
          <InfoContentTitle>Peores Horas</InfoContentTitle>
        </InfoContentHeader>
        <InfoContentBodyContainer>
          <InfoContentBody>
            {purchasesTimes.topHours.map((topHour) => (
              <InfoContentLabel>
                {topHour.value}:{' '}
                <InfoContentValue>
                  <Price>{topHour?.total || 0.0}</Price>
                </InfoContentValue>
              </InfoContentLabel>
            ))}
          </InfoContentBody>
          <InfoContentBody>
            {purchasesTimes.bottomHours.map((bottomHour) => (
              <InfoContentLabel>
                {bottomHour.value}:{' '}
                <InfoContentValue>
                  <Price>{bottomHour?.total || 0.0}</Price>
                </InfoContentValue>
              </InfoContentLabel>
            ))}
          </InfoContentBody>
        </InfoContentBodyContainer>
      </InfoContent>
    </Container>
  );
};

export default memo(BestTimes);
