import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import useModal from 'apollo/hooks/useModal';
import usePurchaseActions from 'apollo/hooks/usePurchaseActions';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import useEmployeeTransactions from 'apollo/hooks/employee/useEmployeeTransactions';
import useExpenseActions from 'apollo/hooks/expense/useExpenseActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { TransactionType } from 'model/Transaction';
import type { Order } from 'model/Order';
import type { Discount } from 'model/Discount';

const useConnect = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(subDays(new Date(), 2)),
    to: endOfDay(new Date()),
  });
  const {
    transactions,
    refetch,
    loading: getTransactionLoading,
  } = useEmployeeTransactions({ id: employeeId, filters: filterDates });
  const { cancelPurchase, loading: purchaseActionsLoading } =
    usePurchaseActions();
  const {
    cancelCreditsTransaction,
    cancelQuotaTransaction,
    loading: partnerActionsLoading,
  } = usePartnerActions();
  const { cancelExpense, loading: expenseActionsLoading } = useExpenseActions();
  const { enqueueSnackbar } = useSnackbar();
  const { openPurchaseDetails, openDialog, close: closeModal } = useModal();

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
      await refetch({
        id: employeeId,
        filters: {
          fromDate: from || null,
          toDate: to || null,
        },
      });
    },
    [refetch, employeeId],
  );

  const handleOpenPurchaseDetailsModal = useCallback(
    async (orders: Order[], discount?: Partial<Discount>) => {
      if (orders.length > 0) {
        openPurchaseDetails({ orders, discount });
      }
    },
    [openPurchaseDetails],
  );

  const handleCancelTransaction = useCallback(
    async ({ id, type }: { id: string; type: TransactionType }) => {
      try {
        if (id && type) {
          if (type === TransactionType.PURCHASE) {
            await cancelPurchase(id);
          } else if (type === TransactionType.CREDITS) {
            await cancelCreditsTransaction(id);
          } else if (type === TransactionType.QUOTA) {
            await cancelQuotaTransaction(id);
          } else if (type === TransactionType.EXPENSE) {
            await cancelExpense(id);
          }

          closeModal();
          await refetch();
          enqueueSnackbar('La transacción se ha cancelado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('transaction', e.message, 'cancelar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [
      cancelCreditsTransaction,
      cancelExpense,
      cancelPurchase,
      cancelQuotaTransaction,
      closeModal,
      enqueueSnackbar,
      refetch,
    ],
  );

  const handleRevertTransaction = useCallback(
    async ({ id, type }: { id: string; type: TransactionType }) => {
      openDialog({
        acceptButtonText: 'Revertir',
        cancelButtonText: 'Cerrar',
        description:
          'Vas a cancelar la transacción, es una acción que no se puede deshacer, ¿quieres cancelar?',
        onAccept: () => handleCancelTransaction({ id, type }),
        title: 'Revertir transacción',
        variant: 'danger',
      });
    },
    [openDialog, handleCancelTransaction],
  );

  return {
    handleOpenPurchaseDetailsModal,
    handleRevertTransaction,
    isLoading:
      getTransactionLoading ||
      purchaseActionsLoading ||
      partnerActionsLoading ||
      expenseActionsLoading,
    transactions,
    filterDates,
    handleOnSearch,
  };
};

export default useConnect;
