import { FC, memo } from 'react';
import { Icon, IconCircle, Image, Initials } from './styles';
import type { Props } from './types';
import useLogic from './logic';

const Avatar: FC<Props> = ({
  className,
  email,
  fullName,
  src,
  useIcon,
  size = 6,
  isSelected,
}) => {
  const { initials } = useLogic({ email, fullName });
  const avatarMissing = !src || src[0] === '/';

  return !avatarMissing ? (
    <Image $size={size} alt="avatar" className={className} src={src} />
  ) : (
    <>
      {useIcon ? (
        <IconCircle $isSelected={isSelected} $size={size} className={className}>
          <Icon $isSelected={isSelected} />
        </IconCircle>
      ) : (
        <Initials $size={size} className={className}>
          {initials}
        </Initials>
      )}
    </>
  );
};

export default memo(Avatar);
