import { gql } from '@apollo/client';

export const CASHIER_FRAGMENT = gql`
  fragment CashierData on Cashier {
    id
    createdAt
    end
    lastRestRealCredits
    note
    realCreditsAdded
    restRealCredits
    start
    totalCreditsAdded
    totalExpenses
    updatedAt
    typeOfCash {
      key
      value
    }

    # Relations
    openedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    closedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const CASHIER_QUOTA_BY_PERIOD_FRAGMENT = gql`
  fragment CashierQuotaByPeriodData on CashierQuota {
    id
    quotaName
    credits
    partners {
      id
      fullName
      num
      credits
      discountName
    }
  }
`;

export const CASHIER_PRODUCTS_BY_PERIOD_FRAGMENT = gql`
  fragment CashierProductsByPeriodData on CashierProducts {
    stats {
      totalPurchases
      totalCosts
      totalDiscounts
      newProducts
    }
    items {
      id
      consumedQuantity
      name
      referenceCode
      quantity
      totalCosts
      totalPurchases
      totalDiscounts
      isNew
    }
  }
`;

export const CASHIER_PARTNERS_BY_PERIOD_FRAGMENT = gql`
  fragment CashierPartnersByPeriodData on CashierPartner {
    id
    fullName
    memberNum
    totalPurchases
    addedCredits
    isNew
    totalDiscounts
    products {
      id
      consumedQuantity
      name
      referenceCode
      quantity
      totalCosts
      totalPurchases
      isNew
      totalDiscounts
    }
  }
`;

export const CASHIER_CATEGORY_BY_PERIOD_FRAGMENT = gql`
  fragment CashierCategoryByPeriodData on CashierCategory {
    id
    name
    totalPurchases
    totalCosts
    totalDiscounts
    totalConsumedQuantity
    products {
      id
      consumedQuantity
      name
      referenceCode
      quantity
      totalCosts
      totalPurchases
      isNew
      totalDiscounts
    }
  }
`;

export const CASHIER_BY_PERIOD_FRAGMENT = gql`
  fragment CashierByPeriodData on CashierByPeriod {
    totalExpenses
    totalRealCredits
    lastRealCredits
    products {
      ...CashierProductsByPeriodData
    }
    quotas {
      ...CashierQuotaByPeriodData
    }
    partners {
      ...CashierPartnersByPeriodData
    }
    categories {
      ...CashierCategoryByPeriodData
    }
  }
  ${CASHIER_QUOTA_BY_PERIOD_FRAGMENT}
  ${CASHIER_CATEGORY_BY_PERIOD_FRAGMENT}
  ${CASHIER_PRODUCTS_BY_PERIOD_FRAGMENT}
  ${CASHIER_PARTNERS_BY_PERIOD_FRAGMENT}
`;
