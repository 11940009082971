import { FC } from 'react';
import Price from 'components/Price';
import {
  Container,
  InfoContent,
  InfoContentBody,
  InfoContentHeader,
  InfoContentLabel,
  InfoContentTitle,
  InfoContentValue,
} from './styles';
import type { Props } from './types';

const InfoData: FC<Props> = ({ cashier }) => {
  return (
    <Container>
      <InfoContent>
        <InfoContentHeader>
          <InfoContentTitle>Movimiento de créditos</InfoContentTitle>
        </InfoContentHeader>
        <InfoContentBody>
          <InfoContentLabel>
            Ingresos:{' '}
            <InfoContentValue>
              <Price>{cashier?.depositedCredits || 0.0}</Price>
            </InfoContentValue>
          </InfoContentLabel>
          <InfoContentLabel>
            Gastos:{' '}
            <InfoContentValue>
              <Price>{cashier?.totalExpenses || 0.0}</Price>
            </InfoContentValue>
          </InfoContentLabel>
        </InfoContentBody>
      </InfoContent>
      <InfoContent>
        <InfoContentHeader>
          <InfoContentTitle>Resumen cierres de caja</InfoContentTitle>
        </InfoContentHeader>
        <InfoContentBody>
          <InfoContentLabel>
            Créditos en caja:{' '}
            <InfoContentValue>
              <Price>{cashier?.totalRealCredits || 0.0}</Price>
            </InfoContentValue>
          </InfoContentLabel>
          <InfoContentLabel>
            Último resto:{' '}
            <InfoContentValue>
              <Price>{cashier?.lastRealCredits || 0.0}</Price>
            </InfoContentValue>
          </InfoContentLabel>
        </InfoContentBody>
      </InfoContent>
      <InfoContent>
        <InfoContentHeader>
          <InfoContentTitle>Registros</InfoContentTitle>
        </InfoContentHeader>
        <InfoContentBody>
          <InfoContentLabel>
            Nuevos socios:{' '}
            <InfoContentValue>{cashier?.newPartners || 0}</InfoContentValue>
          </InfoContentLabel>
          <InfoContentLabel>
            Nuevos productos:{' '}
            <InfoContentValue>
              {cashier?.products?.stats?.newProducts || 0}
            </InfoContentValue>
          </InfoContentLabel>
        </InfoContentBody>
      </InfoContent>
    </Container>
  );
};

export default InfoData;
