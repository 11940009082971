import type { FC } from 'react';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, EmptyState, Main, DateTimeFilterContainer } from './styles';

const EmployeeTransactions: FC = () => {
  const {
    handleOpenPurchaseDetailsModal,
    handleRevertTransaction,
    isLoading,
    transactions,
    filterDates,
    handleOnSearch,
  } = useConnect();

  const hasResults = transactions?.length > 0;

  return (
    <Container>
      <DateTimeFilterContainer>
        <DateTimeFilter
          fromDate={filterDates.from}
          toDate={filterDates.to}
          onSearch={handleOnSearch}
        />
      </DateTimeFilterContainer>
      {isLoading && <Loader />}
      {!isLoading && (
        <Main>
          {!hasResults && (
            <EmptyState
              title="No se puede generar la información"
              iconName="char"
            >
              No hay datos suficientes para generar estadísticas de este
              empleado
            </EmptyState>
          )}
          {hasResults && (
            <>
              <TableHead />
              {transactions.map((transaction) => (
                <Row
                  key={transaction.id}
                  transaction={transaction}
                  onClickItems={handleOpenPurchaseDetailsModal}
                  onRevert={() =>
                    handleRevertTransaction({
                      id: transaction.id,
                      type: transaction.type,
                    })
                  }
                />
              ))}
            </>
          )}
        </Main>
      )}
    </Container>
  );
};

export default EmployeeTransactions;
