import { gql } from '@apollo/client';

export const ORDER_FRAGMENT = gql`
  fragment OrderData on Order {
    id
    extraQuantity
    quantity
    updatedAt
    createdAt
    productPrice
    productWithoutDiscountPrice
    productDiscountName
    discountData {
      ... on PurchaseDiscountData {
        name
        percentage
        price
      }
    }
    product {
      ... on Product {
        id
        name
        price
        referenceCode
      }
    }
  }
`;
