import { FC, memo, useCallback } from 'react';
import TableHead from './TableHead';
import Row from './Row';
import useLogic from './logic';
import { Container } from './styles';
import { SeeMore, SeeMoreButton } from '../styles';
import type { Props } from './types';

const ProductsList: FC<Props> = ({
  className,
  allowDownload,
  categories,
  csv,
  filterDates,
}) => {
  const { handleShowProducts } = useLogic();
  const numberToShow = 6;
  const categoriesToShow = categories.slice(0, numberToShow);
  const getLinkUrl = useCallback(() => {
    let url = '/cashier/categories?';

    if (filterDates.from) {
      url += `start=${filterDates.from.getTime()}&`;
    }

    if (filterDates.to) {
      url += `end=${filterDates.to.getTime()}&`;
    }

    return url;
  }, [filterDates]);

  return (
    <Container className={className}>
      <TableHead allowDownload={allowDownload} csv={csv} />
      {categoriesToShow.map((category) => (
        <Row
          key={category.id}
          category={category}
          onSelectProducts={handleShowProducts}
        />
      ))}
      {categoriesToShow.length >= numberToShow && (
        <SeeMore>
          <SeeMoreButton to={getLinkUrl()}>Ver más</SeeMoreButton>
        </SeeMore>
      )}
    </Container>
  );
};

export default memo(ProductsList);
