import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`;

export const Section = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border: 1px solid #6d7a8229;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  max-width: 350px;
  width: 100%;
`;

export const SectionHeader = styled.div`
  border-bottom: 1px solid #6d7a8229;
  margin-bottom: 0.8rem;
`;

export const SectionTitle = styled.h1`
  color: #6d7a82;
  font-size: 1rem;
  font-weight: 400;
`;

export const ConsumptionLevel = styled.h3`
  color: #021120;
  font-weight: 600;
  font-size: 26px;
  padding-bottom: 1rem;
`;

export const ConsumptionInfo = styled.p`
  color: #6d7a82;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
`;
