import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { normalizePartnerConsumptionsStats } from 'model/Partner';
import { PARTNER_CONSUMPTIONS_STATS } from 'apollo/queries/partners';
import type {
  GetPartnerConsumptionsStatsQuery,
  GetPartnerConsumptionsStatsQueryVariables,
} from 'apollo/graphql.types';

const usePartnerConsumptionsStats = (id?: string) => {
  const { loading, data } = useQuery<
    GetPartnerConsumptionsStatsQuery,
    GetPartnerConsumptionsStatsQueryVariables
  >(PARTNER_CONSUMPTIONS_STATS, {
    skip: !id,
    variables: {
      id: id!,
    },
  });

  const consumptionsStats = useMemo(
    () =>
      data?.partnerConsumptionsStats
        ? normalizePartnerConsumptionsStats(data.partnerConsumptionsStats)
        : null,
    [data],
  );

  return {
    loading,
    consumptionsStats,
  };
};

export default usePartnerConsumptionsStats;
