import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import { ProductsListType } from 'apollo/generated/globalTypes';
import OrderFloatingAction from 'components/OrderFloatingAction';
import ListFormat from './ListFormat';
import SquareFormat from './SquareFormat';
import LandscapeFormat from './LandscapeFormat';
import {
  Container,
  Header,
  EmptyState,
  Main,
  OrderFloatingContainer,
  FiltersContainer,
  EditButton,
  Filters,
  ListContent,
  SearchContainer,
  CategoriesFilter,
  CategoryFilter,
  CategoryName,
  SquareListContent,
  LandscapeListContent,
} from './styles';
import { useConnect } from './connect';

const Products: FC = () => {
  const {
    employeePermissions,
    filter,
    handle,
    isEditing,
    isProductSelected,
    isRefetching,
    isSelectedCategory,
    inactiveProductsFilter,
    mostUsedCategories,
    openNewProductModal,
    orderTotal,
    products,
    selectedProductCount,
    productsDisplayType,
  } = useConnect();

  const organizationConfig = useReactiveVar(organizationConfigVar);
  const hasResults = products?.length > 0;
  const hasMostUsedCategories = mostUsedCategories.length > 0;

  return (
    <Container>
      <Header title="Productos">
        <FiltersContainer>
          <SearchContainer>
            <Filters
              isLoading={isRefetching}
              onSearchChange={handle.search}
              filter={filter}
              nResults={products?.length || 0}
            />
            {employeePermissions.allowEditProducts && (
              <EditButton
                variant="light_black"
                $isAccent={isEditing}
                onClick={handle.editButtonClick}
              >
                {isEditing ? 'Guardar' : 'Editar'}
              </EditButton>
            )}
          </SearchContainer>
          <CategoriesFilter>
            <>
              {hasMostUsedCategories &&
                mostUsedCategories.map((category) => (
                  <CategoryFilter
                    key={category.id}
                    $selected={isSelectedCategory(category.name)}
                    onClick={() => handle.toggleCategory(category.name)}
                  >
                    <CategoryName>{category.name}</CategoryName>
                  </CategoryFilter>
                ))}
              <CategoryFilter
                $selected={inactiveProductsFilter}
                onClick={handle.toggleInactiveProducts}
              >
                <CategoryName>Inactivos</CategoryName>
              </CategoryFilter>
            </>
          </CategoriesFilter>
        </FiltersContainer>
      </Header>
      <Main>
        {!hasResults && (
          <EmptyState
            title="No hay productos"
            iconName="products"
            buttonLabel="Crear producto"
            onClickButton={openNewProductModal}
          >
            Aún no has creado ningún producto, crea al menos uno para mostrar la
            información
          </EmptyState>
        )}

        {hasResults && productsDisplayType === ProductsListType.LIST && (
          <ListContent>
            {products.map((product) => (
              <ListFormat
                activateProductReference={
                  organizationConfig.activateProductReference
                }
                isSelected={isProductSelected(product.id)}
                key={product.id}
                onClick={handle.productClick}
                product={product}
              />
            ))}
          </ListContent>
        )}

        {hasResults && productsDisplayType === ProductsListType.SQUARE && (
          <SquareListContent>
            {products.map((product) => (
              <SquareFormat
                isSelected={isProductSelected(product.id)}
                key={product.id}
                onClick={handle.productClick}
                product={product}
                activateProductReference={
                  organizationConfig.activateProductReference
                }
              />
            ))}
          </SquareListContent>
        )}

        {hasResults && productsDisplayType === ProductsListType.LANDSCAPE && (
          <LandscapeListContent>
            {products.map((product, index) => (
              <LandscapeFormat
                key={product.id}
                isSelected={isProductSelected(product.id)}
                onClick={handle.productClick}
                product={product}
                isEditingMode={isEditing}
                isReverse={index % 2 === 1}
                activateProductReference={
                  organizationConfig.activateProductReference
                }
              />
            ))}
          </LandscapeListContent>
        )}

        {!isEditing && selectedProductCount > 0 && (
          <OrderFloatingContainer onClick={handle.orderClick}>
            <OrderFloatingAction
              count={selectedProductCount}
              total={orderTotal}
            />
          </OrderFloatingContainer>
        )}
      </Main>
    </Container>
  );
};

export default Products;
