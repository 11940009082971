import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import { ModalType } from 'apollo/reactive/modal';
import { translateQuotaType } from 'utils/quotas';
import { formatErrors } from '../../../utils/errors/formatErrors';
import NotifySnackbarErrorButton from '../../../components/NotifySnackbarErrorButton';

const useConnect = () => {
  const { deletePartnerQuota } = usePartnerActions();
  const { enqueueSnackbar } = useSnackbar();
  const { close, type, quotaDetailsPayload, openDialog } = useModal();
  const { quota, partnerId, onRefetch } = quotaDetailsPayload || {};

  const initialValues = useMemo(
    () => ({
      name: quota?.name || '',
      price: quota?.price || 0,
      value: translateQuotaType(quota?.type, quota?.value),
      nextPayment: quota?.nextPayment || '',
      assignedDate: quota?.assignedDate || '',
    }),
    [quota],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleOnRemove = useCallback(async () => {
    if (quota?.id && partnerId) {
      try {
        await deletePartnerQuota({ partnerQuotaId: quota.id, partnerId });
        enqueueSnackbar('El socio se ha actualizado correctamente', {
          variant: 'success',
        });
        if (onRefetch) {
          onRefetch();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
      handleClose();
    }
  }, [
    deletePartnerQuota,
    enqueueSnackbar,
    handleClose,
    partnerId,
    quota,
    onRefetch,
  ]);

  const handleOpenRemoveDialog = useCallback(() => {
    openDialog({
      acceptButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      description:
        'Es una acción que no se puede deshacer, ¿quieres eliminar la cuota?',
      onAccept: handleOnRemove,
      title: 'Eliminar cuota',
      variant: 'danger',
      iconName: 'quota',
    });
  }, [openDialog, handleOnRemove]);

  return {
    handleClose,
    initialValues,
    handleOpenRemoveDialog,
    isOpen: type === ModalType.QUOTA_DETAILS,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
