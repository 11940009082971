import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS, TABLE_MOBILE_METRICS } from '../constants';

export const Container = styled.div`
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey700}1A;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
  padding: 1rem 0;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;
  font-size: 0.8rem;

  ${from.tabletPortrait`
    font-size: 1rem;
  `};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_MOBILE_METRICS.name};
  ${TextEllipsis};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.name};
  `};
`;

export const Quantity = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.quantity};
  ${TextEllipsis};
`;

export const Purchases = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_MOBILE_METRICS.purchases};
  ${TextEllipsis};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.purchases};
  `};
`;

export const Discounts = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_MOBILE_METRICS.discounts};
  ${TextEllipsis};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.discounts};
  `};
`;

export const Costs = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_MOBILE_METRICS.costs};
  ${TextEllipsis};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.costs};
  `};
`;

export const Benefit = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.medium};
  flex: 0 0 ${TABLE_MOBILE_METRICS.benefit};
  ${TextEllipsis};

  ${from.tabletPortrait`
    flex: 0 0 ${TABLE_METRICS.benefit};
  `};
`;

export const RemainingQuantity = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.regular};
  flex: 0 0 ${TABLE_METRICS.remainingQuantity};
  ${TextEllipsis};
`;

export const ReferenceCode = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 0.5rem;
`;
