import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { startOfDay, endOfDay } from 'date-fns';
import { useReactiveVar } from '@apollo/client';
import {
  organizationConfigVar,
  permissionsStateVar,
  userRoleVar,
} from 'apollo/reactive';
import useCashierByPeriod from 'apollo/hooks/cashier/useCashierByPeriod';
import useCashierActions from 'apollo/hooks/cashier/useCashierActions';
import useLastCashier from 'apollo/hooks/cashier/useLastCashier';
import useModal from 'apollo/hooks/useModal';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import {
  getCashierProductData,
  getCashierProductFilename,
  cashierProductHeaders,
  cashierPartnerHeaders,
  getCashierPartnerFilename,
  getCashierPartnerData,
  cashierQuotaHeaders,
  getCashierQuotaFilename,
  getCashierQuotaData,
  cashierCategoriesHeaders,
  getCashierCategoryFilename,
  getCashierCategoryData,
} from 'utils/csv/cashier';
import { formatErrors } from 'utils/errors/formatErrors';

const useConnect = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const userRole = useReactiveVar(userRoleVar);
  const { cashier, loading, refetch } = useCashierByPeriod();
  const { lastCashier, getLastCashierLoading, lastCashierRefetch } =
    useLastCashier();
  const { openCashier, cashierActionsLoading } = useCashierActions();
  const { enqueueSnackbar } = useSnackbar();
  const { openCloseCashierDialog } = useModal();
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  });

  const csvProductsInfo = useMemo(() => {
    const data = getCashierProductData({
      products: cashier?.products,
      showProductReference: organizationConfig.activateProductReference,
    });
    const filename = getCashierProductFilename(filterDates);

    return { headers: cashierProductHeaders, data, filename };
  }, [organizationConfig, cashier, filterDates]);

  const csvPartnersInfo = useMemo(() => {
    const data = getCashierPartnerData(cashier?.partners || []);
    const filename = getCashierPartnerFilename(filterDates);

    return { headers: cashierPartnerHeaders, data, filename };
  }, [cashier, filterDates]);

  const csvQuotasInfo = useMemo(() => {
    const data = getCashierQuotaData(cashier?.quotas || []);
    const filename = getCashierQuotaFilename(filterDates);

    return { headers: cashierQuotaHeaders, data, filename };
  }, [cashier, filterDates]);

  const csvCategoriesInfo = useMemo(() => {
    const data = getCashierCategoryData(cashier?.categories || []);
    const filename = getCashierCategoryFilename(filterDates);

    return { headers: cashierCategoriesHeaders, data, filename };
  }, [cashier, filterDates]);

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      setFilterDate({ from, to });
      await refetch({
        filters: {
          fromDate: from || null,
          toDate: to || null,
        },
      });
    },
    [refetch],
  );

  const handleOpenCashier = useCallback(async () => {
    try {
      await openCashier();
      await lastCashierRefetch();
      enqueueSnackbar('Se ha activado la caja correctamente', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(formatErrors('cashier', e.message, 'actualizar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [openCashier, enqueueSnackbar, lastCashierRefetch]);

  const handleCloseCashier = useCallback(async () => {
    if (lastCashier) {
      openCloseCashierDialog({
        cashier: lastCashier,
        onSubmit: async () => {
          await refetch({
            filters: {
              fromDate: filterDates.from || null,
              toDate: filterDates.to || null,
            },
          });
        },
      });
    }
  }, [lastCashier, openCloseCashierDialog, refetch, filterDates]);

  return {
    cashier,
    csvPartnersInfo,
    csvProductsInfo,
    csvQuotasInfo,
    csvCategoriesInfo,
    filterDates,
    handleCloseCashier,
    handleOnSearch,
    handleOpenCashier,
    isLoading: loading,
    cashierLoading: cashierActionsLoading || getLastCashierLoading,
    lastCashier,
    employeePermissions,
    userRole,
  };
};

export default useConnect;
