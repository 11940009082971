import { gql } from '@apollo/client';

export const PERMISSIONS_GROUP_FRAGMENT = gql`
  fragment PermissionsGroupData on PermissionsGroup {
    id
    name

    # Products
    allowCreateProducts
    allowEditProducts
    allowAdjustProducts
    allowShowAdjustProducts
    allowRemoveProducts

    # Partners
    allowCreatePartners
    allowEditPartners
    allowRemovePartners
    allowPartnerRequests

    # Expenses
    allowShowExpenses
    allowCreateExpenses
    allowEditExpenses
    allowRemoveExpenses

    # Reports
    allowShowReports

    # Metrics
    allowShowMetrics

    # Cashier
    allowShowCashier
    allowShowCashierOneDay
    allowDownloadCashierData
    allowShowProductsCashier
    allowShowPartnersCashier

    # Calendar
    allowShowCalendars
    allowCreateCalendars
    allowEditCalendars
    allowRemoveCalendars

    # Files
    allowShowFiles
    allowCreateFiles
    allowRemoveFiles

    # Providers
    allowShowProviders
    allowCreateProviders
    allowEditProviders
    allowRemoveProviders

    # Discounts
    allowShowDiscounts
    allowCreateDiscounts
    allowAssignDiscounts
    allowEditDiscounts
    allowRemoveDiscounts

    # Quotas
    allowShowQuotas
    allowCreateQuotas
    allowAssignQuotas
    allowEditQuotas
    allowRemoveQuotas

    # Credits concepts
    allowShowCreditsConcept
    allowCreateCreditsConcept
    allowEditCreditsConcept
    allowRemoveCreditsConcept

    # Categories
    allowCreateCategories
    allowEditCategories
    allowRemoveCategories

    # Contract
    allowShowContract
    allowEditContract
    allowAssignContract

    # Organization
    allowShowTransactionsHistory
  }
`;
