import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultSearch from 'components/Inputs/Search';
import DefaultPagination from 'components/Pagination';
import DefaultListLoader from 'components/ListLoader';
import Button from 'components/Button';
import { Props } from './types';

export const Container = styled.div`
  position: relative;
  padding: 0 1.4rem;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const Search = styled(DefaultSearch)<{ $mode: Props['mode'] }>`
  flex: 1 0 75%;

  ${from.tabletPortrait`
     flex: 1 1 auto;
  `}
`;

export const Results = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  flex: 1 1 auto;
  margin: 0.5rem 0 0 0;
  white-space: nowrap;

  ${from.tabletPortrait`
    flex: 0 1 auto;
    margin: 0 0 0 3rem;
  `}
`;

export const Pagination = styled(DefaultPagination)`
  margin: 0.5rem 0 0 0;

  ${from.tabletPortrait`
    margin: 0 0 0 1.6rem;
  `}
`;

export const ListLoader = styled(DefaultListLoader)`
  bottom: 0.2rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
`;

export const ForceApplyButton = styled(Button).attrs(() => ({
  variant: 'light_black',
}))`
  &&& {
    margin-left: 0.5rem;
    padding: 0.3rem;
  }
`;
