import styled from 'styled-components';
import { IconCircle as DefaultIconCircle } from '../Icon/Icons';
import UsersIcon from '../Icon/Icons/Users';

export const Image = styled.img<{ $size: number }>`
  border-radius: 50%;
  display: block;
  height: ${({ $size }) => `${$size}rem`};
  width: ${({ $size }) => `${$size}rem`};
`;

export const Initials = styled.div<{ $size: number }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orangePale};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.orange};
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-content: center;
  height: ${({ $size }) => `${$size}rem`};
  width: ${({ $size }) => `${$size}rem`};
`;

export const IconCircle = styled(DefaultIconCircle)<{
  $size: number;
  $isSelected?: boolean;
}>`
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? `${theme.colors.accent}0A` : theme.colors.white};
  border: 1px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? `${theme.colors.accent}0A` : theme.colors.grey500};
  height: ${({ $size }) => `${$size}rem`};
  width: ${({ $size }) => `${$size}rem`};
`;

export const Icon = styled(UsersIcon)<{
  $isSelected?: boolean;
}>`
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.accent : theme.colors.grey500};
`;
