import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type {
  CreatePartner,
  CreatePartnerVariables,
} from 'apollo/generated/CreatePartner';
import {
  ADD_CREDITS_TO_PARTNER,
  RETIRE_CREDITS_FROM_PARTNER,
  ASSIGN_DISCOUNTS_TO_PARTNER,
  ASSIGN_QUOTA_TO_PARTNER,
  CANCEL_CREDITS_TRANSACTION,
  CANCEL_QUOTA_TRANSACTION,
  CREATE_PARTNER,
  REMOVE_PARTNER,
  REGISTER_PARTNER_PRESENCE,
  UPDATE_PARTNER,
  ACTIVATE_REMOVED_PARTNER,
  REMOVE_PARTNER_QUOTA,
} from 'apollo/mutations/partners';
import {
  RemovePartner,
  RemovePartnerVariables,
} from 'apollo/generated/RemovePartner';
import {
  UpdatePartner,
  UpdatePartnerVariables,
} from 'apollo/generated/UpdatePartner';
import { PartnerFormFields, normalizePartner } from 'model/Partner';
import {
  AssignDiscountsToPartner,
  AssignDiscountsToPartnerVariables,
} from 'apollo/generated/AssignDiscountsToPartner';
import type { Discount } from 'model/Discount';
import type { Quota } from 'model/Quota';
import {
  RegisterPartnerPresence,
  RegisterPartnerPresenceVariables,
} from 'apollo/generated/RegisterPartnerPresence';
import type {
  CancelCreditsTransaction,
  CancelCreditsTransactionVariables,
} from 'apollo/generated/CancelCreditsTransaction';
import type {
  CancelQuotaTransaction,
  CancelQuotaTransactionVariables,
} from 'apollo/generated/CancelQuotaTransaction';
import type {
  ActivateRemovedPartner,
  ActivateRemovedPartnerVariables,
} from 'apollo/generated/ActivateRemovedPartner';
import type {
  AddCreditsToPartnerMutation,
  AddCreditsToPartnerMutationVariables,
  RetireCreditsFromPartnerMutation,
  RetireCreditsFromPartnerMutationVariables,
  AssignQuotaToPartnerMutation,
  AssignQuotaToPartnerMutationVariables,
  RemovePartnerQuotaMutation,
  RemovePartnerQuotaMutationVariables,
} from 'apollo/graphql.types';

const usePartnerActions = () => {
  const [createPartnerMutation, { loading: isLoadingCreatePartner }] =
    useMutation<CreatePartner, CreatePartnerVariables>(CREATE_PARTNER);
  const [removePartnerMutation, { loading: isLoadingRemovePartner }] =
    useMutation<RemovePartner, RemovePartnerVariables>(REMOVE_PARTNER);
  const [updatePartnerMutation, { loading: isLoadingUpdatePartner }] =
    useMutation<UpdatePartner, UpdatePartnerVariables>(UPDATE_PARTNER);
  const [addPartnerCreditsMutation, { loading: isLoadingAddPartnerCredits }] =
    useMutation<
      AddCreditsToPartnerMutation,
      AddCreditsToPartnerMutationVariables
    >(ADD_CREDITS_TO_PARTNER);
  const [
    retirePartnerCreditsMutation,
    { loading: isLoadingRetirePartnerCredits },
  ] = useMutation<
    RetireCreditsFromPartnerMutation,
    RetireCreditsFromPartnerMutationVariables
  >(RETIRE_CREDITS_FROM_PARTNER);
  const [
    cancelPartnerCreditsTransactionMutation,
    { loading: isLoadingCancelPartnerCreditsTransaction },
  ] = useMutation<CancelCreditsTransaction, CancelCreditsTransactionVariables>(
    CANCEL_CREDITS_TRANSACTION,
  );
  const [
    cancelPartnerQuotaTransactionMutation,
    { loading: isLoadingCancelPartnerQuotaTransaction },
  ] = useMutation<CancelQuotaTransaction, CancelQuotaTransactionVariables>(
    CANCEL_QUOTA_TRANSACTION,
  );
  const [
    assignPartnerDiscountsMutation,
    { loading: isLoadingAssignPartnerDiscounts },
  ] = useMutation<AssignDiscountsToPartner, AssignDiscountsToPartnerVariables>(
    ASSIGN_DISCOUNTS_TO_PARTNER,
  );
  const [assignPartnerQuotaMutation, { loading: isLoadingAssignPartnerQuota }] =
    useMutation<
      AssignQuotaToPartnerMutation,
      AssignQuotaToPartnerMutationVariables
    >(ASSIGN_QUOTA_TO_PARTNER);
  const [
    registerPartnerPresenceMutation,
    { loading: isLoadingRegisterPartnerPresence },
  ] = useMutation<RegisterPartnerPresence, RegisterPartnerPresenceVariables>(
    REGISTER_PARTNER_PRESENCE,
  );
  const [
    activateRemovedPartnerMutation,
    { loading: isLoadingActivateRemovedPartner },
  ] = useMutation<ActivateRemovedPartner, ActivateRemovedPartnerVariables>(
    ACTIVATE_REMOVED_PARTNER,
  );
  const [removePartnerQuotaMutation, { loading: isLoadingRemovePartnerQuota }] =
    useMutation<
      RemovePartnerQuotaMutation,
      RemovePartnerQuotaMutationVariables
    >(REMOVE_PARTNER_QUOTA);

  const normalizeFormValuesToCreate = useCallback(
    async (values: PartnerFormFields) => ({
      address: values.address,
      birthDate: values.birthDate,
      document: values.document?.toLowerCase()?.trim(),
      email: values.email?.toLowerCase()?.trim(),
      firstName: values.firstName?.toLowerCase()?.trim(),
      gender: values.gender,
      hostMemberNum: values.hostMemberNum || '',
      lastName: values.lastName?.toLowerCase()?.trim(),
      maxConsumeMonth: Number(values.maxConsumeMonth),
      memberNum: values.memberNum || '',
      phoneNumber: values.phoneNumber,
      usage: values.usage,
      rfidCode: values.rfidCode || '',
    }),
    [],
  );

  const normalizeFormValuesToUpdate = useCallback(
    async (values: Partial<PartnerFormFields>) => ({
      address: values.address,
      birthDate: values.birthDate,
      document: values.document?.toLowerCase()?.trim(),
      email: values.email?.toLowerCase()?.trim(),
      firstName: values.firstName?.toLowerCase()?.trim(),
      gender: values.gender,
      lastName: values.lastName?.toLowerCase()?.trim(),
      maxConsumeMonth: Number(values.maxConsumeMonth),
      phoneNumber: values.phoneNumber,
      usage: values.usage,
      rfidCode: values.rfidCode || '',
    }),
    [],
  );

  const createPartner = useCallback(
    async (values: PartnerFormFields) => {
      const dto = await normalizeFormValuesToCreate(values);
      const { data } = await createPartnerMutation({
        variables: {
          data: dto,
        },
      });

      if (data?.createPartner) {
        return normalizePartner(data.createPartner);
      }
    },
    [createPartnerMutation, normalizeFormValuesToCreate],
  );

  const updatePartner = useCallback(
    async ({ id, data }: { id: string; data: Partial<PartnerFormFields> }) => {
      const dto = await normalizeFormValuesToUpdate(data);
      await updatePartnerMutation({
        variables: {
          id,
          data: dto,
        },
      });
    },
    [normalizeFormValuesToUpdate, updatePartnerMutation],
  );

  const updatePartnerAvatar = useCallback(
    async ({ id, imageId }: { id: string; imageId: string }) => {
      await updatePartnerMutation({
        variables: {
          id,
          data: {
            avatarId: imageId,
          },
        },
      });
    },
    [updatePartnerMutation],
  );

  const assignPartnerQuota = useCallback(
    async ({
      id,
      quotaId,
      discountId,
    }: {
      id: string;
      quotaId: Quota['id'];
      discountId?: Discount['id'];
    }) => {
      const { data } = await assignPartnerQuotaMutation({
        variables: {
          id,
          data: {
            quotaId,
            discountId,
          },
        },
      });

      return data?.assignQuotaToPartner || '';
    },
    [assignPartnerQuotaMutation],
  );

  const updatePartnerCredits = useCallback(
    async ({
      id,
      credits,
      concept,
      action,
    }: {
      id: string;
      credits: number;
      concept?: string;
      action: 'added' | 'retire';
    }) => {
      const variables = {
        id,
        data: {
          value: Number(credits),
          concept,
        },
      };
      if (action === 'retire') {
        const { data } = await retirePartnerCreditsMutation({
          variables,
        });

        return data?.retireCreditsFromPartner || '';
      }

      if (action === 'added') {
        const { data } = await addPartnerCreditsMutation({ variables });

        return data?.addCreditsToPartner || '';
      }
    },
    [addPartnerCreditsMutation, retirePartnerCreditsMutation],
  );

  const cancelCreditsTransaction = useCallback(
    async (creditTransactionId: string) => {
      await cancelPartnerCreditsTransactionMutation({
        variables: {
          creditTransactionId,
        },
      });
    },
    [cancelPartnerCreditsTransactionMutation],
  );

  const cancelQuotaTransaction = useCallback(
    async (quotaTransactionId: string) => {
      await cancelPartnerQuotaTransactionMutation({
        variables: {
          quotaTransactionId,
        },
      });
    },
    [cancelPartnerQuotaTransactionMutation],
  );

  const assignPartnerDiscounts = useCallback(
    async ({ id, discountsIds }: { id: string; discountsIds: string[] }) => {
      await assignPartnerDiscountsMutation({
        variables: {
          id,
          discountsIds,
        },
      });
    },
    [assignPartnerDiscountsMutation],
  );

  const deletePartner = useCallback(
    async (id: string) => {
      await removePartnerMutation({
        variables: {
          id,
        },
      });
    },
    [removePartnerMutation],
  );

  const deletePartnerQuota = useCallback(
    async ({
      partnerQuotaId,
      partnerId,
    }: {
      partnerQuotaId: string;
      partnerId: string;
    }) => {
      await removePartnerQuotaMutation({
        variables: {
          partnerQuotaId,
          partnerId,
        },
      });
    },
    [removePartnerQuotaMutation],
  );

  const registerPartnerPresence = useCallback(
    async (id: string) => {
      await registerPartnerPresenceMutation({
        variables: {
          id,
        },
      });
    },
    [registerPartnerPresenceMutation],
  );

  const activateRemovedPartner = useCallback(
    async (document: string) => {
      const { data } = await activateRemovedPartnerMutation({
        variables: {
          document,
        },
      });

      if (data?.activateRemovedPartner) {
        return normalizePartner(data.activateRemovedPartner);
      }
    },
    [activateRemovedPartnerMutation],
  );

  return {
    activateRemovedPartner,
    assignPartnerDiscounts,
    assignPartnerQuota,
    cancelCreditsTransaction,
    cancelQuotaTransaction,
    createPartner,
    deletePartner,
    deletePartnerQuota,
    registerPartnerPresence,
    updatePartner,
    updatePartnerAvatar,
    updatePartnerCredits,
    loading:
      isLoadingCreatePartner ||
      isLoadingRemovePartner ||
      isLoadingUpdatePartner ||
      isLoadingAddPartnerCredits ||
      isLoadingRetirePartnerCredits ||
      isLoadingAssignPartnerDiscounts ||
      isLoadingAssignPartnerQuota ||
      isLoadingRegisterPartnerPresence ||
      isLoadingCancelPartnerCreditsTransaction ||
      isLoadingCancelPartnerQuotaTransaction ||
      isLoadingActivateRemovedPartner ||
      isLoadingRemovePartnerQuota,
  };
};

export default usePartnerActions;
export type usePartnerActionsType = ReturnType<typeof usePartnerActions>;
