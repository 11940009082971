import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import useModal from 'apollo/hooks/useModal';
import {
  orderVar,
  subscriptionStateVar,
  organizationConfigVar,
} from 'apollo/reactive';
import usePurchaseActions from 'apollo/hooks/usePurchaseActions';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import { useSelectedProducts } from 'containers/Main/Products/logic';
import { ModalType } from 'apollo/reactive/modal';
import { DiscountType } from 'apollo/graphql.types';
import RevertSnackbarButton from 'components/RevertSnackbarButton';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import OpenCashierSnackbarButton from 'components/OpenCashierSnackbarButton';
import { formatErrors, isClosedCashierError } from 'utils/errors/formatErrors';
import { applyDiscount } from 'utils/discounts';
import type { Discount } from 'model/Discount';
import type { Partner } from 'model/Partner';
import type { Product, ProductOrderItem } from 'model/Product';
import type { EditOrderFields } from 'model/Order';

const useConnect = () => {
  const {
    close,
    openNewProduct,
    openPartners,
    openProducts,
    openNewOrder,
    openAssignDiscounts,
    newOrderPayload,
    type,
  } = useModal();
  const {
    products: savedOrderedProducts,
    partner: savedPartner,
    orderDiscount: savedDiscount,
  } = useReactiveVar(orderVar);
  const subscription = useReactiveVar(subscriptionStateVar);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const {
    createPurchase,
    cancelPurchase,
    loading: purchaseActionsLoading,
  } = usePurchaseActions();
  const { updatePartnerCredits, loading: addCreditsLoading } =
    usePartnerActions();
  const { clearSelectedProducts } = useSelectedProducts();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [partner, setPartner] = useState<Partner | null>(null);
  const [partnerCredits, setPartnerCredits] = useState(0);
  const [localProducts, setLocalProducts] = useState<ProductOrderItem[]>([]);
  const [showPartnerError, setShowPartnerError] = useState(false);
  const formikRef = useRef<FormikProps<EditOrderFields>>(null);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [orderDiscount, setOrderDiscount] = useState<Partial<Discount> | null>(
    null,
  );

  const { onSubmitPurchase } = newOrderPayload || {};

  useEffect(() => {
    if (savedPartner) {
      setPartner(savedPartner);
      setPartnerCredits(savedPartner.credits);
    }
  }, [savedPartner]);

  useEffect(() => {
    if (savedDiscount) {
      setOrderDiscount(savedDiscount);
    }
  }, [savedDiscount]);

  useEffect(() => {
    if (savedOrderedProducts) {
      setLocalProducts(savedOrderedProducts);
    }
  }, [savedOrderedProducts]);

  const initialFormValues = useMemo<EditOrderFields>(() => {
    const initialValues: EditOrderFields = { products: [] };
    localProducts.forEach((p) => {
      initialValues.products.push({
        amount: p.amount,
        extraAmount: p.extraAmount ? Number(p.extraAmount) : 0,
        price: p.price,

        // product data to display
        id: p.id,
        name: p.name,
        originalPrice: p.originalPrice,
        productPrice: p.productPrice,
        quantity: p.productQuantity,
        costs: p.originalPrice,
        discount: p.discount,
        referenceCode: p.referenceCode,
        countToMaxConsume: p.countToMaxConsume,
      });
    });

    return initialValues;
  }, [localProducts]);

  const totalPrice = useMemo(
    () => localProducts.reduce((a, b) => a + b.price, 0),
    [localProducts],
  );

  const onSelectPartner = useCallback((partner: Partner) => {
    if (partner?.id) {
      setPartner(partner);
      setPartnerCredits(partner.credits);
    }
  }, []);

  const onSelectProduct = useCallback(
    (product: Product) => {
      if (product) {
        const {
          id,
          name,
          price,
          referenceCode,
          discount: productDiscount,
          quantity,
          countToMaxConsume,
        } = product;
        const existed = savedOrderedProducts.find((p) => p.id === id);
        const newOrderedProducts = savedOrderedProducts.map((p) => {
          if (p.id === id) {
            return {
              ...p,
              amount: p.amount + 1,
            };
          }
          return p;
        });

        if (!existed) {
          const finalPrice = productDiscount
            ? applyDiscount({ discount: productDiscount, price })
            : price;

          newOrderedProducts.push({
            price: finalPrice,
            amount: 1,
            extraAmount: 0,

            // product data to display
            id,
            name,
            referenceCode,
            countToMaxConsume,
            productPrice: finalPrice,
            originalPrice: price,
            discount: productDiscount,
            productQuantity: quantity,
          });
        }

        orderVar({
          products: newOrderedProducts,
          partner,
          orderDiscount,
        });
      }
    },
    [orderDiscount, partner, savedOrderedProducts],
  );

  const handleClose = useCallback(() => {
    setPartner(null);
    setPartnerCredits(0);
    setOrderDiscount(null);
    setLocalProducts([]);
    clearSelectedProducts();
    orderVar({ products: [], partner: null, orderDiscount: null });
    close();
  }, [clearSelectedProducts, close]);

  const handleSelectPartner = useCallback(() => {
    openPartners({
      onSelect: onSelectPartner,
      onReturn: () => openNewOrder({ onSubmitPurchase }),
    });
  }, [openPartners, onSelectPartner, openNewOrder, onSubmitPurchase]);

  const handleChangeProductExtraAmount = useCallback(
    ({
      e,
      productIndex,
      setFieldValue,
    }: {
      e: ChangeEvent<HTMLInputElement>;
      productIndex: number;
      setFieldValue: FormikHelpers<EditOrderFields>['setFieldValue'];
    }) => {
      const currentProduct = localProducts[productIndex];
      const decimalsMaxExtra = organizationConfig.maxExtraQuantity
        ? Number(organizationConfig.maxExtraQuantity)
        : '';
      let extraAmount = Number(e.target.value);

      if (decimalsMaxExtra) {
        const maxExtra = currentProduct.amount + decimalsMaxExtra;
        if (extraAmount > maxExtra) {
          extraAmount = maxExtra;
          setFieldValue(`products[${productIndex}].extraAmount`, maxExtra);
        } else if (extraAmount < 0) {
          extraAmount = 0;
          setFieldValue(`products[${productIndex}].extraAmount`, 0);
        }
      }

      const updatedProduct = {
        ...currentProduct,
        extraAmount,
      };
      const newProductsList = [
        ...localProducts.slice(0, productIndex),
        updatedProduct,
        ...localProducts.slice(productIndex + 1, localProducts.length),
      ];
      orderVar({ products: newProductsList, partner, orderDiscount });
    },
    [orderDiscount, localProducts, organizationConfig, partner],
  );

  const handleChangeProductInput = useCallback(
    ({
      e,
      staticValue,
      productIndex,
      actionType,
    }: {
      e?: ChangeEvent<HTMLInputElement>;
      staticValue?: number;
      productIndex: number;
      actionType: 'PRICE' | 'AMOUNT';
    }) => {
      const value = (e ? Number(e.target.value) : staticValue) || 0;

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        const currentProduct = localProducts[productIndex];
        let amount =
          actionType === 'AMOUNT' ? value : value / currentProduct.productPrice;
        let price =
          actionType === 'AMOUNT' ? value * currentProduct.productPrice : value;

        if (amount > currentProduct.productQuantity) {
          amount = currentProduct.productQuantity;
          price = amount * currentProduct.productPrice;
        }

        const updatedProduct = {
          ...currentProduct,
          amount,
          price: Number(price.toFixed(2)),
        };
        const newProductsList = [
          ...localProducts.slice(0, productIndex),
          updatedProduct,
          ...localProducts.slice(productIndex + 1, localProducts.length),
        ];
        orderVar({ products: newProductsList, partner, orderDiscount });
      }, 200);
    },
    [orderDiscount, localProducts, partner],
  );

  const onDisableSumButton = useCallback(
    (productIndex: number) => {
      const currentProduct = localProducts[productIndex];

      return (
        (currentProduct?.amount || 0) >= (currentProduct?.productQuantity || 0)
      );
    },
    [localProducts],
  );

  const handleChangeAmount = useCallback(
    (productIndex: number, action: 'sum' | 'substract') => {
      const currentProduct = localProducts[productIndex];

      if (action === 'substract' && currentProduct.amount === 0) {
        return;
      }
      if (
        action === 'sum' &&
        currentProduct.amount + 1 > currentProduct.productQuantity
      ) {
        return;
      }

      const value =
        action === 'sum'
          ? currentProduct.amount + 1
          : currentProduct.amount - 1;

      handleChangeProductInput({
        productIndex,
        staticValue: value,
        actionType: 'AMOUNT',
      });
    },
    [handleChangeProductInput, localProducts],
  );

  const handleRevertTransaction = useCallback(
    async (purchaseId: string) => {
      try {
        await cancelPurchase(purchaseId);
        closeSnackbar();
        enqueueSnackbar('Se ha cancelado la compra', {
          variant: 'info',
        });
      } catch (e) {
        enqueueSnackbar(formatErrors('transaction', e.message, 'cancelar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [cancelPurchase, closeSnackbar, enqueueSnackbar],
  );

  const handlePurchase = useCallback(
    async (values: EditOrderFields) => {
      try {
        if (!partner?.id) {
          setShowPartnerError(true);
          return;
        }

        const orders = values.products.map((p) => ({
          quantity: p.amount,
          extraQuantity: p.extraAmount,
          productId: p.id,
          productPrice: p.productPrice,
          productWithoutDiscountPrice: p.originalPrice,
          discount: p?.discount,
        }));

        if (orders?.length > 0) {
          const purchase = await createPurchase({
            orders,
            partnerId: partner.id,
            discountId: orderDiscount?.id,
          });

          if (!purchase) {
            throw new Error();
          }

          enqueueSnackbar(
            `La compra del socio #${partner.memberNum} se ha realizado correctamente`,
            {
              variant: 'success',
              action: () => (
                <RevertSnackbarButton
                  onClickBar={() => handleRevertTransaction(purchase.id)}
                />
              ),
            },
          );

          if (onSubmitPurchase) {
            onSubmitPurchase();
          }
          handleClose();
        } else {
          throw new Error();
        }
      } catch (e) {
        if (isClosedCashierError(e.message)) {
          enqueueSnackbar('Debes abrir la caja', {
            variant: 'error',
            action: () => <OpenCashierSnackbarButton />,
          });
        } else {
          enqueueSnackbar(formatErrors('purchase', e.message, 'crear'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
    },
    [
      partner,
      createPurchase,
      orderDiscount,
      enqueueSnackbar,
      onSubmitPurchase,
      handleClose,
      handleRevertTransaction,
    ],
  );

  const handleOnSubmit = useCallback(async () => {
    if (formikRef?.current) {
      await formikRef.current.submitForm();
    }
  }, []);

  const handleAddProduct = useCallback(() => {
    openProducts({
      onSelect: onSelectProduct,
      filters: { positiveQuantity: true },
      onSubmitPurchase,
    });
  }, [openProducts, onSelectProduct, onSubmitPurchase]);

  const handleRemoveProduct = useCallback(
    (id: string) => {
      const newList = savedOrderedProducts.filter((p) => p.id !== id);
      orderVar({
        products: newList,
        partner,
        orderDiscount,
      });
    },
    [orderDiscount, partner, savedOrderedProducts],
  );

  const handleSetDiscount = useCallback(async (discounts: Array<Discount>) => {
    setOrderDiscount(discounts[0]);
  }, []);

  const handleAddDiscount = useCallback(() => {
    const checkedDiscountsIds = [];
    if (orderDiscount?.id) {
      checkedDiscountsIds.push(orderDiscount.id);
    }

    openAssignDiscounts({
      oneSelection: true,
      checkedDiscountsIds,
      onSubmit: handleSetDiscount,
      type: DiscountType.Product,
      onReturn: openNewOrder,
    });
  }, [orderDiscount, handleSetDiscount, openAssignDiscounts, openNewOrder]);

  const handleSetProductDiscount = useCallback(
    async (productDiscount: Discount, productIndex: number) => {
      const currentProduct = localProducts[productIndex];
      const finalPrice = productDiscount
        ? applyDiscount({
            discount: productDiscount,
            price: currentProduct.originalPrice,
          })
        : currentProduct.originalPrice;

      const updatedProduct = {
        ...currentProduct,
        price: finalPrice * currentProduct.amount,
        productPrice: finalPrice,
        discount: productDiscount || null,
      };
      const newProductsList = [
        ...localProducts.slice(0, productIndex),
        updatedProduct,
        ...localProducts.slice(productIndex + 1, localProducts.length),
      ];
      orderVar({ products: newProductsList, partner, orderDiscount });
    },
    [orderDiscount, localProducts, partner],
  );

  const handleAddProductDiscount = useCallback(
    (productIndex: number) => {
      const currentProduct = localProducts[productIndex];
      const checkedDiscountsIds = [];
      if (currentProduct?.discount?.id) {
        checkedDiscountsIds.push(currentProduct.discount.id);
      }

      openAssignDiscounts({
        oneSelection: true,
        checkedDiscountsIds,
        onSubmit: (discounts) =>
          handleSetProductDiscount(discounts[0], productIndex),
        type: DiscountType.UnitProduct,
        onReturn: openNewOrder,
      });
    },
    [
      handleSetProductDiscount,
      localProducts,
      openAssignDiscounts,
      openNewOrder,
    ],
  );

  const handleAddCreditsSubmit = useCallback(
    async ({ credits }: { credits: number }) => {
      try {
        if (partner) {
          await updatePartnerCredits({
            id: partner.id,
            credits,
            action: 'added',
          });
          setPartnerCredits((prevCredits) => prevCredits + Number(credits));

          enqueueSnackbar(
            `Los créditos se han añadido correctamente al socio ${partner.memberNum}`,
            {
              variant: 'success',
            },
          );
        }
      } catch (e) {
        if (isClosedCashierError(e.message)) {
          enqueueSnackbar('Debes abrir la caja', {
            variant: 'error',
            action: () => <OpenCashierSnackbarButton />,
          });
        } else {
          enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
    },
    [enqueueSnackbar, partner, updatePartnerCredits],
  );

  return {
    discount: orderDiscount,
    formikRef,
    handles: {
      handleAddProduct,
      handleAddDiscount,
      handleChangeAmount,
      handleChangeProductInput,
      handleClose,
      handleOnSubmit,
      handlePurchase,
      handleSelectPartner,
      handleAddCreditsSubmit,
      handleChangeProductExtraAmount,
      handleAddProductDiscount,
      handleRemoveProduct,
      handleOpenNewProduct: openNewProduct,
    },
    initialFormValues,
    isLoading: purchaseActionsLoading,
    isOpen: type === ModalType.NEW_ORDER,
    onDisableSumButton,
    partner,
    products: localProducts,
    showPartnerError,
    totalPrice,
    subscription,
    addCreditsLoading,
    partnerCredits,
    organizationConfig,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
