import { FC, memo } from 'react';
import { Formik } from 'formik';
import { subYears } from 'date-fns';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import { UserGender } from 'apollo/generated/globalTypes';
import Select from 'components/Inputs/Select';
import DatePicker from 'components/Inputs/DatePicker';
import { validationSchema } from './constants';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  SelectHostPartner,
  SelectHostPartnerTitle,
  TextField,
  Icon,
} from './styles';
import type { Props } from './types';

const NewPartnerModalForm: FC<Props> = ({
  className,
  onSubmit,
  onCancel,
  initialValues,
  id = 'new',
  onSelectHostPartner,
  formikRef,
  selectedPartner,
  isLoading,
}) => {
  const maxBirthDate = subYears(new Date(), 18);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const formId = `member_${id}`;
  const hasHostPartner = !!(
    selectedPartner?.memberNum && selectedPartner?.fullName
  );

  window.addEventListener('keypress', (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formikRef}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <Row>
            <TextField
              name="firstName"
              label="Nombre *"
              id={`${formId}_firstName`}
            />
            <TextField
              name="lastName"
              label="Apellidos *"
              id={`${formId}_lastName`}
            />
          </Row>
          <Row>
            <TextField
              name="document"
              label="DNI *"
              id={`${formId}_document`}
            />
            <TextField
              name="address"
              label="Domicilio *"
              id={`${formId}_address`}
            />
          </Row>
          <Row>
            <TextField
              id={`${formId}_email`}
              label="email *"
              name="email"
              type="email"
            />
            <TextField
              name="phoneNumber"
              label="Nº de móvil *"
              id={`${formId}_phoneNumber`}
            />
          </Row>
          <Row>
            <TextField
              type="number"
              name="maxConsumeMonth"
              label="Consumo máximo *"
              id={`${formId}_maxConsumeMonth`}
            />
            <TextField
              name="memberNum"
              label="Nº de socio"
              id={`${formId}_memberNum`}
            />
          </Row>
          <Row>
            <Select
              name="gender"
              label="Genero"
              id={`${formId}_gender`}
              options={[
                {
                  value: UserGender.MALE,
                  label: 'Hombre',
                },
                {
                  value: UserGender.FEMALE,
                  label: 'Mujer',
                },
                {
                  value: UserGender.OTHER,
                  label: 'No especificado',
                },
              ]}
            />
            <DatePicker
              label="Fecha de nacimiento *"
              name="birthDate"
              maxDate={maxBirthDate}
              id={`${formId}_birthDate`}
            />
          </Row>
          <Row>
            <Select
              name="usage"
              label="¿Lúdico o terapéutico?"
              id={`${formId}_usage`}
              options={[
                {
                  value: 'PLAYFUL',
                  label: 'Lúdico',
                },
                {
                  value: 'THERAPEUTIC',
                  label: 'Terapéutico',
                },
              ]}
            />
            {organizationConfig.activateRfidReader && (
              <TextField
                name="rfidCode"
                label="Código RFID"
                id={`${formId}_rfidCode`}
                icon="rfid"
                iconSize={22}
                onLocalChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              />
            )}
          </Row>

          <SelectHostPartner
            $hasHostPartner={hasHostPartner}
            onClick={onSelectHostPartner}
          >
            {!hasHostPartner ? (
              <SelectHostPartnerTitle>
                Seleccionar socio avalador
              </SelectHostPartnerTitle>
            ) : (
              <SelectHostPartnerTitle>
                Socio Avalador: {selectedPartner?.fullName} #
                {selectedPartner?.memberNum}
              </SelectHostPartnerTitle>
            )}
            <Icon name="arrowRight" />
          </SelectHostPartner>
          <Buttons>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
            <CreateButton
              type="submit"
              disabled={!hasHostPartner || !isFormValid}
              isLoading={isLoading}
            >
              Crear
            </CreateButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(NewPartnerModalForm);
