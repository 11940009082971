import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import useCashierPartners from 'apollo/hooks/cashier/useCashierPartners';
import {
  cashierPartnerHeaders,
  getCashierPartnerData,
  getCashierPartnerFilename,
} from 'utils/csv/cashier';
import type { CashierProduct } from 'model/Cashier';

const useConnect = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { openCashierProducts } = useModal();
  const [searchParams] = useSearchParams();
  const startFromPram = searchParams.get('start');
  const endFromParam = searchParams.get('end');
  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startFromPram
      ? new Date(Number(startFromPram))
      : startOfDay(subDays(new Date(), 1)),
    to: endFromParam ? new Date(Number(endFromParam)) : endOfDay(new Date()),
  });

  const { cashierPartners, loading, refetch } = useCashierPartners({
    filters: filterDates,
  });

  useEffect(() => {
    refetch({
      filters: {
        dates: {
          fromDate: filterDates.from || null,
          toDate: filterDates.to || null,
        },
      },
    });
  }, [filterDates, refetch]);

  const csvInfo = useMemo(() => {
    const data = getCashierPartnerData(cashierPartners || []);

    const filename = getCashierPartnerFilename({
      from: filterDates.from ? new Date(filterDates.from) : new Date(),
      to: filterDates.to ? new Date(filterDates.to) : new Date(),
    });

    return { headers: cashierPartnerHeaders, data, filename };
  }, [cashierPartners, filterDates]);

  const handleOnSearchDates = ({ from, to }: { from?: Date; to?: Date }) => {
    setFilterDate({ from, to });
  };

  const handleShowProducts = useCallback(
    (products: CashierProduct[], totalDiscounts: number) => {
      openCashierProducts({ products, totalDiscounts });
    },
    [openCashierProducts],
  );

  return {
    partners: cashierPartners,
    csvInfo,
    isLoading: loading,
    filterDates,
    employeePermissions,
    handleOnSearchDates,
    handleShowProducts,
  };
};

export default useConnect;
