import styled, { css } from 'styled-components';
import DefaultMuiTextField from '@mui/material/TextField';
import { motion } from 'framer-motion';
import DefaultIcon from 'components/Icon';

export const Container = styled.div``;

export const IconContainer = styled.div<{
  $hasValue: boolean;
  $disabled: boolean;
  $isClickable: boolean;
}>`
  bottom: 1.45rem;
  color: ${({ $disabled, theme }) =>
    $disabled ? `${theme.colors.primary}80` : theme.colors.primary};
  position: absolute;
  right: 0.625rem;
  transform: ${({ $hasValue }) =>
    $hasValue ? 'translate(0, 0.5rem)' : 'translate(0, 0)'};
  transition: transform 0.3s ease;

  ${({ $isClickable }) =>
    $isClickable &&
    css`
      cursor: pointer;
    `}
`;

export const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  outline: none;
  position: absolute;
  right: 0.625rem;
  top: 1rem;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.grey900};
  }
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.01rem;
  margin-top: 0.25rem;
  text-align: left;
`;

export const Icon = styled(DefaultIcon)<{
  $color?: string;
  $disabled?: boolean;
}>`
  ${({ $color, $disabled }) =>
    $color &&
    css`
      color: ${$disabled ? `${$color}80` : $color};
    `}
`;

export const InputContainer = styled.div`
  position: relative;

  :focus-within {
    ${IconContainer} {
      transform: translate(0, 0.5rem);
    }
  }
`;

export const Label = styled.label`
  font-size: 0.9rem;
  text-align: left;
`;

export const MuiTextField = styled(DefaultMuiTextField)<{
  $hasIcon: boolean;
  $isHidden: boolean;
  $readOnly?: boolean;
  $isClickable?: boolean;
}>`
  &&& {
    * {
      cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
      transition: all 150ms ease;
    }

    &.Mui-focused {
      border-color: ${({ theme }) => `${theme.colors.accent}`}!important;
    }
  }

  ${({ $isHidden }) =>
    !$isHidden &&
    css`
      .MuiFilledInput-underline:before {
        border-bottom: none !important;
      }
    `}

  .MuiFormLabel-root {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.weights.medium};
    text-transform: capitalize;

    &.MuiInputLabel-filled {
      font-size: 0.9rem !important;
      transform: unset;
    }

    &.MuiInputLabel-shrink {
      color: ${({ $readOnly, theme }) =>
        $readOnly ? theme.colors.primary : theme.colors.accent};
      font-size: 0.9rem !important;
      font-weight: ${({ theme }) => theme.weights.bold};
    }

    &.Mui-error {
      color: ${({ theme }) => theme.colors.danger}!important;
    }

    &.Mui-focused {
      color: ${({ theme }) => theme.colors.accent};
    }

    &.Mui-disabled {
      color: ${({ theme }) => `${theme.colors.black}90`};
    }
  }

  .MuiInputBase-root {
    background: none;

    &:hover,
    &.Mui-focused {
      background: none;
    }

    ${({ $readOnly, theme }) =>
      $readOnly &&
      css`
        .MuiInputBase-input {
          &.Mui-disabled {
            color: ${theme.colors.primary}!important;
          }
        }
      `}

    .MuiInputBase-input,
    .MuiInputFilledInput-input {
      font-size: 1rem;
      font-weight: ${({ theme }) => theme.weights.regular};
      line-height: 1.25;

      &.Mui-disabled {
        color: ${({ theme }) => `${theme.colors.primary}80`};
      }
    }

    &.MuiFilledInput-underline:before {
      border-bottom: none !important;
    }

    &.Mui-focused {
      &.MuiFilledInput-underline:before {
        border-bottom: none !important;
      }
    }
    &.Mui-error {
      &.MuiFilledInput-underline:before {
        border-bottom: none !important;
      }
    }

    &.MuiFilledInput-underline.Mui-error:after {
      display: none;
    }

    &.MuiFilledInput-underline.Mui-focused:after {
      display: none;
    }

    &.Mui-disabled {
      background: none;
    }
  }

  .MuiFormHelperText-root {
    display: none !important;
    background: blue !important;
  }
`;
