import { FC, memo } from 'react';
import { Formik } from 'formik';
import Switch from 'components/Inputs/Switch';
import { categoryTypeOptions } from 'model/Category';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  TextField,
  Select,
} from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const DiscountForm: FC<Props> = ({
  className,
  onSubmit,
  onCancel,
  initialValues,
  isLoading,
  id = 'new',
}) => {
  const formId = `category_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <TextField name="name" label="Nombre" id={`${formId}_name`} />
          <Select
            name="type"
            label="Tipo de categoría"
            id={`${formId}_type`}
            options={categoryTypeOptions}
          />
          <TextField
            name="description"
            label="Descrtipción"
            id={`${formId}_description`}
          />
          <Switch
            id={`${formId}_showInCashier`}
            label="Mostrar en caja"
            name="showInCashier"
          />
          <Buttons>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={!isFormValid}
            >
              {initialValues.name ? 'Editar' : 'Crear'}
            </CreateButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(DiscountForm);
