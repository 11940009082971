import { makeVar } from '@apollo/client';
import { UserRole } from '../generated/globalTypes';

export type PermissionsStateVarData = {
  // Products
  allowCreateProducts: boolean;
  allowEditProducts: boolean;
  allowAdjustProducts: boolean;
  allowShowAdjustProducts: boolean;
  allowRemoveProducts: boolean;

  // Partners
  allowCreatePartners: boolean;
  allowEditPartners: boolean;
  allowRemovePartners: boolean;
  allowPartnerRequests: boolean;

  // Expenses
  allowShowExpenses: boolean;
  allowCreateExpenses: boolean;
  allowEditExpenses: boolean;
  allowRemoveExpenses: boolean;

  // Reports
  allowShowReports: boolean;

  // Metrics
  allowShowMetrics: boolean;

  // Cashier
  allowShowCashier: boolean;
  allowShowCashierOneDay: boolean;
  allowDownloadCashierData: boolean;
  allowShowProductsCashier: boolean;
  allowShowPartnersCashier: boolean;

  // Calendar
  allowShowCalendars: boolean;
  allowCreateCalendars: boolean;
  allowEditCalendars: boolean;
  allowRemoveCalendars: boolean;

  // Files
  allowShowFiles: boolean;
  allowCreateFiles: boolean;
  allowRemoveFiles: boolean;

  // Providers
  allowShowProviders: boolean;
  allowCreateProviders: boolean;
  allowEditProviders: boolean;
  allowRemoveProviders: boolean;

  // Discounts
  allowShowDiscounts: boolean;
  allowCreateDiscounts: boolean;
  allowAssignDiscounts: boolean;
  allowEditDiscounts: boolean;
  allowRemoveDiscounts: boolean;

  // Quotas
  allowShowQuotas: boolean;
  allowCreateQuotas: boolean;
  allowAssignQuotas: boolean;
  allowEditQuotas: boolean;
  allowRemoveQuotas: boolean;

  // Credits concepts
  allowShowCreditsConcept: boolean;
  allowCreateCreditsConcept: boolean;
  allowEditCreditsConcept: boolean;
  allowRemoveCreditsConcept: boolean;

  // Categories
  allowCreateCategories: boolean;
  allowEditCategories: boolean;
  allowRemoveCategories: boolean;

  // Contract
  allowShowContract: boolean;
  allowEditContract: boolean;
  allowAssignContract: boolean;

  // Organization
  allowShowTransactionsHistory: boolean;
};

export const emptyPermissionsState: PermissionsStateVarData = {
  // Products
  allowCreateProducts: false,
  allowEditProducts: false,
  allowAdjustProducts: false,
  allowShowAdjustProducts: false,
  allowRemoveProducts: false,

  // Partners
  allowCreatePartners: false,
  allowEditPartners: false,
  allowRemovePartners: false,
  allowPartnerRequests: false,

  // Expenses
  allowShowExpenses: false,
  allowCreateExpenses: false,
  allowEditExpenses: false,
  allowRemoveExpenses: false,

  // Reports
  allowShowReports: false,

  // Metrics
  allowShowMetrics: false,

  // Cashier
  allowShowCashier: false,
  allowShowCashierOneDay: false,
  allowDownloadCashierData: false,
  allowShowProductsCashier: false,
  allowShowPartnersCashier: false,

  // Calendar
  allowShowCalendars: false,
  allowCreateCalendars: false,
  allowEditCalendars: false,
  allowRemoveCalendars: false,

  // Files
  allowShowFiles: false,
  allowCreateFiles: false,
  allowRemoveFiles: false,

  // Providers
  allowShowProviders: false,
  allowCreateProviders: false,
  allowEditProviders: false,
  allowRemoveProviders: false,

  // Discounts
  allowShowDiscounts: false,
  allowCreateDiscounts: false,
  allowAssignDiscounts: false,
  allowEditDiscounts: false,
  allowRemoveDiscounts: false,

  // Quotas
  allowShowQuotas: false,
  allowCreateQuotas: false,
  allowAssignQuotas: false,
  allowEditQuotas: false,
  allowRemoveQuotas: false,

  // Credits concepts
  allowShowCreditsConcept: false,
  allowCreateCreditsConcept: false,
  allowEditCreditsConcept: false,
  allowRemoveCreditsConcept: false,

  // Categories
  allowCreateCategories: false,
  allowEditCategories: false,
  allowRemoveCategories: false,

  // Contract
  allowShowContract: false,
  allowEditContract: false,
  allowAssignContract: false,

  // Organization
  allowShowTransactionsHistory: false,
};

export const emptyAdminPermissionsState: PermissionsStateVarData = {
  // Products
  allowCreateProducts: true,
  allowEditProducts: true,
  allowAdjustProducts: true,
  allowShowAdjustProducts: true,
  allowRemoveProducts: true,

  // Partners
  allowCreatePartners: true,
  allowEditPartners: true,
  allowRemovePartners: true,
  allowPartnerRequests: true,

  // Expenses
  allowShowExpenses: true,
  allowCreateExpenses: true,
  allowEditExpenses: true,
  allowRemoveExpenses: true,

  // Reports
  allowShowReports: true,

  // Metrics
  allowShowMetrics: true,

  // Cashier
  allowShowCashier: true,
  allowShowCashierOneDay: false,
  allowDownloadCashierData: true,
  allowShowProductsCashier: true,
  allowShowPartnersCashier: true,

  // Calendar
  allowShowCalendars: true,
  allowCreateCalendars: true,
  allowEditCalendars: true,
  allowRemoveCalendars: true,

  // Files
  allowShowFiles: true,
  allowCreateFiles: true,
  allowRemoveFiles: true,

  // Providers
  allowShowProviders: true,
  allowCreateProviders: true,
  allowEditProviders: true,
  allowRemoveProviders: true,

  // Discounts
  allowShowDiscounts: true,
  allowCreateDiscounts: true,
  allowAssignDiscounts: true,
  allowEditDiscounts: true,
  allowRemoveDiscounts: true,

  // Quotas
  allowShowQuotas: true,
  allowCreateQuotas: true,
  allowAssignQuotas: true,
  allowEditQuotas: true,
  allowRemoveQuotas: true,

  // Credits concepts
  allowShowCreditsConcept: true,
  allowCreateCreditsConcept: true,
  allowEditCreditsConcept: true,
  allowRemoveCreditsConcept: true,

  // Categories
  allowCreateCategories: true,
  allowEditCategories: true,
  allowRemoveCategories: true,

  // Contract
  allowShowContract: true,
  allowEditContract: true,
  allowAssignContract: true,

  // Organization
  allowShowTransactionsHistory: true,
};

export const permissionsStateVar = makeVar<PermissionsStateVarData>(
  emptyPermissionsState,
);

export const resetPermissionsStateVar = () => {
  permissionsStateVar(emptyPermissionsState);
};

export const setPermissionsStateVar = (
  data: Partial<PermissionsStateVarData>,
  role: UserRole,
) => {
  if ([UserRole.ADMIN, UserRole.INDITAS].includes(role)) {
    permissionsStateVar({ ...emptyAdminPermissionsState, ...data });
  } else {
    permissionsStateVar({ ...emptyPermissionsState, ...data });
  }
};
