import { gql } from '@apollo/client';
import {
  PARTNER_FRAGMENT,
  PARTNER_LIST_FRAGMENT,
  PARTNER_STATISTICS_FRAGMENT,
} from 'apollo/fragments/Partner';

export const GET_PARTNERS = gql`
  query GetPartners($filter: String) {
    partners(filter: $filter) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const GET_PARTNERS_TO_LIST = gql`
  query GetPartnersToList($filter: String) {
    partners(filter: $filter) {
      ...PartnerListData
    }
  }
  ${PARTNER_LIST_FRAGMENT}
`;

export const GET_PARTNER = gql`
  query GetPartner($id: ID!) {
    partner(id: $id) {
      ...PartnerData
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const GET_PARTNER_TRANSACTIONS = gql`
  query GetPartnerTransactions($id: ID!, $filters: PartnerTransactionsFilters) {
    partnerTransactions(id: $id, filters: $filters) {
      ...PartnerTransactionsData
    }
  }
  ${PARTNER_STATISTICS_FRAGMENT}
`;

export const VALIDATE_REMOVED_PARTNER = gql`
  query ValidateRemovedPartner($document: String!) {
    validateRemovedPartner(document: $document)
  }
`;

export const PARTNER_CONSUMPTIONS_STATS = gql`
  query GetPartnerConsumptionsStats($id: ID!) {
    partnerConsumptionsStats(id: $id) {
      entries
      purchases
      totalPriceOfPurchases
      numPurchasesByEntriesRatio
      totalPurchasesPriceByEntriesRatio
    }
  }
`;
