import { FC, memo } from 'react';
import {
  ConsumptionInfo,
  ConsumptionLevel,
  Container,
  Section,
  SectionHeader,
  SectionTitle,
} from './styles';
import type { Props } from './types';

const ConsumptionData: FC<Props> = ({ className, consumptionData }) => {
  return (
    <Container className={className}>
      <Section>
        <SectionHeader>
          <SectionTitle>Frecuencia</SectionTitle>
          <ConsumptionLevel>
            {consumptionData.partnerNumOfPurchaseCategory}
          </ConsumptionLevel>
        </SectionHeader>
        <ConsumptionInfo>
          {consumptionData.entries} entradas últimos 6 meses
        </ConsumptionInfo>
        <ConsumptionInfo>
          {consumptionData.purchases} compras últimos 6 meses
        </ConsumptionInfo>
      </Section>
      <Section>
        <SectionHeader>
          <SectionTitle>Nivel de consumo</SectionTitle>
          <ConsumptionLevel>
            {consumptionData.partnerTotalPurchasesPriceCategory}
          </ConsumptionLevel>
        </SectionHeader>
        <ConsumptionInfo>{consumptionData.purchases} compras</ConsumptionInfo>
        <ConsumptionInfo>
          {consumptionData.totalPriceOfPurchases.toFixed(2)} cr últimos 6 meses
        </ConsumptionInfo>
      </Section>
    </Container>
  );
};

export default memo(ConsumptionData);
