import { gql } from '@apollo/client';

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryData on Category {
    id
    name
    description
    type
    showInCashier
    updatedAt
    createdAt
    deletedAt
  }
`;
