import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Price from 'components/Price';
import {
  Billing,
  Chevron,
  ChevronRightIcon,
  Container,
  Content,
  InfoBlock,
  MonthlyInfo,
  Name,
  NameContainer,
  MemberNum,
  Sales,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({
  className,
  employee,
  fromTabletLandscape,
  openPartnerDetails,
}) => {
  const navigate = useNavigate();

  const handleGoToDetail = useCallback(() => {
    navigate(`/employees/${employee.id}?section=profile`);
  }, [employee, navigate]);

  const handleOpenPartnerDetails = useCallback(
    () => openPartnerDetails(employee.partnerNum),
    [employee, openPartnerDetails],
  );

  const monthlyBilling = Number((employee.monthlyBilling || 0).toFixed(2));

  return (
    <Container className={className}>
      <MemberNum onClick={handleOpenPartnerDetails}>
        {employee.partnerNum}
      </MemberNum>
      <Content onClick={handleGoToDetail}>
        {fromTabletLandscape ? (
          <>
            <NameContainer>
              <Name $active={employee.isActive}>
                {employee.fullName} {!employee.isActive && '(inactivo)'}
              </Name>
            </NameContainer>
            <Sales>{employee.monthlySales}</Sales>
            <Billing>
              <Price>{monthlyBilling}</Price>
            </Billing>
          </>
        ) : (
          <InfoBlock>
            <NameContainer>
              <Name $active={employee.isActive}>
                {' '}
                {employee.fullName} {!employee.isActive && '(inactivo)'}
              </Name>
            </NameContainer>
            <MonthlyInfo>
              Este mes: {employee.monthlySales} ·{' '}
              <Price>{monthlyBilling}</Price>
            </MonthlyInfo>
          </InfoBlock>
        )}
        <Chevron>
          <ChevronRightIcon />
        </Chevron>
      </Content>
    </Container>
  );
};

export default memo(Row);
