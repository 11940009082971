import { FC } from 'react';
import { Formik } from 'formik';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { UserGender } from 'apollo/generated/globalTypes';
import Loader from 'components/Loader';
import Select from 'components/Inputs/Select';
import useConnect from './connect';
import {
  Avatar,
  Buttons,
  Container,
  Content,
  DatePicker,
  Form,
  CreateButton,
  TextField,
  Row,
  Header,
  BackLink,
  AvatarContainer,
  RemoveButton,
  HeaderInfo,
  HeaderName,
  HeaderButtons,
} from './styles';
import { validationSchema } from './constants';

const NewPartnerRequestDetails: FC = () => {
  const {
    isLoading,
    selectedHostPartner,
    initialValues,
    handleSubmit,
    activateRfidReader,
    handleSelectHostPartner,
    handleOnRemove,
    partnerRequest,
  } = useConnect();
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const formId = `new_member`;
  const hasHostPartner = !!(
    selectedHostPartner?.memberNum && selectedHostPartner?.fullName
  );

  window.addEventListener('keypress', (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  return (
    <Container>
      <Header>
        <BackLink to="/organization/partners-requests" />
        <AvatarContainer>
          <Avatar
            src={partnerRequest?.avatarUrl}
            fullName={`${partnerRequest?.firstName} ${partnerRequest?.lastName}`}
          />
        </AvatarContainer>
        {fromTabletPortrait && (
          <HeaderInfo>
            <HeaderName>Solicitud de alta</HeaderName>
          </HeaderInfo>
        )}
        <HeaderButtons>
          <RemoveButton onClick={handleOnRemove}>Eliminar</RemoveButton>
        </HeaderButtons>
      </Header>
      {isLoading && <Loader />}
      {!isLoading && (
        <Content>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ isValid: isFormValid }) => (
              <Form>
                <Row>
                  <TextField
                    name="hostMemberNum"
                    label="Padrino *"
                    id={`${formId}_hostMemberNum`}
                    icon="arrowRight"
                    iconSize={8}
                    onClick={handleSelectHostPartner}
                    value={
                      hasHostPartner
                        ? selectedHostPartner?.fullName
                        : 'Seleccionar'
                    }
                    readOnly
                  />
                  <TextField
                    type="number"
                    name="maxConsumeMonth"
                    label="Consumo máximo *"
                    id={`${formId}_maxConsumeMonth`}
                  />
                </Row>
                <Row>
                  <TextField
                    name="firstName"
                    label="Nombre *"
                    id={`${formId}_firstName`}
                  />
                  <TextField
                    name="lastName"
                    label="Apellidos *"
                    id={`${formId}_lastName`}
                  />
                </Row>
                <Row>
                  <TextField
                    name="document"
                    label="DNI *"
                    id={`${formId}_document`}
                  />
                  <TextField
                    name="address"
                    label="Domicilio *"
                    id={`${formId}_address`}
                  />
                </Row>
                <Row>
                  <TextField
                    id={`${formId}_email`}
                    label="email *"
                    name="email"
                    type="email"
                  />
                  <TextField
                    name="phoneNumber"
                    label="Nº de móvil *"
                    id={`${formId}_phoneNumber`}
                  />
                </Row>
                <Row>
                  <Select
                    name="gender"
                    label="Genero"
                    id={`${formId}_gender`}
                    options={[
                      {
                        value: UserGender.MALE,
                        label: 'Hombre',
                      },
                      {
                        value: UserGender.FEMALE,
                        label: 'Mujer',
                      },
                      {
                        value: UserGender.OTHER,
                        label: 'No especificado',
                      },
                    ]}
                  />
                  <DatePicker
                    label="Fecha de nacimiento *"
                    name="birthDate"
                    id={`${formId}_birthDate`}
                  />
                </Row>
                <Row>
                  <Select
                    name="usage"
                    label="¿Lúdico o terapéutico?"
                    id={`${formId}_usage`}
                    options={[
                      {
                        value: 'PLAYFUL',
                        label: 'Lúdico',
                      },
                      {
                        value: 'THERAPEUTIC',
                        label: 'Terapéutico',
                      },
                    ]}
                  />
                  {activateRfidReader && (
                    <TextField
                      name="rfidCode"
                      label="Código RFID"
                      id={`${formId}_rfidCode`}
                      icon="rfid"
                      iconSize={22}
                      onLocalChange={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                    />
                  )}
                </Row>
                <Row>
                  <TextField
                    name="memberNum"
                    label="Nº de socio"
                    id={`${formId}_memberNum`}
                  />
                </Row>
                <Buttons>
                  <CreateButton
                    type="submit"
                    disabled={!hasHostPartner || !isFormValid}
                    isLoading={isLoading}
                  >
                    Crear Socio
                  </CreateButton>
                </Buttons>
              </Form>
            )}
          </Formik>
        </Content>
      )}
    </Container>
  );
};

export default NewPartnerRequestDetails;
