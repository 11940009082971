import type { BillingMonthData } from 'apollo/generated/BillingMonthData';
import type { ChartData } from 'apollo/generated/ChartData';
import type { TotalPartnersMetricData } from 'apollo/generated/TotalPartnersMetricData';
import type { ProductRankingData } from 'apollo/generated/ProductRankingData';
import type { MonthProfit, PurchaseTime } from 'apollo/graphql.types';
import { isAfter, subWeeks } from 'date-fns';

const MONTHS_MAP = {
  '1': 'Enero',
  '2': 'Febrero',
  '3': 'Marzo',
  '4': 'Abril',
  '5': 'Mayo',
  '6': 'Junio',
  '7': 'Julio',
  '8': 'Agosto',
  '9': 'Septiembre',
  '10': 'Octubre',
  '11': 'Noviembre',
  '12': 'Diciembre',
};

const WEEK_DAYS_MAP = {
  0: 'Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
};

const HOURS_MAP = {
  0: '00:00',
  1: '01:00',
  2: '02:00',
  3: '03:00',
  4: '04:00',
  5: '05:00',
  6: '06:00',
  7: '07:00',
  8: '08:00',
  9: '09:00',
  10: '10:00',
  11: '11:00',
  12: '12:00',
  13: '13:00',
  14: '14:00',
  15: '15:00',
  16: '16:00',
  17: '17:00',
  18: '18:00',
  19: '19:00',
  20: '20:00',
  21: '21:00',
  22: '22:00',
  23: '23:00',
  24: '00:00',
};

export const normalizeBestPurchasesTimes = (input: PurchaseTime) => {
  return {
    topMonths: input.topMonths.map((top) => ({
      // @ts-ignore
      value: MONTHS_MAP[top.value.toString()],
      total: top.total || 0,
    })),
    bottomMonths: input.bottomMonths.map((top) => ({
      // @ts-ignore
      value: MONTHS_MAP[top.value.toString()],
      total: top.total || 0,
    })),
    topDays: input.topDays.map((top) => ({
      // @ts-ignore
      value: WEEK_DAYS_MAP[top.value],
      total: top.total || 0,
    })),
    bottomDays: input.bottomDays.map((top) => ({
      // @ts-ignore
      value: WEEK_DAYS_MAP[top.value],
      total: top.total || 0,
    })),
    topHours: input.topHours.map((top) => ({
      // @ts-ignore
      value: HOURS_MAP[top.value],
      total: top.total || 0,
    })),
    bottomHours: input.bottomHours.map((top) => ({
      // @ts-ignore
      value: HOURS_MAP[top.value],
      total: top.total || 0,
    })),
  };
};

export type BestPurchasesTimes = ReturnType<typeof normalizeBestPurchasesTimes>;

export const normalizeChart = (input: ChartData) => ({
  name: input.name || '',
  value: input.value || 0,
});

export type Chart = ReturnType<typeof normalizeChart>;

export const normalizeBillingMetric = (input: BillingMonthData) => ({
  totalMonthCredits: input.totalMonthCredits || 0,
  totalWeekCredits: input.totalWeekCredits || 0,
  chart: input.chart.map(normalizeChart),
});

export const normalizeMonthlyProductsMetric = (input: MonthProfit) => ({
  // @ts-ignore
  month: MONTHS_MAP[input.month || '1'],
  costes: Number((input.costs || 0).toFixed(2)),
  ganancias: Number((input.profits || 0).toFixed(2)),
});

export type MonthlyProductsMetric = ReturnType<
  typeof normalizeMonthlyProductsMetric
>;

export const normalizeMonthlyCreditsMetric = (input: MonthProfit) => ({
  // @ts-ignore
  month: MONTHS_MAP[input.month || '1'],
  gastos: Number((input.costs || 0).toFixed(2)),
  donaciones: Number((input.profits || 0).toFixed(2)),
});

export type MonthlyCreditsMetric = ReturnType<
  typeof normalizeMonthlyCreditsMetric
>;

export const normalizePartnersMetric = (input: TotalPartnersMetricData) => ({
  totalPartners: input.partners || 0,
  totalMonthPartners: input.totalMonthPartners || 0,
  chart: input.chart.map(normalizeChart),
});

export enum ProductRankingState {
  UP = 'UP',
  DOWN = 'DOWN',
  EQUAL = 'EQUAL',
}

export const normalizeProductRanking = (input: ProductRankingData) => {
  const lastPosition = input.lastPosition || 0;
  const position = input.position || 0;
  const createdAt = input.product.createdAt
    ? new Date(input.product.createdAt)
    : new Date();

  let state = ProductRankingState.EQUAL;

  if (lastPosition < position) {
    state = ProductRankingState.DOWN;
  } else if (lastPosition > position) {
    state = ProductRankingState.UP;
  }

  return {
    lastPosition,
    position,
    product: {
      id: input.product.id || '',
      name: input.product.name || '',
      // @ts-ignore
      referenceCode: input.product.referenceCode || '',
      createdAt,
    },
    totalPurchases: input.totalPurchases || 0,
    totalSales: input.totalSales || 0.0,
    isNew: isAfter(createdAt, subWeeks(new Date(), 1)),
    state,
  };
};

export type ProductRanking = ReturnType<typeof normalizeProductRanking>;
