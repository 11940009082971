import { FC, memo } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './constants';
import {
  Buttons,
  CancelButton,
  Container,
  CreateButton,
  Row,
  TextField,
} from './styles';
import type { Props } from './types';

const Form: FC<Props> = ({
  className,
  id = 'new',
  initialValues,
  isLoading,
  onCancel,
  onSubmit,
}) => {
  const formId = `adjustProductBaseWeight_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid, values }) => (
        <Container className={className}>
          <Row>
            <TextField
              readOnly
              type="number"
              name="lastBaseWeight"
              label="Peso Base Actual"
            />
            <TextField
              type="number"
              name="baseWeight"
              label="Nuevo Peso Base"
              id={`${formId}_baseWeight`}
            />
          </Row>

          <TextField
            type="text"
            name="note"
            label="Nota"
            id={`${formId}_note`}
          />

          <Buttons>
            <CreateButton
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || !isFormValid || values?.baseWeight === 0}
            >
              Guardar
            </CreateButton>
            <CancelButton onClick={onCancel}>Cancelar</CancelButton>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
};

export default memo(Form);
