import { FC, memo, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { useNavigate } from 'react-router-dom';
import Price from 'components/Price';
import {
  AvatarIcon,
  Balance,
  Chevron,
  Container,
  Identifier,
  Content,
  Name,
  Number,
  OrdersIcon,
  WithoutQuota,
  ImageContainer,
  Avatar,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, onClickActions, partner }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const navigate = useNavigate();
  const {
    credits,
    hostMemberNum,
    fullName,
    id: partnerId,
    memberNum,
    quota,
  } = partner;
  const isSubscribed = quota?.isSubscribed;

  const handleGoToDetail = useCallback(() => {
    navigate(`/partners/${partnerId}?section=profile`);
  }, [partnerId, navigate]);

  const handleOnClickActions = useCallback(
    () => onClickActions(partner),
    [onClickActions, partner],
  );

  return (
    <Container className={className}>
      <Content onClick={handleGoToDetail}>
        <ImageContainer>
          {partner.avatarImageUrl ? (
            <Avatar src={partner.avatarImageUrl} />
          ) : (
            <AvatarIcon />
          )}
        </ImageContainer>
        <Number>#{memberNum}</Number>
        <Name>
          {fullName} {!isSubscribed && <WithoutQuota>(sin cuota)</WithoutQuota>}
        </Name>
        {fromTabletPortrait && <Identifier>{hostMemberNum}</Identifier>}
        <Balance $credits={credits}>
          <Price>{credits}</Price>
        </Balance>
      </Content>
      {fromTabletPortrait && (
        <Chevron onClick={handleOnClickActions}>
          <OrdersIcon />
        </Chevron>
      )}
    </Container>
  );
};

export default memo(Row);
