import { gql } from '@apollo/client';
import {
  BILLING_MONTH_FRAGMENT,
  PARTNERS_METRIC_FRAGMENT,
  PRODUCT_RANKING_FRAGMENT,
} from 'apollo/fragments/Metric';

export const GET_BILLINGS_METRIC = gql`
  query GetBillingsMetric {
    billingsMetric {
      ...BillingMonthData
    }
  }
  ${BILLING_MONTH_FRAGMENT}
`;

export const GET_PARTNERS_METRIC = gql`
  query GetPartnersMetric {
    partnersMetric {
      ...TotalPartnersMetricData
    }
  }
  ${PARTNERS_METRIC_FRAGMENT}
`;

export const GET_PRODUCTS_RANKING = gql`
  query GetProductsRanking {
    productsRanking {
      ...ProductRankingData
    }
  }
  ${PRODUCT_RANKING_FRAGMENT}
`;

export const GET_MONTHLY_PROFIT = gql`
  query GetMonthlyProfit {
    monthlyProfit {
      month
      profits
      costs
    }
  }
`;

export const GET_MONTHLY_COLLECTED_CREDITS_PROFIT = gql`
  query GetMonthlyCollectedCreditsProfit {
    monthlyCollectedCreditsProfit {
      month
      profits
      costs
    }
  }
`;

export const GET_BEST_PURCHASES_TIMES = gql`
  query GetBestPurchasesTimes {
    bestPurchasesTimes {
      topMonths {
        value
        total
      }
      bottomMonths {
        value
        total
      }
      topDays {
        value
        total
      }
      bottomDays {
        value
        total
      }
      topHours {
        value
        total
      }
      bottomHours {
        value
        total
      }
    }
  }
`;
