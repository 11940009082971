import styled from 'styled-components';
import { from } from 'styles/responsive';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  margin-top: 3rem;
  gap: 0.5rem;
`;

export const InfoContent = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border: 1px solid #6d7a8229;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  min-height: 7rem;
  width: 100%;

  ${from.tabletPortrait`
    max-width: 20rem;
  `};
`;

export const InfoContentHeader = styled.div`
  border-bottom: 1px solid #6d7a8229;
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
`;

export const InfoContentBody = styled.div`
  display: flex;
  flex-flow: column;
`;

export const InfoContentTitle = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  ${from.tabletPortrait`
    font-size: 16px;
  `};
`;

export const InfoContentLabel = styled.span`
  color: #6d7a82;
  font-size: 12px;
  font-weight: 400;

  &:not(:last-child) {
    padding-bottom: 0.5rem;
  }

  ${from.tabletPortrait`
    font-size: 1rem;
  `};
`;

export const InfoContentValue = styled(InfoContentLabel)`
  font-weight: bold;
  padding-bottom: 0;
`;
