import { FC, memo } from 'react';
import { Container, Label, Loader } from './styles';
import type { Props } from './types';

const Button: FC<Props> = ({
  className,
  onClick,
  disabled,
  isLoading,
  children,
  color,
  variant = 'primary',
  to,
  ...rest
}) => (
  <Container
    $isLoading={isLoading}
    $variant={variant}
    className={className}
    disabled={isLoading || disabled}
    onClick={!isLoading ? onClick : undefined}
    to={to}
    {...rest}
  >
    <Label $isLoading={!!isLoading}>{children}</Label>
    {isLoading && <Loader aria-label="loader" />}
  </Container>
);

export default memo(Button);
