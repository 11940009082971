import { ChangeEvent, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import usePartners from 'apollo/hooks/partner/usePartners';
import useModal from 'apollo/hooks/useModal';
import { orderVar } from 'apollo/reactive/order';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { Partner, PartnerToList } from 'model/Partner';

const useConnect = () => {
  const { partners, loading, refetch } = usePartners({
    fetchPolicy: 'cache-and-network',
  });
  const {
    openNewPartner: openNewPartnerModal,
    openNewOrder,
    openPartnerMenu,
    openManageCredits,
  } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');

  const handleSearch = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value;
      try {
        setIsRefetching(true);
        setFilter(newSearch);

        await refetch({ filter: newSearch });
        setIsRefetching(false);
      } catch (error) {
        setIsRefetching(false);
        enqueueSnackbar('No se ha podido realizar la búsqueda', {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={error} />,
        });
      }
    },
    [enqueueSnackbar, refetch],
  );

  const handleForceSearch = useCallback(async () => {
    setIsRefetching(true);
    try {
      await refetch({ filter });
      setIsRefetching(false);
    } catch (error) {
      setIsRefetching(false);
      enqueueSnackbar('No se ha podido realizar la búsqueda', {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={error} />,
      });
    }
  }, [enqueueSnackbar, filter, refetch]);

  const handleNewOrder = useCallback(
    async (partner: Partner) => {
      if (partner) {
        orderVar({
          products: [],
          partner,
          orderDiscount: null,
        });
        openNewOrder();
      }
    },
    [openNewOrder],
  );

  const handleLoadCredits = useCallback(
    async (partner: Partner) => {
      openManageCredits({
        action: 'added',
        partner,
        onReturn: async () => {
          await refetch({ filter });
        },
      });
    },
    [refetch, filter, openManageCredits],
  );

  const handleOpenPartnerMenuModal = useCallback(
    async (partner: PartnerToList) => {
      openPartnerMenu({
        newOrder: () => handleNewOrder(partner as Partner),
        loadCredits: () => handleLoadCredits(partner as Partner),
      });
    },
    [handleLoadCredits, handleNewOrder, openPartnerMenu],
  );

  return {
    isLoading: loading,
    partners,
    openNewPartnerModal,
    isRefetching,
    filter,
    handleSearch,
    handleNewOrder,
    handleOpenPartnerMenuModal,
    handleForceSearch,
  };
};

export default useConnect;
