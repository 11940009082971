import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultFilters from 'components/Filters';
import DefaultButton from 'components/Button';
import DefaultModal, { Content } from 'components/Modal';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;

    ${from.tabletPortrait`
      width: 100%;
      max-width: 45rem;
    `}

    ${from.tabletLandscape`
      width: 100%;
      max-width: 55rem;
    `}
  }
`;

export const ButtonContent = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
`;

export const ReturnButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;

export const RowsContainer = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(white, rgba(255, 255, 255, 0.001));
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  }
`;

export const Rows = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
  margin: 1.5rem 0;

  ${from.tabletPortrait`
    max-height: 30rem;
    min-height: 12rem;
  `}
`;

export const LandscapeListContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow-y: scroll;
  margin: 1.5rem 0;
  gap: 0.8rem;
  max-height: 28rem;

  ${from.tabletPortrait`
    max-height: 20rem;
    min-height: 12rem;
  `}
`;

export const FiltersContainer = styled.div`
  flex: 0 1 auto;
`;

export const Filters = styled(DefaultFilters)`
  padding: 0;

  ${from.tabletPortrait`
    padding: 0 1.4rem;
  `};
`;

export const CategoriesFilter = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
  gap: 0.5rem;

  ${from.tabletPortrait`
    padding: 0 1.4rem;
  `}
`;

export const CategoryName = styled.span`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: color 200ms ease;
`;

export const CategoryFilter = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.grey400};
  border-radius: 0.5rem;
  transition: border-color 200ms ease;
  padding: 0.4rem 0.7rem;

  ${from.tabletPortrait`
    padding: 0.6rem 1rem;
  `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey900};

    ${CategoryName} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme, $selected }) =>
    $selected &&
    css`
      border-color: ${theme.colors.primary};

      ${CategoryName} {
        color: ${theme.colors.primary};
        font-weight: 600;
      }
    `}
`;
