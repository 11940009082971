import { memo } from 'react';
import type { FC } from 'react';
import {
  Avatar,
  AvatarIcon,
  Container,
  Credits,
  Name,
  Number,
  ImageContainer,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, partner, onSelect }) => {
  return (
    <Container className={className} onClick={onSelect}>
      <ImageContainer>
        {partner.avatarImageUrl ? (
          <Avatar src={partner.avatarImageUrl} />
        ) : (
          <AvatarIcon />
        )}
      </ImageContainer>
      <Number>#{partner.memberNum}</Number>
      <Name>{partner.fullName}</Name>
      <Credits>{partner.credits} cr</Credits>
    </Container>
  );
};

export default memo(Row);
