import type { FC } from 'react';
import Form from './Form';
import useConnect from './connect';
import {
  Container,
  Title,
  Icon,
  IconCircle,
  Header,
  CancelButton,
  Buttons,
  RemoveButton,
} from './styles';

const QuotaDetails: FC = () => {
  const { handleClose, isOpen, initialValues, handleOpenRemoveDialog } =
    useConnect();

  return (
    <Container disableBackdropClick open={isOpen} onClose={handleClose}>
      <Header>
        <IconCircle>
          <Icon name="quota" />
        </IconCircle>
        <Title>Cuota Asignada '{initialValues.name}'</Title>
      </Header>
      <Form initialValues={initialValues} />
      <Buttons>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
        <RemoveButton onClick={handleOpenRemoveDialog}>Eliminar</RemoveButton>
      </Buttons>
    </Container>
  );
};

export default QuotaDetails;
