import { memo, FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import {
  Benefit,
  Container,
  Costs,
  Discounts,
  Name,
  Purchases,
  Quantity,
  RemainingQuantity,
  ReferenceCode,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, product, activateProductReference }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const {
    name,
    referenceCode,
    quantity,
    consumedQuantity,
    totalCosts,
    totalPurchases,
    totalDiscounts,
  } = product;

  const hasDiscount = totalDiscounts > 0;
  const totalBenefit = totalPurchases - totalCosts;

  return (
    <Container className={className}>
      <Name>
        {name}{' '}
        {activateProductReference && referenceCode && (
          <ReferenceCode>#{referenceCode}</ReferenceCode>
        )}
      </Name>
      {fromTabletPortrait && <Quantity>{consumedQuantity}</Quantity>}
      <Purchases>
        <Price>
          {hasDiscount ? totalDiscounts + totalPurchases : totalPurchases}
        </Price>
      </Purchases>
      <Costs>
        {totalCosts > 0 && '-'}
        <Price>{totalCosts}</Price>
      </Costs>
      <Discounts>
        {hasDiscount && '-'}
        <Price>{totalDiscounts}</Price>
      </Discounts>
      <Benefit>
        <Price>{totalBenefit}</Price>
      </Benefit>
      {fromTabletPortrait && <RemainingQuantity>{quantity}</RemainingQuantity>}
    </Container>
  );
};

export default memo(Row);
