const formatter = Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 2,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice = (value: number) => {
  const parts = formatter.formatToParts(value).map(({ value, type }) => {
    if (type === 'group') {
      return ' ';
    }

    if (type === 'decimal') {
      return '.';
    }

    return value;
  });

  return parts.join('');
};
