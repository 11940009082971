import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultButton from 'components/Button';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultOrdersIcon from 'components/Icon/Icons/ShoppingCart';
import { prettyScrollbar } from 'styles/mixins';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    ${from.tabletPortrait`
      max-width: 36rem;
    `}
  }
`;

export const HeaderContainer = styled.div`
  flex: 0 1 auto;
`;

export const Button = styled(DefaultButton)``;

export const IconCircle = styled(DefaultIconCircle)``;

export const OrdersIcon = styled(DefaultOrdersIcon).attrs(() => ({
  size: 20,
}))``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2.75rem;
`;

export const Title = styled(ModalTitle)`
  margin-top: 1rem;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
`;

export const CloseButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  width: 100%;
`;

export const Products = styled.div`
  ${prettyScrollbar};
  max-height: 20rem;
  overflow-y: scroll;
`;

export const ProductsContainer = styled.div`
  flex: 1 1 auto;
  margin-bottom: 1rem;
`;

export const ProductRow = styled.div`
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  color: ${({ theme }) => theme.colors.grey500};
  display: flex;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  padding: 1.0625rem 1rem 1.125rem;
  justify-content: space-between;
`;

export const ProductTitle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
`;

export const ProductDiscount = styled.span`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  text-transform: capitalize;
`;

export const TotalProducts = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const ProductPrice = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-bottom: 0;
  margin-left: auto;
  margin-top: 0;
`;

export const TotalPrice = styled(ProductPrice)``;

export const OriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: ${({ theme }) => theme.weights.medium};
  position: relative;

  &:before {
    border-color: inherit;
    border-top: 1px solid;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    transform: skewY(-10deg);
  }
`;

export const OriginalProductPrice = styled(OriginalPrice)`
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: ${({ theme }) => theme.weights.medium};
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductNameContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  ${TextEllipsis}
`;

export const ProductName = styled.span`
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const ProductDiscountName = styled.span`
  font-size: 0.8rem;
  text-transform: capitalize;
`;

export const ProductReferenceCode = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 0.8rem;
  margin-left: 0.3rem;
`;

export const ProductQuantity = styled.span`
  ${TextEllipsis}
`;

export const ProductTotalPrice = styled.div`
  display: flex;
  flex-flow: column;
  ${TextEllipsis}
`;
