import type { FC } from 'react';
import { Formik } from 'formik';
import Loader from 'components/Loader';
import ProfileForm from './ProfileForm';
import DocumentsForm from './DocumentsForm';
import useConnect from './connect';
import { validationSchema } from './constants';
import {
  Container,
  FinishButton,
  Footer,
  NextButton,
  PreviousButton,
  RequestMsg,
  GreenIconCircle,
  Icon,
  DangerIconCircle,
  MessageContainer,
  Logo,
  ResultContainer,
} from './styles';

const NewPartnerForm: FC = () => {
  const {
    initialValues,
    handleSetDocuments,
    handleChangeStep,
    stepNum,
    handleFinish,
    selfieFile,
    document1File,
    document2File,
    isLoading,
    finishStatus,
  } = useConnect();

  const isSecondStepDisabled =
    stepNum === 2 && (!selfieFile || !document1File || !document2File);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      {finishStatus && (
        <ResultContainer>
          <Logo />
          {finishStatus.statusOk ? (
            <MessageContainer $isOk>
              <GreenIconCircle>
                <Icon name="check" />
              </GreenIconCircle>
              <RequestMsg>La solicitud ha sido enviada.</RequestMsg>
            </MessageContainer>
          ) : (
            <MessageContainer>
              <DangerIconCircle>
                <Icon name="close" />
              </DangerIconCircle>
              <RequestMsg>
                Ha ocurrido un error al enviar la solicitud. Inténtalo mas tarde
                o contacte con la organización.
              </RequestMsg>
            </MessageContainer>
          )}
        </ResultContainer>
      )}

      {!finishStatus && (
        <Formik
          onSubmit={handleFinish}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid: isFormValid, submitForm }) => (
            <>
              {stepNum === 1 && <ProfileForm />}
              {stepNum === 2 && (
                <DocumentsForm
                  handleOnGetFile={handleSetDocuments}
                  selectedSelfie={selfieFile}
                  selectedDocument1={document1File}
                  selectedDocument2={document2File}
                />
              )}

              <Footer>
                <PreviousButton
                  onClick={() => handleChangeStep('prev')}
                  disabled={stepNum === 1}
                >
                  Volver
                </PreviousButton>
                {stepNum === 2 ? (
                  <FinishButton
                    disabled={isSecondStepDisabled}
                    onClick={submitForm}
                  >
                    Finalizar
                  </FinishButton>
                ) : (
                  <NextButton
                    onClick={() => handleChangeStep('next')}
                    disabled={!isFormValid}
                  >
                    Siguiente
                  </NextButton>
                )}
              </Footer>
            </>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default NewPartnerForm;
