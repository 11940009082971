import { FC, memo } from 'react';
import { Chevron, Container } from './styles';
import type { Props } from './types';

const BackLink: FC<Props> = ({ size = 8.5, ...props }) => (
  <Container {...props}>
    <Chevron size={size} />
  </Container>
);

export default memo(BackLink);
