import { FC, memo } from 'react';
import TableHead from './TableHead';
import Row from './Row';
import useLogic from './logic';
import {
  Button,
  Container,
  EmptyState,
  Section,
  SectionTitle,
  SectionContent,
} from './styles';
import type { Props } from './types';

const WorkSchedulesForm: FC<Props> = ({
  className,
  employeeId,
  workSchedules,
}) => {
  const { handleRemoveSchedule, handleOpenAddWorkScheduleModal } =
    useLogic(employeeId);

  return (
    <Container className={className}>
      <Section>
        <SectionTitle>
          <Button
            variant="light_black"
            onClick={handleOpenAddWorkScheduleModal}
          >
            Crear tramo
          </Button>
        </SectionTitle>
        <SectionContent>
          {workSchedules.length > 0 ? (
            <>
              <TableHead />
              {workSchedules.map((workSchedule) => (
                <Row
                  key={workSchedule.id}
                  workSchedule={workSchedule}
                  onRemove={() => handleRemoveSchedule(workSchedule.id)}
                />
              ))}
            </>
          ) : (
            <EmptyState
              title="No hay tramos"
              iconName="clock"
              buttonLabel="Añadir tramo"
              onClickButton={handleOpenAddWorkScheduleModal}
            >
              Crea tu primer tramo horario
            </EmptyState>
          )}
        </SectionContent>
      </Section>
    </Container>
  );
};

export default memo(WorkSchedulesForm);
