import { memo, FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Price from 'components/Price';
import {
  Benefit,
  Container,
  Costs,
  Discounts,
  Name,
  Products,
  Purchases,
  Quantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, category, onSelectProducts }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const {
    name,
    totalCosts,
    totalPurchases,
    totalDiscounts,
    totalConsumedQuantity,
    products,
  } = category;

  const hasDiscount = totalDiscounts > 0;
  const totalBenefit = totalPurchases - totalCosts;

  return (
    <Container className={className}>
      <Name>{name}</Name>
      {fromTabletPortrait && <Quantity>{totalConsumedQuantity}</Quantity>}
      <Purchases>
        <Price>
          {hasDiscount ? totalDiscounts + totalPurchases : totalPurchases}
        </Price>
      </Purchases>
      <Costs>
        {totalCosts > 0 && '-'}
        <Price>{totalCosts}</Price>
      </Costs>
      {fromTabletPortrait && (
        <Discounts>
          {hasDiscount && '-'}
          <Price>{totalDiscounts}</Price>
        </Discounts>
      )}
      <Benefit>
        <Price>{totalBenefit}</Price>
      </Benefit>

      {fromTabletPortrait && (
        <Products
          $hasProducts={products.length > 0}
          onClick={() => onSelectProducts(products)}
        >
          {products.length || 0}
        </Products>
      )}
    </Container>
  );
};

export default memo(Row);
