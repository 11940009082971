import { useCallback, useMemo, useRef, useState } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCategories from 'apollo/hooks/category/useCategories';
import { CategoryType } from 'apollo/generated/globalTypes';
import useModal from 'apollo/hooks/useModal';
import { organizationConfigVar, permissionsStateVar } from 'apollo/reactive';
import useMenuConfig from 'apollo/hooks/organization/useMenuConfig';
import useMenuConfigActions from 'apollo/hooks/organization/useMenuConfigActions';
import type { MenuConfigFormFields } from 'model/Organization';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import { OrganizationMenuType } from 'apollo/graphql.types';
import { DEFAULT_COLORS } from './constants';

const BASE_URL = `https://www.inditas.com/menu`;

const useConnect = () => {
  const formikRef = useRef<FormikProps<MenuConfigFormFields>>(null);
  const { menuId } = useParams<{ menuId: string }>();
  const isNew = menuId === 'new';
  const { enqueueSnackbar } = useSnackbar();
  const { categories, loading: categoriesLoading } = useCategories({
    filter: { type: CategoryType.PRODUCT },
  });
  const { menuConfig, loading } = useMenuConfig(!isNew ? menuId : undefined);
  const { createMenuConfig, updateMenuConfig, removeMenuConfig } =
    useMenuConfigActions();
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const {
    openDialog,
    close: closeModal,
    openAddMenuSchedule,
    openGenerateQRDialog,
  } = useModal();
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const initialValues = useMemo(() => {
    setSelectedCategories(menuConfig?.categoriesNames || []);

    return {
      name: menuConfig?.name || '',
      type: menuConfig?.type || OrganizationMenuType.Selectable,
      showLogo: menuConfig?.showLogo || false,
      useLogoToBackground: menuConfig?.useLogoToBackground || false,
      showProductPrice: menuConfig?.showProductPrice || false,
      showProductDescription: menuConfig?.showProductDescription || false,
      showProductDiscount: menuConfig?.showProductDiscount || false,
      showPriceSimbol: menuConfig?.showPriceSimbol || false,
      menuActive: menuConfig?.menuActive || false,
      defaultImageUrl: menuConfig?.defaultImageUrl || '',
      backgroundColor: menuConfig?.backgroundColor || DEFAULT_COLORS.background,
      menuSchedules: menuConfig?.menuSchedules || [],
      imageBorderColor:
        menuConfig?.imageBorderColor || DEFAULT_COLORS.imageBorder,
      productTitleColor:
        menuConfig?.productTitleColor || DEFAULT_COLORS.productTitle,
      productDescriptionColor:
        menuConfig?.productDescriptionColor ||
        DEFAULT_COLORS.productDescription,
      productPriceColor:
        menuConfig?.productPriceColor || DEFAULT_COLORS.productPrice,
      categoriesColor:
        menuConfig?.categoriesColor || DEFAULT_COLORS.categoryName,
      menuCode: menuConfig?.menuCode
        ? `${BASE_URL}/${menuConfig.menuCode}`
        : '',
      categoriesNames: menuConfig?.categoriesNames || [],
    };
  }, [menuConfig]);

  const handleToggleCategory = useCallback(
    async (id: string) => {
      const cat = categories.find((c) => c.id === id);
      if (cat) {
        const selected = selectedCategories.find(
          (c) => c.toLowerCase() === cat.name.toLowerCase(),
        );
        if (selected) {
          setSelectedCategories((list) => list.filter((c) => c !== cat.name));
        } else {
          setSelectedCategories((list) => [...list, cat.name]);
        }
      }
    },
    [categories, selectedCategories],
  );

  const handleAddSchedule = useCallback(async () => {
    openAddMenuSchedule({
      onSave: (schedule) => {
        if (formikRef?.current) {
          formikRef.current.setFieldValue('menuSchedules', [
            ...formikRef.current.values.menuSchedules,
            schedule,
          ]);
        }
      },
    });
  }, [formikRef, openAddMenuSchedule]);

  const handleResetColors = useCallback(
    (setFieldValue: FormikHelpers<MenuConfigFormFields>['setFieldValue']) => {
      setFieldValue('backgroundColor', DEFAULT_COLORS.background);
      setFieldValue('imageBorderColor', DEFAULT_COLORS.imageBorder);
      setFieldValue('productTitleColor', DEFAULT_COLORS.productTitle);
      setFieldValue('categoriesColor', DEFAULT_COLORS.categoryName);
      setFieldValue(
        'productDescriptionColor',
        DEFAULT_COLORS.productDescription,
      );
      setFieldValue('productPriceColor', DEFAULT_COLORS.productPrice);
    },
    [],
  );

  const handleOnSubmit = useCallback(
    async (values: MenuConfigFormFields) => {
      if (isNew) {
        try {
          await createMenuConfig({
            ...values,
            categoriesNames: selectedCategories,
          });
          enqueueSnackbar('El menú se ha creado correctamente', {
            variant: 'success',
          });
          navigate('/organization/menus');
        } catch (e) {
          enqueueSnackbar(formatErrors('menu', e.message, 'crear'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      } else if (!isNew && menuConfig?.id) {
        try {
          await updateMenuConfig(menuConfig.id, {
            ...values,
            categoriesNames: selectedCategories,
          });
          enqueueSnackbar('El menú se ha actualizado correctamente', {
            variant: 'success',
          });
          navigate('/organization/menus');
        } catch (e) {
          enqueueSnackbar(formatErrors('menu', e.message, 'actualizar'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
    },
    [
      navigate,
      isNew,
      menuConfig,
      createMenuConfig,
      selectedCategories,
      enqueueSnackbar,
      updateMenuConfig,
    ],
  );

  const handleConfirmRemoveMenu = useCallback(async () => {
    try {
      if (menuConfig?.id) {
        await removeMenuConfig(menuConfig.id);
        closeModal();
        navigate('/organization/menus');
        enqueueSnackbar('El menú se ha eliminado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('menu', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [closeModal, enqueueSnackbar, menuConfig, navigate, removeMenuConfig]);

  const handleRemoveMenu = useCallback(() => {
    if (menuConfig?.id) {
      openDialog({
        acceptButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        description:
          'Vas a eliminar este menú y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
        onAccept: handleConfirmRemoveMenu,
        title: 'Eliminar Menú',
        variant: 'danger',
        iconName: 'menu',
      });
    }
  }, [menuConfig, openDialog, handleConfirmRemoveMenu]);

  const handleCopyLink = useCallback(
    async (url: string) => {
      try {
        await navigator.clipboard.writeText(url);
        enqueueSnackbar('Se ha copiado el link', {
          variant: 'info',
        });
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al copiar el link', {
          variant: 'warning',
        });
      }
    },
    [enqueueSnackbar],
  );

  const handleOpenQRModal = useCallback(async () => {
    openGenerateQRDialog({ value: initialValues.menuCode });
  }, [openGenerateQRDialog, initialValues]);

  return {
    categories,
    employeePermissions,
    formikRef,
    handleAddSchedule,
    handleCopyLink,
    handleOnSubmit,
    handleRemoveMenu,
    handleResetColors,
    handleToggleCategory,
    handleOpenQRModal,
    initialValues,
    isLoading: loading || categoriesLoading,
    isNew,
    organizationConfig,
  };
};

export default useConnect;
