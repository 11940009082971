import { FC, memo } from 'react';
import { subYears } from 'date-fns';
import Select from 'components/Inputs/Select';
import { UserGender } from 'apollo/graphql.types';
import { Content, Row, TextField, DatePicker } from './styles';
import { SectionContainer, SectionTitle } from '../styles';

const ProfileForm: FC = () => {
  const formId = 'new_partner';
  const maxBirthDate = subYears(new Date(), 18);

  return (
    <SectionContainer>
      <SectionTitle>Datos personales</SectionTitle>
      <Content>
        <Row>
          <TextField
            name="firstName"
            label="Nombre *"
            id={`${formId}_firstName`}
          />
          <TextField
            name="lastName"
            label="Apellidos *"
            id={`${formId}_lastName`}
          />
        </Row>
        <Row>
          <TextField name="document" label="DNI *" id={`${formId}_document`} />
          <TextField
            name="address"
            label="Domicilio *"
            id={`${formId}_address`}
          />
        </Row>
        <Row>
          <TextField
            id={`${formId}_email`}
            label="email *"
            name="email"
            type="email"
          />
          <TextField
            name="phoneNumber"
            label="Nº de móvil *"
            id={`${formId}_phoneNumber`}
          />
        </Row>
        <Row>
          <Select
            name="gender"
            label="Genero"
            id={`${formId}_gender`}
            options={[
              {
                value: UserGender.Male,
                label: 'Hombre',
              },
              {
                value: UserGender.Female,
                label: 'Mujer',
              },
              {
                value: UserGender.Other,
                label: 'No especificado',
              },
            ]}
          />
          <DatePicker
            label="Fecha de nacimiento *"
            name="birthDate"
            maxDate={maxBirthDate}
            id={`${formId}_birthDate`}
          />
        </Row>
        <Row>
          <Select
            name="usage"
            label="¿Lúdico o terapéutico?"
            id={`${formId}_usage`}
            options={[
              {
                value: 'PLAYFUL',
                label: 'Lúdico',
              },
              {
                value: 'THERAPEUTIC',
                label: 'Terapéutico',
              },
            ]}
          />
        </Row>
      </Content>
    </SectionContainer>
  );
};

export default memo(ProfileForm);
