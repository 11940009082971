export const TABLE_METRICS = {
  name: '15%',
  quantity: '12%',
  discounts: '10%',
  purchases: '10%',
  costs: '10%',
  benefit: '15%',
  products: '15%',
  button: '2rem',
};

export const TABLE_MOBILE_METRICS = {
  name: '25%',
  purchases: '22%',
  costs: '22%',
  benefit: '22%',
};
