import parseISO from 'date-fns/parseISO';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import { ICONS } from 'components/Icon/constants';
import type {
  GetPartnerTransactions_partnerTransactions_transactions,
  GetPartnerTransactions_partnerTransactions_transactions_credits,
} from 'apollo/generated/GetPartnerTransactions';
import type {
  GetEmployeeTransactions_employeeTransactions_transactions,
  GetEmployeeTransactions_employeeTransactions_transactions_credits,
  GetEmployeeTransactions_employeeTransactions_transactions_expenses,
} from 'apollo/generated/GetEmployeeTransactions';
import type {
  OrganizationTransactionsDataFragment,
  PartnerQuotaDataFragment,
  PartnerQuotaTransactionDataFragment,
  PurchaseDataFragment,
} from 'apollo/graphql.types';
import { normalizeOrder } from './Order';
import { applyDiscount } from '../utils/discounts';

export enum TransactionType {
  PURCHASE = 'purchase',
  CREDITS = 'credits',
  QUOTA = 'quota',
  EXPENSE = 'expense',
}

const normalizePurchaseTransactions = (input: PurchaseDataFragment) => {
  const orders = removeOptionalsFromArray(input.orders ?? []);
  // TODO temporary flow until remove mainDiscount flow
  const discountData = input.discountData || input.mainDiscount || null;

  return {
    id: input.id || '',

    canceled: input.canceled || false,
    canceledAt: input.canceledAt
      ? new Date(input.canceledAt).getTime()
      : undefined,
    canceledBy: input.canceledBy
      ? {
          id: input.canceledBy.id || '',
          fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
        }
      : undefined,
    concept: 'Ejecutó una compra',
    extraConcept: '',
    credits: input.total || 0,
    date: new Date(input.createdAt).getTime(),
    employee: {
      id: input.employee.id || '',
      fullName: `${input.employee.user.firstName} ${input.employee.user.lastName}`,
    },
    partner: {
      id: input.partner.id || '',
      fullName: `${input.partner?.user?.firstName} ${input.partner?.user?.lastName}`,
    },
    icon: {
      name: 'shoppingCart' as keyof typeof ICONS,
      color: '#0074CA',
    },
    items: orders.map(normalizeOrder),
    state: undefined,
    sumCredits: false,
    toDate: undefined,
    type: TransactionType.PURCHASE,
    discount: !discountData
      ? undefined
      : {
          name: discountData?.name || '',
          price: discountData?.price ? Number(discountData.price) : 0,
          percentage: discountData?.percentage
            ? Number(discountData.percentage)
            : 0,
        },
  };
};

export const normalizeCreditTransactions = (
  input:
    | GetPartnerTransactions_partnerTransactions_transactions_credits
    | GetEmployeeTransactions_employeeTransactions_transactions_credits,
) => {
  // @ts-ignore TODO until fix types
  const isAddedCreditsAction = input.action === 'ADDED';

  return {
    id: input.id || '',
    canceled: input.canceled || false,
    canceledAt: input.canceledAt
      ? parseISO(input.canceledAt).getTime()
      : undefined,
    canceledBy: input.canceledBy
      ? {
          id: input.canceledBy.id || '',
          fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
        }
      : undefined,
    concept: `Ha ${isAddedCreditsAction ? 'añadido' : 'retirado'} créditos`,
    extraConcept: '',
    // @ts-ignore TODO until fix types
    credits: input.credits || 0,
    date: parseISO(input.date).getTime(),
    employee:
      'addedBy' in input
        ? {
            id: input.addedBy.id || '',
            fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
          }
        : undefined,
    icon: {
      name: (isAddedCreditsAction ? 'cr' : 'coin') as keyof typeof ICONS,
      color: isAddedCreditsAction ? '#2CB532' : '#E53935',
    },
    items: [],
    partner:
      'partner' in input
        ? {
            id: input.partner?.id || '',
            fullName: `${input.partner?.user?.firstName} ${input.partner?.user?.lastName}`,
          }
        : undefined,
    state: undefined,
    sumCredits: isAddedCreditsAction,
    toDate: undefined,
    type: TransactionType.CREDITS,
    discount: undefined,
  };
};

const normalizeQuotaTransactions = (
  input: PartnerQuotaDataFragment | PartnerQuotaTransactionDataFragment,
) => {
  const discount = input.discountData || null;
  let finalPrice = input.quota.price || 0;
  let extraConcept = '';

  if (discount) {
    finalPrice = applyDiscount({ discount, price: finalPrice });
    extraConcept = `Descuento ${discount.name} (-${
      discount.price ? `${discount.price}cr` : `${discount.percentage}%`
    })`;
  }

  return {
    id: input.id || '',
    canceled: input.canceled || false,
    canceledAt: input.canceledAt
      ? new Date(input.canceledAt).getTime()
      : undefined,
    canceledBy: input.canceledBy
      ? {
          id: input.canceledBy.id || '',
          fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
        }
      : undefined,
    concept: `Asignó la cuota '${input.quota.name}'`,
    extraConcept,
    credits: finalPrice,
    date: new Date(input.createdAt).getTime(),
    employee: {
      id: input.addedBy.id || '',
      fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
    },
    icon: {
      name: 'quota' as keyof typeof ICONS,
      color: '#2C3E50',
    },
    partner: {
      id: ('partner' in input && input?.partner?.id) || '',
      fullName:
        ('partner' in input &&
          `${input.partner?.user?.firstName} ${input.partner?.user?.lastName}`) ||
        '',
    },
    items: [],
    state: undefined,
    sumCredits: false,
    toDate: undefined,
    type: TransactionType.QUOTA,
    discount: !discount
      ? undefined
      : {
          name: discount?.name || '',
          price: discount?.price ? Number(discount.price) : 0,
          percentage: discount?.percentage ? Number(discount.percentage) : 0,
        },
  };
};

const normalizeExpenseTransactions = (
  input: GetEmployeeTransactions_employeeTransactions_transactions_expenses,
) => ({
  id: input.id || '',
  canceled: input.canceled || false,
  canceledAt: input.canceledAt
    ? parseISO(input.canceledAt).getTime()
    : undefined,
  canceledBy: input.canceledBy
    ? {
        id: input.canceledBy.id || '',
        fullName: `${input.canceledBy.user.firstName} ${input.canceledBy.user.lastName}`,
      }
    : undefined,
  concept: `Creó un gasto '${input.name}'`,
  extraConcept: '',
  credits: input.price || 0,
  date: parseISO(input.createdAt).getTime(),
  employee: input.addedBy
    ? {
        id: input.addedBy.id || '',
        fullName: `${input.addedBy.user.firstName} ${input.addedBy.user.lastName}`,
      }
    : undefined,
  icon: {
    name: 'expenses' as keyof typeof ICONS,
    color: '#2C3E50',
  },
  items: [],
  state: undefined,
  sumCredits: false,
  toDate: undefined,
  type: TransactionType.EXPENSE,
});

export const normalizePartnerTransaction = (
  input: GetPartnerTransactions_partnerTransactions_transactions,
) => {
  const credits = removeOptionalsFromArray(input.credits ?? []).map(
    normalizeCreditTransactions,
  );
  const purchase = removeOptionalsFromArray(input.purchases ?? []).map(
    // @ts-ignore
    normalizePurchaseTransactions,
  );

  const quotas = removeOptionalsFromArray(input.quotas ?? []).map(
    // @ts-ignore
    normalizeQuotaTransactions,
  );

  return [...credits, ...purchase, ...quotas].sort((a, b) => b.date - a.date);
};

export const normalizeEmployeeTransaction = (
  input: GetEmployeeTransactions_employeeTransactions_transactions,
) => {
  const credits = removeOptionalsFromArray(input.credits ?? []).map(
    normalizeCreditTransactions,
  );
  const expenses = removeOptionalsFromArray(input.expenses ?? []).map(
    normalizeExpenseTransactions,
  );
  const purchase = removeOptionalsFromArray(input.purchases ?? []).map(
    // @ts-ignore
    normalizePurchaseTransactions,
  );

  const quotas = removeOptionalsFromArray(input.quotas ?? []).map(
    // @ts-ignore
    normalizeQuotaTransactions,
  );

  return [...credits, ...expenses, ...purchase, ...quotas].sort(
    (a, b) => b.date - a.date,
  );
};

export const normalizeOrganizationTransactions = (
  input: OrganizationTransactionsDataFragment,
) => {
  const credits = removeOptionalsFromArray(input.credits ?? []).map(
    // @ts-ignore
    normalizeCreditTransactions,
  );
  const purchase = removeOptionalsFromArray(input.purchases ?? []).map(
    normalizePurchaseTransactions,
  );
  const quotas = removeOptionalsFromArray(input.quotas ?? []).map(
    normalizeQuotaTransactions,
  );

  return [...credits, ...purchase, ...quotas].sort((a, b) => b.date - a.date);
};
