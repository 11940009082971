import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './Image';
import { PURCHASE_FRAGMENT } from './Purchase';
import { EXPENSE_FRAGMENT } from './Expense';
import { PARTNER_QUOTA_TRANSACTION_FRAGMENT } from './Partner';
import { PERMISSIONS_GROUP_FRAGMENT } from './PermissionsGroup';

export const EMPLOYEE_WORK_SCHEDULE_FRAGMENT = gql`
  fragment WorkScheduleData on WorkSchedule {
    id
    dayOfWeek
    schedules {
      startTime
      endTime
    }
  }
`;

export const EMPLOYEE_FRAGMENT = gql`
  fragment EmployeeData on Employee {
    id
    partnerNum
    monthlyBilling
    monthlySales
    workSchedules {
      ...WorkScheduleData
    }
    permissions {
      ...PermissionsGroupData
    }
    user {
      ... on User {
        avatar {
          ...ImageData
        }
        address
        birthDate
        city
        country
        createdAt
        document
        email
        firstName
        gender
        lastName
        phoneNumber
        id
        zipCode
        role
        isActive
      }
    }
    logins {
      id
      date
      endSession
    }
  }
  ${IMAGE_FRAGMENT}
  ${EMPLOYEE_WORK_SCHEDULE_FRAGMENT}
  ${PERMISSIONS_GROUP_FRAGMENT}
`;

export const EMPLOYEE_CREDITS_TRANSACTION_FRAGMENT = gql`
  fragment EmployeeCreditTransactionData on EmployeeCreditTransaction {
    id
    action
    credits
    canceled
    canceledAt
    canceledBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    date
    partner {
      ... on Partner {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const EMPLOYEE_TRANSACTIONS_FRAGMENT = gql`
  fragment EmployeeTransactionsData on EmployeeTransactions {
    employee {
      ...EmployeeData
    }
    transactions {
      expenses {
        ...ExpenseData
      }
      purchases {
        ...PurchaseData
      }
      credits {
        ...EmployeeCreditTransactionData
      }
      quotas {
        ...PartnerQuotaTransactionData
      }
    }
  }
  ${EMPLOYEE_FRAGMENT}
  ${EXPENSE_FRAGMENT}
  ${PURCHASE_FRAGMENT}
  ${EMPLOYEE_CREDITS_TRANSACTION_FRAGMENT}
  ${PARTNER_QUOTA_TRANSACTION_FRAGMENT}
`;
