import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey700}1A;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.medium};
  ${TextEllipsis};
`;

export const Number = styled(ColumnTitle)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.number};
`;

export const Name = styled(ColumnTitle)`
  margin-right: 0.5rem;
  flex: 0 0 29%;

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.name};
`}
`;

export const Products = styled(ColumnTitle)`
  flex: 0 0 22%;
  margin-right: 0.5rem;
  ${TextEllipsis};

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.products};
`}
`;

export const Benefit = styled(ColumnTitle)`
  flex: 0 0 22%;
  ${TextEllipsis};

  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.benefit};
`}
`;

export const AddedCredits = styled(ColumnTitle)`
  flex: 0 0 10%;
  ${TextEllipsis};
  ${from.tabletLandscape`
  flex: 0 0 ${TABLE_METRICS.addedCredits};
`}
`;
