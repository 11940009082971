import { FC, memo } from 'react';
import { Formik } from 'formik';
import { Container, TextField } from './styles';
import type { Props } from './types';

const QuotaForm: FC<Props> = ({ className, initialValues, id = 'new' }) => {
  const formId = `quota_${id}`;

  return (
    <Formik onSubmit={() => console.log('')} initialValues={initialValues}>
      <Container className={className}>
        <TextField
          name="value"
          label="Ejercicio"
          id={`${formId}_value`}
          readOnly
        />
        <TextField
          name="price"
          label="Precio (cr)"
          id={`${formId}_price`}
          readOnly
        />
        <TextField
          name="assignedDate"
          label="Asignada"
          id={`${formId}_assignedDate`}
          readOnly
        />
        <TextField
          name="nextPayment"
          label="Siguiente pago"
          id={`${formId}_nextPayment`}
          readOnly
        />
      </Container>
    </Formik>
  );
};

export default memo(QuotaForm);
