import styled from 'styled-components';
import { from } from 'styles/responsive';
import DefaultModal, { Content, Title as ModalTitle } from 'components/Modal';
import { IconCircle as DefaultIconCircle } from 'components/Icon/Icons';
import DefaultIcon from 'components/Icon';
import DefaultButton from 'components/Button';

export const Container = styled(DefaultModal)`
  ${Content} {
    display: flex;
    flex-direction: column;
    width: 100%;

    ${from.tabletPortrait`
      max-width: 55rem;
    `}
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  flex: 0 1 auto;
`;

export const IconCircle = styled(DefaultIconCircle)`
  margin-right: 1rem;
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Title = styled(ModalTitle)`
  text-transform: capitalize;
  margin: 1rem auto 3.5625rem;
`;

export const Label = styled.span`
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  margin-top: 2rem;

  ${from.tabletPortrait`
    flex-flow: row nowrap;
    margin-top: 2.5rem;

    > * {
      flex: 1 0 auto;
    }
  `}
`;

export const RemoveButton = styled(DefaultButton).attrs(() => ({
  variant: 'danger',
}))`
  &&& {
    ${from.tabletPortrait`
      margin-right: 0.5rem;
    `}
  }
`;

export const CancelButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  &&& {
    margin-bottom: 1rem;
    ${from.tabletPortrait`
      margin-bottom: 0;
      margin-right: 0.5rem;
    `}
  }
`;
