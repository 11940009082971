import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import {
  AddedCredits,
  Benefit,
  Container,
  Name,
  Number,
  Products,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  return (
    <Container className={className}>
      <Number>{fromTabletPortrait ? 'Socios' : 'Nº'}</Number>
      <Name>Nombre</Name>
      <Products>Productos</Products>
      <Benefit>Dispensado</Benefit>
      <AddedCredits>{fromTabletPortrait ? 'Créditos' : 'Cr'}</AddedCredits>
    </Container>
  );
};

export default memo(Row);
