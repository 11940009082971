import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import useMetrics from 'apollo/hooks/useMetrics';
import { isAfter, subMonths } from 'date-fns';

const useConnect = () => {
  const fromTabletLandscape = useMediaQuery(from.tabletLandscape.query);
  const {
    billingsMetric,
    partnersMetric,
    productsRanking,
    monthlyProfit,
    monthlyCollectedCreditsProfit,
    bestPurchasesTimes,
    loading: GetMetricsLoading,
  } = useMetrics();

  const newProductsByMonth = useMemo(
    () =>
      productsRanking.filter((item) =>
        isAfter(item.product.createdAt, subMonths(new Date(), 1)),
      ),
    [productsRanking],
  );

  const productsMobile = productsRanking.slice(0, 3);

  return {
    billingsMetric,
    fromTabletLandscape,
    isLoading: GetMetricsLoading,
    partnersMetric,
    productsMobile,
    productsRanking,
    newProductsByMonth,
    monthlyProfit,
    monthlyCollectedCreditsProfit,
    bestPurchasesTimes,
  };
};

export default useConnect;
