import type { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import Loader from 'components/Loader';
import { from } from 'styles/responsive';
import AddCreditsModal from '../../Modals/ManageCredits';
import PartnerNoteModal from '../../Modals/PartnerNote';
import PartnerAdvancedMenuModal from '../../Modals/PartnerAdvancedMenu';
import ValidatePinModal from '../../Modals/ValidatePin';
import DocumentsModal from '../../Modals/Documents';
import SetDocumentCustomDataModal from '../../Modals/SetDocumentCustomData';
import GenerateFileDialogModal from '../../Modals/GenerateFileDialog';
import ExpiredQuotaDialogModal from '../../Modals/ExpiredQuotaDialog';
import AssignQuota from '../../Modals/AssignQuota';
import QuotaDetailsModals from '../../Modals/QuotaDetails';
import SignDocumentModal from '../../Modals/SignDocument';
import UploadFileDialogModal from '../../Modals/UploadFileDialog';
import QuotaModal from '../../Modals/Quota';
import ProfileForm from './ProfileForm';
import PartnerNavigationSidebar from './NavigationSidebar';
import PartnerHeader from './Header';
import PartnerTransactions from './Transactions';
import PartnerArchivesForm from './ArchivesForm';
import SupportedPartners from './SupportedPartners';
import { PartnerSections } from './constants';
import PartnerNotes from './Notes';
import PartnerRegisters from './Registers';
import useConnect from './connect';
import { Container, Content, Section } from './styles';
import ConsumptionData from './ConsumptionData';

const Partner: FC = () => {
  const {
    consumptionsStats,
    handleRegisterPartnerPresence,
    handleOpenAddCreditsModal,
    handleOpenAdvancedPartnerMenuModal,
    handleOpenHostPartnerDetailsModal,
    handleOpenNewOrderModal,
    handleOpenQuotaDetailModal,
    handleSelectedSection,
    handleSubmitAvatar,
    handleSubmitProfile,
    hasPartnerPresenceRegistered,
    initialProfileValues,
    isLoading,
    partner,
    selectedSection,
    subscription,
    fileActionsLoading,
  } = useConnect();

  const fullName = `${initialProfileValues.firstName}${
    initialProfileValues.lastName ? ` ${initialProfileValues.lastName}` : ''
  }`;
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <PartnerHeader
          currentAvatar={initialProfileValues.avatarImageUrl}
          fromTabletPortrait={fromTabletPortrait}
          fullName={fullName}
          lastRegister={partner?.lastRegister}
          onClickAdvancedActions={handleOpenAdvancedPartnerMenuModal}
          onClickOrder={handleOpenNewOrderModal}
          onClickPartnerPresence={handleRegisterPartnerPresence}
          onSubmitAvatar={handleSubmitAvatar}
          partnerPresenceRegistered={hasPartnerPresenceRegistered}
        />
        <Content>
          <PartnerNavigationSidebar
            partnerId={partner?.id}
            onSelectedSection={handleSelectedSection}
          />
          <Section>
            {selectedSection === PartnerSections.PROFILE && (
              <>
                {consumptionsStats && (
                  <ConsumptionData consumptionData={consumptionsStats} />
                )}
                <ProfileForm
                  id={initialProfileValues.id}
                  initialValues={initialProfileValues}
                  onClickCredits={() => handleOpenAddCreditsModal('added')}
                  onClickQuota={handleOpenQuotaDetailModal}
                  onOpenHostMemberDetails={handleOpenHostPartnerDetailsModal}
                  onSubmit={handleSubmitProfile}
                />
              </>
            )}
            {selectedSection === PartnerSections.TRANSACTIONS &&
              subscription.allowedPartnerTransactions && (
                <PartnerTransactions />
              )}
            {selectedSection === PartnerSections.FILES && (
              <PartnerArchivesForm
                files={partner?.files || []}
                partnerId={partner?.id || ''}
                isLoading={fileActionsLoading}
              />
            )}
            {selectedSection === PartnerSections.PARTNERS && (
              <SupportedPartners partners={partner?.supportedPartners || []} />
            )}
            {selectedSection === PartnerSections.NOTES && (
              <PartnerNotes
                notes={partner?.notes || []}
                partnerId={partner?.id}
              />
            )}
            {selectedSection === PartnerSections.REGISTERS && (
              <PartnerRegisters registers={partner?.registers || []} />
            )}
          </Section>
        </Content>
      </Container>
      <AddCreditsModal />
      <PartnerNoteModal />
      <PartnerAdvancedMenuModal />
      <ValidatePinModal />
      <DocumentsModal />
      <SetDocumentCustomDataModal />
      <SignDocumentModal />
      <GenerateFileDialogModal />
      <AssignQuota />
      <QuotaModal />
      <ExpiredQuotaDialogModal />
      <QuotaDetailsModals />
      <UploadFileDialogModal />
    </>
  );
};

export default Partner;
