import styled, { css } from 'styled-components';
import DefaultAvatarIcon from '@mui/icons-material/AccountCircleOutlined';
import { from } from 'styles/responsive';
import DefaultAvatar from 'components/Avatar';
import { TABLE_METRICS } from '../constants';

export const Container = styled.div`
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey900}0A;
  cursor: pointer;
  display: flex;
  padding: 1.0625rem 0 1.1875rem 1.0625rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  transition: background-color 200ms ease-out;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ImageContainer = styled.div`
  flex: 0 0 16%;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.3rem;

  ${from.tabletPortrait`
     margin-right: 0;
     flex: 0 0 ${TABLE_METRICS.image};
     width: 3rem;
     height: 3rem;
  `};
`;

export const Avatar = styled(DefaultAvatar)`
  object-fit: cover;
  height: 3rem;
  width: 3rem;

  ${from.tabletPortrait`
     width: 4rem;
     height: 4rem;
  `};
`;

export const Number = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-right: 0.5rem;
  flex: 0 0 ${TABLE_METRICS.number};
  ${TextEllipsis};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 ${TABLE_METRICS.name};
  ${TextEllipsis};
`;

export const Credits = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-left: auto;
  margin-right: 1rem;
  flex: 0 0 ${TABLE_METRICS.credits};
  ${TextEllipsis};
`;

export const AvatarIcon = styled(DefaultAvatarIcon)`
  height: 3rem !important;
  width: 3rem !important;

  ${from.tabletPortrait`
  height: 4rem !important;
  width: 4rem !important;
`}
`;
