import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { CANCEL_PURCHASE, CREATE_PURCHASE } from 'apollo/mutations';
import {
  normalizePurchase,
  PurchaseFormFields,
  PurchaseOrderFormFields,
} from 'model/Purchase';
import type {
  CreatePurchaseMutation,
  CreatePurchaseMutationVariables,
  CancelPurchaseMutation,
  CancelPurchaseMutationVariables,
} from 'apollo/graphql.types';

const usePurchaseActions = () => {
  const [createPurchaseMutation, { loading: isLoadingCreatePurchase }] =
    useMutation<CreatePurchaseMutation, CreatePurchaseMutationVariables>(
      CREATE_PURCHASE,
    );
  const [cancelPurchaseMutation, { loading: isLoadingCancelPurchase }] =
    useMutation<CancelPurchaseMutation, CancelPurchaseMutationVariables>(
      CANCEL_PURCHASE,
    );

  const normalizeFormOrder = (order: PurchaseOrderFormFields) => ({
    productId: order.productId,
    quantity: Number(order.quantity),
    extraQuantity: order?.extraQuantity ? Number(order.extraQuantity) : 0,
    productPrice: Number(order.productPrice),
    productDiscountName: order.discount?.name || '',
    productWithoutDiscountPrice: order.productWithoutDiscountPrice
      ? Number(order.productWithoutDiscountPrice)
      : Number(order.productPrice),
    discountData: order.discount
      ? {
          id: order.discount.id,
          name: order.discount.name,
          type: order.discount.type,
          price: order.discount.price,
          percentage: order.discount.percentage,
        }
      : null,
  });

  const createPurchase = useCallback(
    async ({ partnerId, orders, discountId }: PurchaseFormFields) => {
      const normalizedOrders = orders.map(normalizeFormOrder);
      const { data } = await createPurchaseMutation({
        variables: {
          data: {
            partnerId,
            discountId,
            orders: normalizedOrders,
          },
        },
      });

      if (data?.createPurchase) {
        return normalizePurchase(data.createPurchase);
      }
    },
    [createPurchaseMutation],
  );

  const cancelPurchase = useCallback(
    async (purchaseId: string) => {
      await cancelPurchaseMutation({
        variables: { purchaseId },
      });
    },
    [cancelPurchaseMutation],
  );

  return {
    cancelPurchase,
    createPurchase,
    loading: isLoadingCreatePurchase || isLoadingCancelPurchase,
  };
};

export default usePurchaseActions;
export type usePurchaseActionsType = ReturnType<typeof usePurchaseActions>;
