import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { GET_TEMPORARY_PARTNERS } from 'apollo/queries/organizations';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import { normalizeOrganizationPartnerRequest } from 'model/Organization';
import type { GetTemporaryPartnersQuery } from 'apollo/graphql.types';

const usePartnersRequests = () => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { data, loading } = useQuery<GetTemporaryPartnersQuery>(
    GET_TEMPORARY_PARTNERS,
    {
      skip: !employeePermissions.allowPartnerRequests,
    },
  );

  const partnersRequests = useMemo(
    () =>
      data?.getTemporaryPartners && data?.getTemporaryPartners?.length > 0
        ? data.getTemporaryPartners.map(normalizeOrganizationPartnerRequest)
        : [],
    [data],
  );

  return {
    gePartnersRequestsLoading: loading,
    partnersRequests,
  };
};

export default usePartnersRequests;
