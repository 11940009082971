import { FC, memo } from 'react';
import { CSVLink } from 'react-csv';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import { formatDate2 } from 'utils/dates/formatDates';
import CashierProductsModal from '../../Modals/CashierProducts';
import TableHead from './TableHead';
import Row from './Row';
import useConnect from './connect';
import {
  Container,
  Header,
  BackLink,
  HeaderInfo,
  HeaderName,
  DownloadButton,
  Main,
  ListContainer,
  FileIcon,
  Filters,
  DateTimeFilterContainer,
} from './styles';

const CashierCategories: FC = () => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const {
    isLoading,
    categories,
    csvInfo,
    employeePermissions,
    filterDates,
    handleOnSearchDates,
    handleShowProducts,
  } = useConnect();

  return (
    <>
      <Container>
        <Header>
          <BackLink to="/cashier" />
          <HeaderInfo>
            <HeaderName>
              Movimientos de categorías{' '}
              {formatDate2(
                filterDates.from ? new Date(filterDates.from) : new Date(),
              )}{' '}
              -{' '}
              {formatDate2(
                filterDates.to ? new Date(filterDates.to) : new Date(),
              )}
            </HeaderName>
          </HeaderInfo>
          {!isLoading && employeePermissions.allowDownloadCashierData && (
            <>
              {fromTabletPortrait ? (
                <DownloadButton>
                  <CSVLink
                    data={csvInfo.data}
                    headers={csvInfo.headers}
                    filename={csvInfo.filename}
                    target="_blank"
                  >
                    Descargar
                  </CSVLink>
                </DownloadButton>
              ) : (
                <CSVLink
                  data={csvInfo.data}
                  headers={csvInfo.headers}
                  filename={csvInfo.filename}
                  target="_blank"
                >
                  <FileIcon />
                </CSVLink>
              )}
            </>
          )}
        </Header>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Filters>
              <DateTimeFilterContainer>
                <DateTimeFilter
                  fromDate={filterDates.from}
                  toDate={filterDates.to}
                  onSearch={handleOnSearchDates}
                />
              </DateTimeFilterContainer>
            </Filters>
            <Main>
              <ListContainer>
                <TableHead />
                {categories?.map((category) => (
                  <Row
                    key={category.id}
                    category={category}
                    onSelectProducts={() =>
                      handleShowProducts(category.products)
                    }
                  />
                ))}
              </ListContainer>
            </Main>
          </>
        )}
      </Container>
      <CashierProductsModal />
    </>
  );
};

export default memo(CashierCategories);
